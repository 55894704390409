import { Injectable } from '@angular/core';
import { Feature } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { FeaturesVisibilityInfoServiceBase } from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';

@Injectable({
    providedIn: 'root'
})
export class FeaturesVisibilityInfoService {
    private baseService!: FeaturesVisibilityInfoServiceBase;

    public setBaseService(baseService: FeaturesVisibilityInfoServiceBase): void {
        this.baseService = baseService;
    }

    public isHidden(feature: Feature, regionId?: number): boolean {
        return this.baseService.isHidden(feature, regionId);
    }

    public isDisabled(feature: Feature, regionId?: number): boolean {
        return this.baseService.isDisabled(feature, regionId);
    }

    public tooltip(feature: Feature): string {
        return this.baseService.tooltip(feature);
    }
}
