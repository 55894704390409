import { PunchComponent, PunchComponentConstructor } from '../punch-component';

export class PunchBaseMaterial extends PunchComponent {
    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
    }

    public update(): void {
        // TODO TEAM: implement
    }
}
