import { Injectable } from '@angular/core';
import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonCodeList, CommonCodeListServiceBase } from '@profis-engineering/pe-ui-common/services/common-code-list.common';

@Injectable({
  providedIn: 'root'
})
export class CommonCodeListService {
    private baseService!: CommonCodeListServiceBase;

    public setBaseService(baseService: CommonCodeListServiceBase): void {
        this.baseService = baseService;
    }

    public get commonCodeLists(): Record<CommonCodeList, CodeList[]> {
        return this.baseService.commonCodeLists;
    }
}
