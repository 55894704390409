import { ButtonSize, ButtonType, NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Command, commandFromService } from '../../command';
import { getSpriteAsIconStyle } from '../../sprites';
import { StrengthRegion, StrengthTab } from './static-menu.strength.definitions';

export function createSPMenu_PostInstalledElement(): StrengthTab {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled',
        Id: 4,
        Name: 'postinstalled-tab',
        Image: 'tab-supplementary-reinforcement',
        IconImage: getSpriteAsIconStyle('sprite-tab-supplementary-reinforcement'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_PostInstalledElement_ShearReinforcement(),
            createSPMenu_PostInstalledElement_Zone1(),
            createSPMenu_PostInstalledElement_Zone2(),
            createSPMenu_PostInstalledElement_Zone3(),
            createSPMenu_PostInstalledElement_Zone4(),
            createSPMenu_PostInstalledElement_Zone5(),
            createSPMenu_PostInstalledElement_Zone6(),
            createSPMenu_PostInstalledElement_Zone7(),
            createSPMenu_PostInstalledElement_Zone8()
        ]
    };
}

function createSPMenu_PostInstalledElement_ShearReinforcement(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement',
        Id: 40,
        Name: 'strengthening-element-section',
        Controls: [
            {
                ControlType: 'PopupGrid',
                Name: 'postinstalled-family-popup-grid',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Family',
                CodelistName: 'fastenerFamilyGroups',
                UIPropertyId: 'fastenerFamilyGroupId',
                WrapName: true
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-type-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Type',
                CodelistName: 'fastenerFamilies',
                UIPropertyId: 'fastenerFamilyId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-size-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Size',
                CodelistName: 'fasteners',
                UIPropertyId: 'fastenerId'
            },
            {
                ControlType: 'Button',
                Name: 'postinstalled-approval-button',
                ButtonSize: ButtonSize.Full,
                ButtonType: ButtonType.Default,
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.ViewApproval',
                UIPropertyId: 'approval',
                Command: commandFromService(Command.OpenApproval)
            }
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone1(): StrengthRegion {
    return {
        Id: 42,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone1',
        Name: 'concrete-zone1-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone1-define-strengthening-element',
                UIPropertyId: 'zone1StrengtheningElementDefinition'
            },
            {
                ControlType: 'Dropdown',
                Name: 'zone1-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone1InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone1SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone1SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone1MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone1MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone1TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone2(): StrengthRegion {
    return {
        Id: 43,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone2',
        Name: 'concrete-zone2-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone2-define-strengthening-element',
                UIPropertyId: 'zone2StrengtheningElementDefinition'
            },
            {
                ControlType: 'Dropdown',
                Name: 'zone2-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone2InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone2-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone2SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone2-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone2SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone2-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone2MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone2-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone2MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone2-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone2TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone3(): StrengthRegion {
    return {
        Id: 44,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone3',
        Name: 'concrete-zone3-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone3-define-strengthening-element',
                UIPropertyId: 'zone3StrengtheningElementDefinition'

            },
            {
                ControlType: 'Dropdown',
                Name: 'zone3-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone3InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone3SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone3SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone3MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone3MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone3TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone4(): StrengthRegion {
    return {
        Id: 45,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone4',
        Name: 'concrete-zone4-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone4-define-strengthening-element',
                UIPropertyId: 'zone4StrengtheningElementDefinition'
            },
            {
                ControlType: 'Dropdown',
                Name: 'zone4-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone4InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone4SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone4SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone4MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone4MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone4TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone5(): StrengthRegion {
    return {
        Id: 46,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone5',
        Name: 'concrete-zone5-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone5-define-strengthening-element',
                UIPropertyId: 'zone5StrengtheningElementDefinition'
            },
            {
                ControlType: 'Dropdown',
                Name: 'zone5-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone5InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone5SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone5SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone5MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone5MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone5TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone6(): StrengthRegion {
    return {
        Id: 47,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone6',
        Name: 'concrete-zone6-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone6-define-strengthening-element',
                UIPropertyId: 'zone6StrengtheningElementDefinition'
            },
            {
                ControlType: 'Dropdown',
                Name: 'zone6-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone6InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone6SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone6SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone6MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone6MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone6TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone7(): StrengthRegion {
    return {
        Id: 48,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone7',
        Name: 'concrete-zone7-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone7-define-strengthening-element',
                UIPropertyId: 'zone7StrengtheningElementDefinition'
            },
            {
                ControlType: 'Dropdown',
                Name: 'zone7-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone7InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone7SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone7SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone7MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone7MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone7TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}

function createSPMenu_PostInstalledElement_Zone8(): StrengthRegion {
    return {
        Id: 49,
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionZone8',
        Name: 'concrete-zone8-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabPostInstalled.RegionReinforcementPosition.DefineStrengtheningElement',
                Name: 'zone8-define-strengthening-element',
                UIPropertyId: 'zone8StrengtheningElementDefinition'
            },
            {
                ControlType: 'Dropdown',
                Name: 'zone8-installationdirection-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.InstallationDirection',
                CodelistName: 'installationDirections',
                UIPropertyId: 'zone8InstallationDirectionId',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenInstallationDirectionPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-spacing-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingX',
                Size: 50,
                UIPropertyId: 'zone8SpacingX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-spacing-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.SpacingY',
                Size: 50,
                UIPropertyId: 'zone8SpacingY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-edgedistance-x',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceX',
                Size: 50,
                UIPropertyId: 'zone8MinimumEdgeDistanceX'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-edgedistance-y',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.MinEdgeDistanceY',
                Size: 50,
                UIPropertyId: 'zone8MinimumEdgeDistanceY'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-transverse-eccentricity',
                UnitGroup: UnitGroup.Length,
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionZones.TransverseEccentricity',
                Size: 50,
                UIPropertyId: 'zone8TransverseEccentricity',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenTransverseEccentricityPopup)
            },
        ]
    };
}
