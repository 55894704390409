import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DataService } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';

type NumericTextBoxPropsBound = Pick<NumericTextBoxProps, 'id' | 'title' | 'placeholder' | 'minValue' | 'maxValue' | 'tooltip' | 'precision'>;

type ControlPropertyId = 'e';

@Component({
    selector: 'app-add-edit-design-inputs-steel-material',
    templateUrl: './add-edit-design-inputs-steel-material.component.html',
    styleUrls: ['./add-edit-design-inputs-steel-material.component.scss']
})
export class AddEditDesignInputsSteelMaterialComponent implements OnInit, OnChanges {
    @Input()
    public e: number | null = null;

    @Output()
    public eChange = new EventEmitter<number | null>();

    @Input()
    public eUnit!: UnitType;

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designStandardId!: number;

    public eTextBox!: NumericTextBoxPropsBound;

    constructor(
        private localizationService: LocalizationService,
        private unit: UnitService,
        private dataService: DataService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['regionId']?.firstChange === false) {
            this.onRegionChange();
        }

        if (changes['designStandardId']?.firstChange === false) {
            this.onRegionChange();
        }
    }

    public ngOnInit(): void {
        this.initControls();
        this.onRegionChange();
        this.onDesignStandardChange();
    }

    private updateControl(control: NumericTextBoxPropsBound, appPropertyId: ControlPropertyId) {
        const propertyValue = this.dataService.getPropertyDetail(appPropertyId, { designStandardId: this.designStandardId });

        control.maxValue = propertyValue.maxValue;
        control.minValue = propertyValue.minValue;
        control.placeholder = propertyValue.defaultValue ?? this.localizationService.getString('SP.Placeholder.Default');
    }

    private onRegionChange() {
        this.updateControl(this.eTextBox, 'e');
    }

    private onDesignStandardChange() {
        this.updateControl(this.eTextBox, 'e');
    }

    private initControls() {
        this.eTextBox = {
            id: 'steel-material-E',
            title: this.localizationService.getString('SP.SteelMaterial.E'),
            tooltip: {
                title: this.localizationService.getString('SP.SteelMaterial.E'),
                content: this.localizationService.getString('SP.SteelMaterial.E.Tooltip')
            }
        };
    }
}
