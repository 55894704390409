import { propertyValueChanged as basePropertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { DataService } from '../../services/data.service';
import { PunchPropertyId, StrengthPropertyId } from '../../services/design.service';

export const strengthPropertyValueChanged = basePropertyValueChanged as unknown as (property?: StrengthPropertyId) => (target: object, propertyKey: string) => void;
export const punchPropertyValueChanged = basePropertyValueChanged as unknown as (property?: PunchPropertyId) => (target: object, propertyKey: string) => void;

export interface UpdateServices {
    dataService: DataService;
}
