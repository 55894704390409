import { Injectable } from '@angular/core';
import { ReportTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { ReportTemplateServiceBase } from '@profis-engineering/pe-ui-common/services/report-template.common';

interface IReportTemplateLogo {
    blob: Blob;
    url: string;
    base64: string;
}

export interface ReportTemplateServiceBaseInternal extends ReportTemplateServiceBase {
    getLogo: (id: number, logos: Record<number, unknown>) => Promise<IReportTemplateLogo>;
}

@Injectable({
    providedIn: 'root'
})
export class ReportTemplateService {
    private baseService!: ReportTemplateServiceBaseInternal;

    public setBaseService(baseService: ReportTemplateServiceBaseInternal): void {
        this.baseService = baseService;
    }

    public get templates(): ReportTemplateEntity[] {
        return this.baseService.templates;
    }

    public async getLogo(id: number): Promise<IReportTemplateLogo> {
        return await this.baseService.getLogo(id, {});
    }
}
