import { Injectable } from '@angular/core';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType as Unit, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ConstantParameter, NoneUnitNumericalParameter, NumericalParameter, TemplateParameter, TranslatableParameter, TranslationFormat, TranslationParameter, TranslationParameterType } from './design.service';
import { LocalizationService } from './localization.service';
import { UnitService } from './unit.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationFormatService {
    constructor(
        private localizationService: LocalizationService,
        private unitService: UnitService
    ) { }

    public getLocalizedStringWithTranslationFormat(
        translationFormat?: TranslationFormat,
        roundValue = true,
        preTransformedParams?: Record<string, string>
    ): string | undefined {
        if (translationFormat == null) {
            return undefined;
        }

        // Transforms the translation parameters array into a JSON object.
        const transformedParams = this.transformTranslationParameters(translationFormat.translationParameters, roundValue, preTransformedParams);
        // Gets the template from the attribute or checks the template object (recursive call, because it's a nested object of the same type).
        const translation = translationFormat.template ?? this.getLocalizedStringWithTranslationFormat(translationFormat.templateFormat, roundValue);

        // Sets the values based on the template and parameter array.
        if (translation == null) {
            let localizedString = '';
            translationFormat.translationParameters.forEach(key => {
                transformedParams[key.name] = transformedParams[key.name].split('{').join(`{${key.name}_`);
                localizedString += formatKeyValue(`{${key.name}}`, transformedParams) + '\n';
            });

            return localizedString.trimEnd();
        }

        return formatKeyValue(translation, transformedParams).trimEnd();
    }

    public getScopeCheckHtml(scopeCheckMessage: TranslationFormat) : string{
        const transformedParams = this.transformTranslationParameters(
            scopeCheckMessage.translationParameters,
            true,
            undefined,
            this.getNumericScopeCheckHtml.bind(this)
        );

        const html = this.getLocalizedStringWithTranslationFormat(
            scopeCheckMessage,
            true,
            transformedParams
        );

        return html!.replace(/τ/g, '<span class="tauFontSmall">τ</span>');
    }

    private getNumericScopeCheckHtml(parameter: TranslationParameter, roundValue: boolean) {
        if (parameter.parameterType !== TranslationParameterType.Numerical || !roundValue) {
            // Handle only Numerical parameters with rounding
            return null;
        }

        const numericalParameter = parameter as NumericalParameter;
        if (numericalParameter.value == null) {
            return null;
        }

        return this.getNumericScopeCheckHtmlBase(numericalParameter.value, numericalParameter.unitGroup, numericalParameter.additionalPrecision as number);
    }

    private getNumericScopeCheckHtmlBase(numberValue: number, unitGroup: UnitGroup, additionalPrecision: number) {
        let unit = UnitType.None;
        if (unitGroup) {
            unit = this.unitService.getDefaultUnit(unitGroup);
        }

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const precision = this.unitService.getPrecision(unit) + 1 + additionalPrecision; // Precision must be default + 1.

        numberValue = this.getConvertedUnitValue(numberValue, unit, unitGroup);
        let displayedUnitValue = this.unitService.formatNumber(numberValue, precision);
        let exactUnitValue = this.unitService.formatNumber(numberValue, maxPrecision);

        if (displayedUnitValue.length >= exactUnitValue.length) {
            return null;
        }

        // Displayed with less decimals than internally used!
        displayedUnitValue = this.unitService.appendPrecisionLossSymbolToValueString(numberValue, displayedUnitValue);

        if (unitGroup) {
            displayedUnitValue = this.unitService.appendUnitToValueString(numberValue, displayedUnitValue, unit);
            exactUnitValue = this.unitService.appendUnitToValueString(numberValue, exactUnitValue, unit);
        }

        return `<span class="additional-info" title="${exactUnitValue}">${displayedUnitValue}</span>`;
    }

    private getConvertedUnitValue(numberValue: number, unit: UnitType, unitGroup: UnitGroup) {
        if (unitGroup) {
            const internalUnit = this.unitService.getInternalUnit(unitGroup);
            return this.unitService.convertUnitValueArgsToUnit(numberValue, internalUnit, unit);
        }

        return numberValue;
    }

    private transformTranslationParameters(
        translationParameters: TranslationParameter[],
        roundValue: boolean,
        preTransformedParams?: Record<string, string>,
        customParameterTransformation?: (parameter: TranslationParameter, roundValue: boolean) => string | undefined | null
    ): Record<string, string> {
        const keys: Record<string, string> = {};

        translationParameters.forEach((parameter) => {
            let handled = false;

            if (preTransformedParams != null && parameter.name in preTransformedParams) {
                keys[parameter.name] = preTransformedParams[parameter.name];
                handled = true;
            } else if (customParameterTransformation != null) {
                const value = customParameterTransformation(parameter, roundValue);
                if (value != null) {
                    keys[parameter.name] = value;
                    handled = true;
                }
            }

            if (!handled) {
                switch (parameter.parameterType) {
                    case TranslationParameterType.Numerical:
                        keys[parameter.name] = this.getNumericalParameterValue(parameter, roundValue);
                        break;
                    case TranslationParameterType.Translatable:
                        keys[parameter.name] = this.getTranslatableParameterValue(parameter);
                        break;
                    case TranslationParameterType.Constant:
                        keys[parameter.name] = (parameter as ConstantParameter).value;
                        break;
                    case TranslationParameterType.Template:
                        keys[parameter.name] = this.getTemplateParameterValue(parameter, roundValue);
                        break;
                    case TranslationParameterType.NoneUnitNumericalParameter:
                        keys[parameter.name] = this.getNumericalWithoutUnitParameterValue(parameter, roundValue);
                        break;
                    default:
                        throw new Error('Unknown parameter type');
                }
            }
        });

        return keys;
    }

    private getNumericalParameterValue(parameter: TranslationParameter, roundValue: boolean) : string {
        const numericalParameter = parameter as NumericalParameter;
        if (numericalParameter.value == null) {
            return '';
        }

        const defaultPrecision = this.unitService.getDefaultPrecision();
        if (numericalParameter.unitGroup == null) {
            const precision = (!roundValue)
                ? defaultPrecision
                : this.unitService.getPrecision(Unit.None) + (numericalParameter.additionalPrecision ?? 0);
            return this.unitService.formatNumber(numericalParameter.value, precision);
        }

        const internalUnit = this.unitService.getInternalUnit(numericalParameter.unitGroup);
        const defaultUnit = this.unitService.getDefaultUnit(numericalParameter.unitGroup);
        const precision = (!roundValue)
            ? defaultPrecision
            : this.unitService.getPrecision(defaultUnit) + 1  + (numericalParameter.additionalPrecision ?? 0); // Precision must be default + 1.

            let unitValueNumber = this.unitService.convertUnitValueArgsToUnit(numericalParameter.value, internalUnit, defaultUnit);
        if (typeof unitValueNumber === 'string') {
            unitValueNumber = this.unitService.parseNumber(unitValueNumber);
        }

        return this.unitService.formatUnitValueArgs(unitValueNumber, defaultUnit, precision);
    }

    private getNumericalWithoutUnitParameterValue(parameter: TranslationParameter, roundValue: boolean) : string {
        const noneUnitNumericalParameter = parameter as NoneUnitNumericalParameter;

        if (noneUnitNumericalParameter.value == null) {
            return '';
        }

        const defaultPrecision = this.unitService.getDefaultPrecision();

        const precision = (!roundValue)
        ? defaultPrecision
        : this.unitService.getPrecision(UnitType.None) + 1 + (noneUnitNumericalParameter.additionalPrecision ?? 0); // Precision must be default + 1.

        return this.unitService.formatNumber(noneUnitNumericalParameter.value, precision);
    }

    private getTranslatableParameterValue(parameter: TranslationParameter) {
        const translatableParameter = parameter as TranslatableParameter;
        if (translatableParameter.value == null || translatableParameter.value.trim() == '') {
            return '';
        }

        return this.localizationService.getString(translatableParameter.value);
    }

    private getTemplateParameterValue(parameter: TranslationParameter, roundValue: boolean) {
        const templateParameter = parameter as TemplateParameter;
        return this.getLocalizedStringWithTranslationFormat(templateParameter.value, roundValue) ?? '';
    }
}
