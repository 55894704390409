import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Update } from '@profis-engineering/gl-model/base-update';
import { MaterialCache } from '@profis-engineering/gl-model/cache/material-cache';
import { CanvasContext3d } from '@profis-engineering/gl-model/canvas-context-3d';
import { ScopeCheck } from '@profis-engineering/gl-model/components/base-component';
import { getText2DFromGlobal, getText2DString, isText2DEnabled, updateText2D } from '@profis-engineering/gl-model/consoleUpdatePropertyMode';
import { EventNotifier, RemoveEvent } from '@profis-engineering/gl-model/external/event-notifier';
import { InputSettings, MouseClickType } from '@profis-engineering/gl-model/external/input-settings';
import { MathCalculator } from '@profis-engineering/gl-model/external/math-calculator';
import { PropertyInfo } from '@profis-engineering/gl-model/external/property-info';
import { Tooltip } from '@profis-engineering/gl-model/external/tooltip';
import { UnitConverter, UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { RenderType } from '@profis-engineering/gl-model/gl-model';
import { GLImages } from '@profis-engineering/gl-model/images';
import fontTexture from '@profis-engineering/gl-model/text/default-font-texture';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../../../environments/environment';
import { DataService } from '../../services/data.service';
import { PropertyId, DesignDetails, designTypes, PropertyIdValue } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { MathService } from '../../services/math.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { TooltipService } from '../../services/tooltip.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { MeshCache } from '../../web-gl/cache/mesh-cache';
import { GlModel, Model, PartialModel, ScreenshotSettings } from '../../web-gl/gl-model';
import { PunchGlModel, PunchModelUpdate, PunchPartialModel } from '../../web-gl/punch-gl-model';
import { StrengthGlModel, StrengthModel, StrengthModelUpdate, StrengthPartialModel } from '../../web-gl/strength-gl-model';
import { BaseMaterialUpdate } from './update/base-material-update';
import { OpeningUpdate } from './update/opening-update';
import { PostInstalledElementUpdate } from './update/post-installed-element-update';

declare global {
    interface Window {
        updateText2D: typeof updateText2D;
        getText2DFromGlobal: typeof getText2DFromGlobal;
        getText2DString: typeof getText2DString;
        isText2DEnabled: typeof isText2DEnabled;
    }
}

export interface GlModelProps {
    continuousRender: boolean;
    model: Model;
    designDetails: DesignDetails;
    onFontsLoaded: () => void;
    onZoom: (zoom: number) => void;
    onSelectTab: (tab: string) => void;
    propertyChange: (propertyChange: PropertyIdValue[]) => Promise<void>;
}

const enum Stepper {
    increment,
    decrement
}

export interface StrengthGlModelComponent extends GlModelComponent {
    update: (model: StrengthPartialModel, replace?: boolean) => Promise<void>;
}

export interface PunchGlModelComponent extends GlModelComponent {
    update: (model: PunchPartialModel, replace?: boolean) => Promise<void>;
}

const context3d = new CanvasContext3d('context-3d-main', MaterialCache, MeshCache);

@Component({
    selector: 'app-gl-model',
    templateUrl: './gl-model.component.html',
    styleUrls: ['./gl-model.component.scss']
})
export class GlModelComponent implements OnDestroy, OnInit, OnChanges {

    @Input()
    public continuousRender!: boolean;

    @Input()
    public model!: Model;

    @Input()
    public designDetails!: DesignDetails;

    // TODO FILIP: remove
    @Input()
    public zoomToFit = (extraInfo: ScreenshotSettings) => this.zoomToFitInternal(extraInfo);
    @Input()
    public update = (model: PartialModel, replace?: boolean) => this.updateInternal(model, replace);
    @Input()
    public getModel = () => this.getModelInternal();
    @Input()
    public clearSelected = () => this.clearSelectedInternal();
    @Input()
    public resizeNextFrame = (which?: number, renderCount?: number) => this.resizeNextFrameInternal(which, renderCount);
    @Input()
    public resetCamera = () => this.resetCameraInternal();
    @Input()
    public createDesignScreenshot = (extraInfo: ScreenshotSettings) => this.createDesignScreenshotInternal(extraInfo);
    @Input()
    public propertyValueChanged = (changes: Change[], scopeChecks: ScopeCheck[], update: Update, model?: Model) => this.propertyValueChangedInternal(changes, scopeChecks, update, model);
    @Input()
    public cameraZoomIn = () => this.cameraZoomInInternal();
    @Input()
    public cameraZoomOut = () => this.cameraZoomOutInternal();
    @Input()
    public cameraZoom = (percentage: number) => this.cameraZoomInternal(percentage);



    @Output()
    public fontsLoaded = new EventEmitter<void>();

    @Output()
    public zoom = new EventEmitter<number>();

    @Output()
    public selectTab = new EventEmitter<string>();

    @Output()
    public propertyChange = new EventEmitter<PropertyIdValue[]>;

    public Stepper: Record<keyof typeof Stepper, Stepper> = {
        increment: Stepper.increment,
        decrement: Stepper.decrement
    };

    public glModel3d!: GlModel;

    private textEditor!: HTMLInputElement;

    private glUnitConverter!: GLUnitConverter;
    private glPropertyInfo!: GLPropertyInfo;
    private glMathCalculator!: GLMathCalculator;
    private glTooltip!: GLTooltip;
    private glEventNotifier!: GLEventNotifier;
    private glInputSettings!: GLInputSettings;

    constructor(
        private unitService: UnitService,
        private numberService: NumberService,
        private localizationService: LocalizationService,
        private mathService: MathService,
        private tooltipService: TooltipService,
        private modalService: ModalService,
        private userSettingsService: UserSettingsService,
        private dataService: DataService,
        private changeDetection: ChangeDetectorRef,
        private ngZone: NgZone,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        if (typeof window == 'object') {
            window.updateText2D = updateText2D;
            window.getText2DFromGlobal = getText2DFromGlobal;
            window.getText2DString = getText2DString;
            window.isText2DEnabled = isText2DEnabled;
        }
    }

    public get selectedLanguage() {
        return this.localizationService.selectedLanguage;
    }

    public ngOnInit(): void {
        const ensureInZone = createEnsureInZoneFunction(this.ngZone);

        this.glUnitConverter = new GLUnitConverter(this.unitService, this.numberService, this.localizationService);
        this.glMathCalculator = new GLMathCalculator(this.mathService);
        this.glTooltip = new GLTooltip(this.tooltipService, this.localizationService, ensureInZone);
        this.glEventNotifier = new GLEventNotifier(this.modalService, this.onEvent.bind(this), ensureInZone);
        this.glInputSettings = new GLInputSettings(this.userSettingsService);
        this.glPropertyInfo = new GLPropertyInfo(propertyChanges => this.propertyChange.emit(propertyChanges), ensureInZone);

        this.onDesignDetailsChange(this.designDetails);

        this.glModel3d = this.createGlModel3d();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['designDetails']?.firstChange === false) {
            this.onDesignDetailsChange(this.designDetails);
        }
    }

    public resizeInternal() {
        this.glModel3d.resize();
    }

    public renderNextFrameInternal(count?: number) {
        this.glModel3d.renderNextFrame(count);
    }

    public resizeNextFrameInternal(which?: number, renderCount?: number) {
        this.glModel3d.resizeNextFrame(which, renderCount);
    }

    public updateInternal(model: PartialModel, replace?: boolean): Promise<void> {
        return this.glModel3d.update(model as Model, replace);
    }

    public propertyValueChangedInternal(changes: Change[], scopeChecks: ScopeCheck[], update: Update, model?: Model): void {
        // no need for await - pending task to make all functions in gl-model sync
        this.glModel3d.propertyValueChanged(changes, scopeChecks, update, model, {
            dataService: this.dataService
        }, this.designDetails)
        .catch(error => console.error(error));
    }

    public resetCameraInternal() {
        this.glModel3d.resetCamera();
    }

    public cameraZoomInternal(percentage: number) {
        this.glModel3d.cameraZoom(percentage);
    }

    public cameraZoomInInternal() {
        this.glModel3d.cameraZoomIn();
    }

    public cameraZoomOutInternal() {
        this.glModel3d.cameraZoomOut();
    }

    public getModelInternal(): Model {
        return this.glModel3d.getModel();
    }

    public ngOnDestroy(): void {
        this.textEditor?.remove();

        this.glUnitConverter?.dispose();
        this.glModel3d?.dispose();
    }

    public clearSelectedInternal() {
        this.glModel3d.clearSelected();
    }

    public async createDesignScreenshotInternal(extraInfo: ScreenshotSettings): Promise<string> {
        // TODO FILIP: what do we need to do here?

        // TODO FILIP: should not be a Promise
        return await this.glModel3d.createDesignScreenshot(extraInfo);
    }

    public zoomToFitInternal(extraInfo: ScreenshotSettings) {
        this.glModel3d.zoomToFit(extraInfo);
    }

    public stepperClick(stepper: Stepper) {
        const unitValue = this.unitService.parseUnknownUnitValue(this.glModel3d.textEditorValue ?? '');

        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            const stepValue = this.unitService.incDecValueByUnit(unitValue.unit);
            unitValue.value = (stepper == Stepper.increment ? unitValue.value + stepValue : unitValue.value - stepValue);

            this.glModel3d.textEditorValue = this.unitService.formatUnitValue(unitValue);
            this.glModel3d.textEditorValueChange();
        }
    }

    private createGlModel3d(): GlModel {
        const nativeElement = this.elementRef.nativeElement;
        this.textEditor = nativeElement.querySelector<HTMLInputElement>('.gl-model-text-editor')!;

        setTimeout(() => {
            // canvas is not the correct size yet so we wait
            this.resizeInternal();

            // append the text editor to body
            document.body.appendChild(this.textEditor);
        });

        context3d.parentContainer = nativeElement;

        // run outside angular zone because of requestAnimationFrame
        return this.ngZone.runOutsideAngular(() => this.createInstanceGlModel3d());
    }

    private createInstanceGlModel3d(): GlModel {
        switch (this.designDetails.designTypeId) {
            case designTypes.strength.id:
                return this.strengthCreateGlModel3d() as unknown as GlModel;
            case designTypes.punch.id:
                return this.punchCreateGlModel3d() as unknown as GlModel;
            default:
                throw new Error('Unknown designTypeId');
        }
    }

    private strengthCreateGlModel3d(): StrengthGlModel {
        if (this.designDetails.designTypeId != designTypes.strength.id) {
            throw new Error('StrengthGlModel can only be created for designTypeId == strength');
        }

        const images: GLImages = {
            existingStructureConcrete: undefined!,
            newStructureConcrete: undefined!,
            reinforcement: undefined!,
            fontTexture
        };

        const modelUpdate: StrengthModelUpdate = {
            BaseMaterialUpdate,
            PostInstalledElementUpdate,
            OpeningUpdate
        };

        return new StrengthGlModel({
            showDebugLayer: environment.debugGlModel,
            context3d: context3d,
            images: images,
            renderType: this.continuousRender ? RenderType.Continuous : RenderType.Auto,
            textEditor: this.textEditor,
            // we bind glModel values in html (like glModel3d.textEditorVisible) so we need to call angular change detection to check for html changes
            textEditorChange: () => this.changeDetection.detectChanges(),
            unitConverter: this.glUnitConverter,
            propertyInfo: this.glPropertyInfo,
            mathCalculator: this.glMathCalculator,
            tooltip: this.glTooltip,
            eventNotifier: this.glEventNotifier,
            inputSettings: this.glInputSettings,
            model: this.model as StrengthModel,
            modelUpdate: modelUpdate
        });
    }

    private punchCreateGlModel3d(): PunchGlModel {
        if (this.designDetails.designTypeId != designTypes.punch.id) {
            throw new Error('PunchGlModel can only be created for designTypeId == strength');
        }

        const images: GLImages = {
            existingStructureConcrete: undefined!,
            newStructureConcrete: undefined!,
            reinforcement: undefined!,
            fontTexture
        };

        const modelUpdate: PunchModelUpdate = {
            BaseMaterialUpdate: undefined
        };

        return new PunchGlModel({
            showDebugLayer: environment.debugGlModel,
            context3d: context3d,
            images: images,
            renderType: this.continuousRender ? RenderType.Continuous : RenderType.Auto,
            textEditor: this.textEditor,
            // we bind glModel values in html (like glModel3d.textEditorVisible) so we need to call angular change detection to check for html changes
            textEditorChange: () => this.changeDetection.detectChanges(),
            unitConverter: this.glUnitConverter,
            propertyInfo: this.glPropertyInfo,
            mathCalculator: this.glMathCalculator,
            tooltip: this.glTooltip,
            eventNotifier: this.glEventNotifier,
            inputSettings: this.glInputSettings,
            model: this.model as StrengthModel,
            modelUpdate: modelUpdate
        });
    }

    private onEvent(event: GlEvent, eventArgs?: unknown) {
        NgZone.assertInAngularZone();

        switch (event) {
            case 'Fonts_Loaded':
                this.fontsLoaded.next();
                break;
            case 'Zoom':
                this.zoom.next(eventArgs as number);
                break;
            case 'Select_Tab':
                this.selectTab.next(eventArgs as string);
                break;
            default:
                throw new Error('Unknown gl-model event');
        }
    }

    private onDesignDetailsChange(designDetails: DesignDetails) {
        this.glUnitConverter.changeDesignDetails(designDetails);
        this.glPropertyInfo.changeDesignDetails(designDetails);
    }
}

class GLUnitConverter implements UnitConverter<string> {
    private separatorChangeSubscription: Subscription;
    private formatChangedCallbacks: Set<() => void> = new Set();

    private designDetails!: DesignDetails;

    constructor(
        private unitService: UnitService,
        private numberService: NumberService,
        private localizationService: LocalizationService,
    ) {
        this.separatorChangeSubscription = this.localizationService.separatorChange.subscribe(() => {
            for (const callback of this.formatChangedCallbacks) {
                callback();
            }
        });
    }

    public changeDesignDetails(designDetails: DesignDetails) {
        this.designDetails = designDetails;

        for (const callback of this.formatChangedCallbacks) {
            callback();
        }
    }

    public getNumberWithUnit(internalValue: number, unitGroup = UnitGroup.None): string {
        const defaultValue = this.unitService.convertInternalValueToDefaultUnitValue(internalValue, unitGroup as number);
        return this.unitService.formatUnitValue(defaultValue, Number.MAX_SAFE_INTEGER);
    }

    public format(internalValue: number, unitGroup = UnitGroup.None, property?: string): string {
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup as number);
        const precision = this.unitService.getPrecision(defaultUnit, property);
        const defaultValue = this.unitService.convertInternalValueToDefaultUnitValue(internalValue, unitGroup as number);

        return this.unitService.formatNumber(defaultValue.value, precision);
    }

    public formatWithUnit(internalValue: number, unitGroup = UnitGroup.None, property?: string): string {
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup as number);
        const precision = this.unitService.getPrecision(defaultUnit, property);
        const defaultValue = this.unitService.convertInternalValueToDefaultUnitValue(internalValue, unitGroup as number);

        return this.unitService.formatUnitValue(defaultValue, precision);
    }

    public parse(value: string, unitGroup: UnitGroup): number {
        const unitValue = this.unitService.parseUnitValue(value, unitGroup as number);

        return this.unitService.convertUnitValueToInternalUnitValue(unitValue)?.value ?? 0;
    }

    public round(value: number, decimals?: number): number {
        return this.numberService.round(value, decimals);
    }

    public formatChanged(fn: () => void): () => void {
        this.formatChangedCallbacks.add(fn);

        return () => {
            this.formatChangedCallbacks.delete(fn);
        };
    }

    public equals(firstValue: number, secondValue: number, precision: number): boolean {
        return this.numberService.equals(firstValue, secondValue, precision);
    }

    public dispose() {
        this.separatorChangeSubscription.unsubscribe();
        this.formatChangedCallbacks = new Set();
    }
}

class GLPropertyInfo implements PropertyInfo<string> {
    private designDetails!: DesignDetails;

    constructor(
        private propertyChange: (propertyChange: PropertyIdValue[]) => void,
        private ensureInZone: EnsureInZone
    ) { }

    public changeDesignDetails(designDetails: DesignDetails) {
        this.designDetails = designDetails;
    }

    public isPropertyDisabled(): boolean {
        // should be passed though model change if needed

        return false;
    }

    public isPropertyHidden(): boolean {
        // should be passed though model change if needed

        return false;
    }

    public propertyDisabledChanged(): RemoveEvent {
        // should be passed though model change if needed

        return () => {
            // not needed
        };
    }

    public setPropertyValue(property: string, value: unknown): void {
        this.ensureInZone(() => {
            const propertyChange: PropertyIdValue[] = [{
                propertyId: property,
                propertyValue: value
            }];

            this.propertyChange(propertyChange);
        });
    }

    public getPropertyValue(property: string): unknown {
        return this.designDetails.properties[property as PropertyId];
    }
}

class GLMathCalculator implements MathCalculator {
    constructor(
        private mathService: MathService
    ) { }

    public tryComputeExactUnitValue(value: string, unitGroup?: UnitGroup): string {
        return this.mathService.tryComputeUnitValue(value, unitGroup as number, undefined, undefined, Number.MAX_SAFE_INTEGER);
    }
}

class GLTooltip implements Tooltip<string> {
    constructor(
        private tooltipService: TooltipService,
        private localizationService: LocalizationService,
        private ensureInZone: EnsureInZone
    ) { }

    public getTranslation(key: string): string {
        return this.localizationService.getString(this.getTranslationKey(key));
    }

    public showTranslation(value: string): void {
        this.ensureInZone(() => {
            this.tooltipService.show(this.localizationService.getString(this.getTranslationKey(value)));
        });
    }

    public hideTranslation(value: string): void {
        this.ensureInZone(() => {
            this.tooltipService.hide(this.localizationService.getString(this.getTranslationKey(value)));
        });
    }

    public showText(value: string): void {
        this.ensureInZone(() => {
            this.tooltipService.show(value);
        });
    }

    public hideText(value: string): void {
        this.ensureInZone(() => {
            this.tooltipService.hide(value);
        });
    }

    private getTranslationKey(tooltipKey: string) {
        switch (tooltipKey) {
            // TODO FILIP: do we use pe translation keys for tooltips that are already in gl-model
            case 'Arrow':
                return 'Agito.Hilti.Profis3.GL.Arrow.Tooltip';
            case 'BaseMaterial':
                return 'SP.GlModel.BaseMaterial';

            default:
                throw new Error('unknown TooltipKey');
        }
    }
}

export type GlEvent =
    'Zoom' |
    'Model_Loaded' |
    'Fonts_Loaded' |
    'Select_Tab';
type TriggerEvent = (event: GlEvent, eventArgs?: unknown) => void;

class GLEventNotifier implements EventNotifier {
    private alert3dErrorPopupOpend?: IModalOpened;

    constructor(
        private modalService: ModalService,
        private triggerEvent: TriggerEvent,
        private ensureInZone: EnsureInZone
    ) { }

    public webGLContextLost(): void {
        this.ensureInZone(() => {
            this.alert3dErrorPopupOpend = this.modalService.openAlertGLError();
        });
    }

    public webGLContextRestored(): void {
        this.ensureInZone(() => {
            if (this.alert3dErrorPopupOpend) {
                this.alert3dErrorPopupOpend.close();
                this.alert3dErrorPopupOpend = undefined;
            }
        });
    }

    public zoomChange(zoom: number): void {
        this.ensureInZone(() => {
            this.triggerEvent?.('Zoom', zoom);
        });
    }

    public fontsLoaded(): void {
        this.ensureInZone(() => {
            this.triggerEvent?.('Fonts_Loaded');
        });
    }

    public componentSelected(component: Component): void {
        this.ensureInZone(() => {
            this.triggerEvent?.('Select_Tab', component);
        });
    }

    public registerCurrentStateUndoRedoAction(): void {
        // not needed
    }

    public positionsChanged2d(): void {
        // not needed
    }

    public draggingSelectionChanged2d(): void {
        // not needed
    }
}

/**
* This should all be in IModel. Do NOT add new things here. Add them to IModel as an input to GLModel.
*/
class GLInputSettings implements InputSettings {
    private userSettingsService: UserSettingsService;

    constructor(userSettingsService: UserSettingsService) {
        this.userSettingsService = userSettingsService;
    }

    public get rotateMouseButton(): MouseClickType {
        return this.userSettingsService.controls3dSettings.rotate as number;
    }

    public get panMouseButton(): MouseClickType {
        return this.userSettingsService.controls3dSettings.pan as number;
    }
}

// gl-model events might be triggered outside angular zone (we don't want gl-model requestAnimationFrame to run inside angular)
// this function ensures that all "events" outside gl-model run in an angular zone
type EnsureInZone = <T>(fn: () => T) => T;
function createEnsureInZoneFunction(ngZone: NgZone): EnsureInZone {
    return <T>(fn: () => T): T => {
        if (NgZone.isInAngularZone()) {
            return fn();
        }
        else {
            return ngZone.run(fn);
        }
    };
}
