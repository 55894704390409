import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { DesignExternalMetaData, DocumentAccessMode, DocumentServiceBase, IDefferedData as BaseDefferedData, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import cloneDeep from 'lodash-es/cloneDeep';
import { ProjectDesign } from './design.service';

type IDefferedData = BaseDefferedData<ProjectDesign, IDetailedDisplayDesign>;

// TODO FILIP: fix common
export interface DocumentServiceBaseInternal extends DocumentServiceBase {
    openDesign(design: IBaseDesign): Promise<ProjectDesign>;
    smallDesignChange(internalDesign: IDesignListItem, projectDesign: ProjectDesign, data: IDefferedData, metaData: DesignExternalMetaData): Promise<void>;
    defferedRequests: IDefferedRequests<unknown, unknown>;
    createUniqueName(oldName: string, usedNames: string[]): string;
}

export interface IDefferedImageData {
    designId: string;
    thumbnailContent: string;
    respond: boolean;
    isTemplate: boolean;
}

interface IDefferedRequests<TProjectDesign, TDetailedDisplayDesign> {
    [designId: string]: {
        defferedData: BaseDefferedData<TProjectDesign, TDetailedDisplayDesign> | IDefferedImageData | null;
        deffered: Deferred<void> | null;
        running: Promise<void>;
    };
}
// -----------------------------

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    private baseService!: DocumentServiceBaseInternal;

    public setBaseService(baseService: DocumentServiceBaseInternal): void {
        this.baseService = baseService;
    }

    public get projects(): Record<string, Project> {
        return this.baseService.projects;
    }

    public get draftsProject(): Project {
        return this.baseService.draftsProject;
    }

    public findProjectByDesignId(designId: string): Project {
        return this.baseService.findProjectByDesignId(designId);
    }

    public findDesignById(designId: string): IDesignListItem {
        return this.baseService.findDesignById(designId);
    }

    public findProjectById(projectId: string): Project {
        return this.baseService.findProjectById(projectId);
    }

    public addDesignCommon(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItem> {
        return this.baseService.addDesignCommon(projectId, design, canGenerateUniqueName, ignoreConflict);
    }

    public updateDesignWithNewContentCommon(design: Design, displayDesign: unknown, unlock: boolean, exclusiveLock: boolean, documentAccessMode?: DocumentAccessMode): Promise<void> {
        return this.baseService.updateDesignWithNewContentCommon(design, displayDesign, unlock, exclusiveLock, documentAccessMode);
    }

    public updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        return this.baseService.updateDesignThumbnailImage(designId, thumbnailContent, respond);
    }

    public publish(id: string): Promise<void> {
        return this.baseService.publish(id);
    }

    public copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        return this.baseService.copyDesign(documentId, documentName, projectId);
    }

    public moveDesign(documentId: string, projectId: string): Promise<void> {
        return this.baseService.moveDesign(documentId, projectId);
    }

    public openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        return this.baseService.openDesignExclusive<TProjectDesign>(design, adjustContent);
    }

    public getSessionKeyForDesign(designId: string): string {
        return this.baseService.getSessionKeyForDesign(designId);
    }

    public openDesign(design: IBaseDesign): Promise<ProjectDesign> {
        return this.baseService.openDesign(design);
    }

    // TODO FILIP: fix common
    public async updateDesignWithNewContent(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesign,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        // If there are no pending requests start normally
        if (this.baseService.defferedRequests[designId] == null || (this.baseService.defferedRequests[designId] != null && this.baseService.defferedRequests[designId].running == null)) {
            this.baseService.defferedRequests[designId] = {
                running: this.putSmallDesingChangePromise({
                    designId,
                    projectId,
                    designName,
                    contentOverride,
                    metadataOverride,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.baseService.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.baseService.defferedRequests[designId].deffered == null) {
                this.baseService.defferedRequests[designId].deffered = new Deferred();
            }

            this.baseService.defferedRequests[designId].defferedData = {
                designId,
                projectId,
                designName,
                contentOverride,
                metadataOverride,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await (this.baseService.defferedRequests[designId].deffered as Deferred).promise;
        }
    }

    private async putSmallDesingChangePromise(data: IDefferedData): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        const metaData = cloneDeep(data.metadataOverride);

        await this.baseService.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }

    public createUniqueName(oldName: string, usedNames: string[]): string {
        return this.baseService.createUniqueName(oldName, usedNames);
    }

    // -----------------------------
}
