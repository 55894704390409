import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { IAppSettingsComponent } from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { getSpecialRegionName, SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { format, formatKeyValue, sortByUnicode } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ApplicationSettingsStrength, AppSettingsService } from '../../services/app-settings.service';
import { DataService, Unit } from '../../services/data.service';
import { designTypes } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './app-settings-strength.component.html',
    styleUrls: ['./app-settings-strength.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AppSettingsStrengthComponent implements IAppSettingsComponent<ApplicationSettingsStrength>, OnChanges {
    @Input()
    public selectedRegionId!: number;

    @Input()
    public decimalSeparatorId!: number;

    @Input()
    public thousandsSeparatorId!: number;

    @Input()
    public submitted = false;

    @Input()
    public settings!: ApplicationSettingsStrength;

    public regionDropdown!: Pick<DropdownProps<KnownRegion | SpecialRegion>, 'id' | 'title' | 'items'>;
    public designStandardDropdown!: DropdownProps<number>;
    public lengthDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public areaDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public stressDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public forceDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public momentDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public temperatureDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public densityDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public areaPerLengthDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;
    public forcePerLengthDropdown!: Pick<DropdownProps<UnitType>, 'id' | 'title' | 'items'>;

    public isLoaded = false;

    constructor(
        private appSettingsService: AppSettingsService,
        private userSettingsService: UserSettingsService,
        private localizationService: LocalizationService,
        private dataService: DataService,
        private changeDetectionRef: ChangeDetectorRef
    ) { }

    public get regionId(): KnownRegion | SpecialRegion {
        return this.settings.regionId == SpecialRegion.Default
            ? this.selectedRegionId ?? KnownRegion.Unknown
            : this.settings.regionId;
    }

    public get designStandardDescription() {
        return this.localizationService.getString(this.dataService.designStandardsById[this.settings.designStandardId].descriptionKey);
    }

    public ngOnChanges(): void {
        if (!this.isLoaded) {
            this.onInit();

            this.isLoaded = true;
        }

        this.onChange();
    }

    private onInit() {
        this.initSettings();
        this.createControlsRegion();
        this.createControlsMethod();
        this.createControlsUnit();
    }

    private onChange() {
        this.updateQuickStart(false);
    }

    public onRegionDropdownChange(regionId: number) {
        this.settings.regionId = regionId;

        this.updateQuickStart(true);

        this.changeDetectionRef.detectChanges();
    }

    private initSettings() {

        const quickStart = this.userSettingsService.settings.sp.quickStart.strength;

        this.settings = this.settings ?? {};

        this.settings.regionId = this.settings.regionId ?? quickStart.regionId.value;
        this.settings.designStandardId = this.settings.designStandardId ?? quickStart.designStandardId.value;
        this.settings.length = this.settings.length ?? quickStart.length.value;
        this.settings.area = this.settings.area ?? quickStart.area.value;
        this.settings.stress = this.settings.stress ?? quickStart.stress.value;
        this.settings.force = this.settings.force ?? quickStart.force.value;
        this.settings.moment = this.settings.moment ?? quickStart.moment.value;
        this.settings.temperature = this.settings.temperature ?? quickStart.temperature.value;
        this.settings.density = this.settings.density ?? quickStart.density.value;
        this.settings.areaPerLength = this.settings.areaPerLength ?? quickStart.areaPerLength.value;
        this.settings.forcePerLength = this.settings.forcePerLength ?? quickStart.forcePerLength.value;
    }

    private createControlsRegion() {
        let regionItems = this.dataService.strengthRegions.map((x): DropdownItem<KnownRegion | SpecialRegion> => ({
            value: x.id,
            text: this.localizationService.getString(x.nameKey)
        }));

        // sort
        regionItems = sortByUnicode(regionItems, 'text');

        // add None and Default
        regionItems = [
            {
                value: SpecialRegion.None,
                text: this.localizationService.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.' + getSpecialRegionName(SpecialRegion.None))
            },
            {
                value: SpecialRegion.Default,
                text: this.localizationService.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.' + getSpecialRegionName(SpecialRegion.Default))
            },
            ...regionItems
        ];

        this.regionDropdown = {
            id: 'shear-application-settings-region',
            title: this.localizationService.getString('SP.ApplicationSettings.Region'),
            items: regionItems
        };

    }

    private createControlsMethod() {
        this.designStandardDropdown = {
            id: 'shear-application-settings-design-standard',
            title: this.localizationService.getString('SP.DesignStandard')
        };

        this.setDesignStandardItems();
    }

    private setDesignStandardItems() {
        this.designStandardDropdown.items = this.designStandards.map(designStandard => ({
            id: `shear-application-settings-design-standard-sp-dropdown-item-${designStandard.id}`,
            value: designStandard.id,
            text: this.localizationService.getString(designStandard.nameKey)
        }));
    }

    private updateControlsOnRegionChange() {
        this.setDesignStandardItems();
    }

    private createControlsUnit() {

        this.lengthDropdown = this.createUnitDropdown(
            'Length',
            this.dataService.units.length
        );

        this.areaDropdown = this.createUnitDropdown(
            'Area',
            this.dataService.units.area
        );

        this.stressDropdown = this.createUnitDropdown(
            'Stress',
            this.dataService.units.stress
        );

        this.forceDropdown = this.createUnitDropdown(
            'Force',
            this.dataService.units.force
        );

        this.momentDropdown = this.createUnitDropdown(
            'Moment',
            this.dataService.units.moment
        );

        this.temperatureDropdown = this.createUnitDropdown(
            'Temperature',
            this.dataService.units.temperature
        );

        this.forcePerLengthDropdown = this.createUnitDropdown(
            'ForcePerLength',
            this.dataService.units.forcePerLength
        );

        this.densityDropdown = this.createUnitDropdown(
            'Density',
            this.dataService.units.density,
        );


        this.areaPerLengthDropdown = this.createUnitDropdown(
            'AreaPerLength',
            this.dataService.units.areaPerLength,
        );
    }

    private createUnitDropdown(key: string, codeListUnits: Unit[]) {
        const items = codeListUnits.map(x => ({
            value: x.id,
            text: x.name
        }));

        const unitDropdown: DropdownProps<UnitType> = {
            id: 'shear-application-settings-unit-' + key.toLowerCase() + '-dropdown',
            title: this.translateDropdownTitle(`Units.${key}`),
            items
        };

        return unitDropdown;
    }

    private translateDropdownTitle(key: string): string {
        return this.localizationService.getString(`SP.ApplicationSettings.${key}`);
    }

    private get designStandards() {
        return this.dataService.designStandards.filter(x => this.allowedDesignStandardIds.includes(x.id));
    }

    private get allowedDesignStandardIds() {
        return this.dataService.getPropertyDetail('designStandardId', { designTypeId: designTypes.strength.id, regionId: this.regionId }).allowedValues ?? [];
    }

    public get allowQuickStart() {
        if (this.settings.regionId == SpecialRegion.None) {
            return false;
        }

        // default region might be a region that is not allowed
        if (this.settings.regionId == SpecialRegion.Default && !(this.selectedRegionId in this.dataService.strengthRegionsById)) {
            return false;
        }

        return true;
    }

    public get isRegionValid() {
        return this.allowQuickStart || this.settings.regionId == SpecialRegion.None;
    }

    public get allowStatus() {
        if (this.isRegionValid) {
            const translationFormat = this.localizationService.getString('SP.ApplicationSettings.Region.Strength.Ok');

            return format(translationFormat, this.localizationService.getString('Agito.Hilti.Profis3.ApplicationSettings.SpecialRegion.None'));
        }
        else {
            const spRegionOrGlobal = this.regionId == SpecialRegion.None
                ? this.selectedRegionId
                : this.regionId;

            const region = this.dataService.peRegionsById[spRegionOrGlobal];
            const translationFormat = this.localizationService.getString('SP.ApplicationSettings.Region.Strength.Error');

            return formatKeyValue(translationFormat, { region: this.localizationService.getString(region.nameKey) });
        }
    }

    private updateQuickStart(regionChanged: boolean) {
        // ignore regions that we don't support
        // when Default is selected with unsupported region we treat is the same as None
        if (this.regionId in this.dataService.strengthRegionsById) {
            if (regionChanged) {
                this.appSettingsService.handleStrengthRegionChange(this.settings, this.regionId, true);
                this.updateControlsOnRegionChange();
            }
        }

        this.changeDetectionRef.detectChanges();
    }
}
