import { Component, ViewEncapsulation } from '@angular/core';
import coverImage from '../../../../images/c_vl.png';
import { StrengthDesignDetails } from '../../../../services/design.service';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';

export interface InfoDialogCoverInput {
    strengthDesignDetails: StrengthDesignDetails;
}

@Component({
    templateUrl: './info-dialog-cover.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogCoverComponent extends InfoDialogBaseComponent<InfoDialogCoverInput> {
    public coverImage = coverImage;
    public getTitle(): string {
        // Title must match with Cover control label
        return this.translate('SP.ExistingReinforcement.Cover.InfoPopUp.Title');
    }
}
