import { Injectable } from '@angular/core';
import { CommonTrackingServiceBase, TrackingUserData } from '@profis-engineering/pe-ui-common/services/common-tracking.common';

@Injectable({
  providedIn: 'root'
})
export class CommonTrackingService {
    private baseService!: CommonTrackingServiceBase;

    public setBaseService(baseService: CommonTrackingServiceBase): void {
        this.baseService = baseService;
    }

    public getTrackingUserData(timezoneOffset?: number): TrackingUserData {
        return this.baseService.getTrackingUserData(timezoneOffset);
    }
}
