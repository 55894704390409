import { Injectable } from '@angular/core';
import { RegionOrderServiceBase } from '@profis-engineering/pe-ui-common/services/region-order.common';

@Injectable({
    providedIn: 'root'
})
export class RegionOrderService {
    private baseService!: RegionOrderServiceBase;

    public setBaseService(baseService: RegionOrderServiceBase) {
        this.baseService = baseService;
    }

    public update(order: string[], menuId: number): Promise<string[]> {
        return this.baseService.update(order, menuId);
    }
}
