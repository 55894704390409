import { Injectable } from '@angular/core';
import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {
    private baseService!: RoutingServiceBase;

    public setBaseService(baseService: RoutingServiceBase): void {
        this.baseService = baseService;
    }

    public get currentUrl(): URL {
        return this.baseService.currentUrl;
    }

    public get currentPath(): string {
        return this.baseService.currentPath;
    }

    public getUrl(url: string, queryParams?: Record<string, unknown>): string {
        return this.baseService.getUrl(url, queryParams);
    }

    public reloadWindow(url: string): void {
        this.baseService.reloadWindow(url);
    }

    public navigateToUrl(url: string): Promise<boolean> {
        return this.baseService.navigateToUrl(url);
    }
}
