import { Injectable } from '@angular/core';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeListService } from './common-code-list.service';
import { DataService } from './data.service';
import { designTypes } from './design.service';
import { UserSettingsService } from './user-settings.service';

export interface ApplicationSettings {
    regionId: KnownRegion | SpecialRegion;
    designStandardId: number;
    length: UnitType;
    area: UnitType;
    stress: UnitType;
    force: UnitType;
    moment: UnitType;
    temperature: UnitType;
    forcePerLength: UnitType;
    density: UnitType;
    areaPerLength: UnitType;
}
export type ApplicationSettingsStrength = ApplicationSettings;

export type ApplicationSettingsPunch = ApplicationSettings;

@Injectable({
    providedIn: 'root',
})
export class AppSettingsService {
    constructor(
        private userSettingsService: UserSettingsService,
        private commonCodeListService: CommonCodeListService,
        private dataService: DataService
    ) {}

    public loadStrengthSettings(): ApplicationSettingsStrength {
        const quickStart = this.userSettingsService.settings.sp.quickStart.strength;

        return {
            regionId: quickStart.regionId.value,
            designStandardId: quickStart.designStandardId.value,
            length: quickStart.length.value,
            area: quickStart.area.value,
            stress: quickStart.stress.value,
            force: quickStart.force.value,
            moment: quickStart.moment.value,
            temperature: quickStart.temperature.value,
            forcePerLength: quickStart.forcePerLength.value,
            density: quickStart.density.value,
            areaPerLength: quickStart.areaPerLength.value,
        };
    }

    public loadPunchSettings(): ApplicationSettingsPunch {
        const quickStart = this.userSettingsService.settings.sp.quickStart.punch;

        return {
            regionId: quickStart.regionId.value,
            designStandardId: quickStart.designStandardId.value,
            length: quickStart.length.value,
            stress: quickStart.stress.value,
            area: quickStart.area.value,
            force: quickStart.force.value,
            moment: quickStart.moment.value,
            temperature: quickStart.temperature.value,
            forcePerLength: quickStart.forcePerLength.value,
            density: quickStart.density.value,
            areaPerLength: quickStart.areaPerLength.value,
        };
    }

    public handleStrengthRegionChange(applicationSettings: ApplicationSettingsStrength, regionId: number, resetToDefault = false): void {
        // ignore regions that we don't support
        // when Default is selected with unsupported region we treat it the same as None

        // if region in shear UI settings is not Default, we do not change selected values
        if (applicationSettings.regionId > 0 && !resetToDefault)
            return;

        if (regionId in this.dataService.strengthRegionsById) {
            applicationSettings.designStandardId = this.dataService.getPropertyDetail('designStandardId', { designTypeId: designTypes.strength.id, regionId }).defaultValue;
            applicationSettings.length = this.dataService.getPropertyDetail('unitLength', { regionId }).defaultValue;
            applicationSettings.area = this.dataService.getPropertyDetail('unitArea', { regionId }).defaultValue;
            applicationSettings.stress = this.dataService.getPropertyDetail('unitStress', { regionId }).defaultValue;
            applicationSettings.force = this.dataService.getPropertyDetail('unitForce', { regionId }).defaultValue;
            applicationSettings.moment = this.dataService.getPropertyDetail('unitMoment', { regionId }).defaultValue;
            applicationSettings.temperature = this.dataService.getPropertyDetail('unitTemperature', { regionId }).defaultValue;
            applicationSettings.forcePerLength = this.dataService.getPropertyDetail('unitForcePerLength', { regionId }).defaultValue;
            applicationSettings.density = this.dataService.getPropertyDetail('unitDensity', { regionId }).defaultValue;
            applicationSettings.areaPerLength = this.dataService.getPropertyDetail('unitAreaPerLength', { regionId }).defaultValue;
        }
    }

    public handlePunchRegionChange(applicationSettings: ApplicationSettingsPunch, regionId: number, resetToDefault = false): void {
        // ignore regions that we don't support
        // when Default is selected with unsupported region we treat is the same as None

        // if region in punch UI settings is not Default, we do not change selected values
        if (applicationSettings.regionId > 0 && !resetToDefault)
            return;

        if (regionId in this.dataService.punchRegionsById) {
            applicationSettings.designStandardId = this.dataService.getPropertyDetail('designStandardId', { designTypeId: designTypes.punch.id, regionId }).defaultValue;
            applicationSettings.length = this.dataService.getPropertyDetail('unitLength', { regionId }).defaultValue;
            applicationSettings.stress = this.dataService.getPropertyDetail('unitStress', { regionId }).defaultValue;
            applicationSettings.area = this.dataService.getPropertyDetail('unitArea', { regionId }).defaultValue;
            applicationSettings.force = this.dataService.getPropertyDetail('unitForce', { regionId }).defaultValue;
            applicationSettings.moment = this.dataService.getPropertyDetail('unitMoment', { regionId }).defaultValue;
            applicationSettings.temperature = this.dataService.getPropertyDetail('unitTemperature', { regionId }).defaultValue;
            applicationSettings.forcePerLength = this.dataService.getPropertyDetail('unitForcePerLength', { regionId }).defaultValue;
            applicationSettings.density = this.dataService.getPropertyDetail('unitDensity', { regionId }).defaultValue;
            applicationSettings.areaPerLength = this.dataService.getPropertyDetail('unitAreaPerLength', { regionId }).defaultValue;
        }
    }

    public updateStrengthSettings(applicationSettings: ApplicationSettingsStrength): void {
        if (applicationSettings == null) {
            return;
        }

        const quickStart = this.userSettingsService.settings.sp.quickStart.strength;

        quickStart.regionId.value = applicationSettings.regionId;
        quickStart.designStandardId.value = applicationSettings.designStandardId;
        quickStart.length.value = applicationSettings.length;
        quickStart.area.value = applicationSettings.area;
        quickStart.stress.value = applicationSettings.stress;
        quickStart.force.value = applicationSettings.force;
        quickStart.moment.value = applicationSettings.moment;
        quickStart.temperature.value = applicationSettings.temperature;
        quickStart.density.value = applicationSettings.density;
        quickStart.areaPerLength.value = applicationSettings.areaPerLength;
        quickStart.forcePerLength.value = applicationSettings.forcePerLength;
    }

    public updatePunchSettings(applicationSettings: ApplicationSettingsPunch): void {
        if (applicationSettings == null) {
            return;
        }

        const quickStart = this.userSettingsService.settings.sp.quickStart.punch;

        quickStart.regionId.value = applicationSettings.regionId;
        quickStart.designStandardId.value = applicationSettings.designStandardId;
        quickStart.length.value = applicationSettings.length;
        quickStart.stress.value = applicationSettings.stress;
        quickStart.area.value = applicationSettings.area;
        quickStart.force.value = applicationSettings.force;
        quickStart.moment.value = applicationSettings.moment;
        quickStart.temperature.value = applicationSettings.temperature;
        quickStart.density.value = applicationSettings.density;
        quickStart.areaPerLength.value = applicationSettings.areaPerLength;
        quickStart.forcePerLength.value = applicationSettings.forcePerLength;
    }
}
