import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-zone-utilization-item',
    templateUrl: './zone-utilization-item.component.html',
    styleUrls: ['./zone-utilization-item.component.scss']
})

export class ZoneUtilizationItemComponent {
    @Input()
    public controlId = '';

    @Input()
    public showPercentBar = true;

    @Input()
    public showNumberContainer = true;

    @Input()
    public percentValue = 0;

    @Input()
    public value?: string;

    @Input()
    public title? = '';

    @Input()
    public utilizationVariable? = '';

    @Input()
    public header = '';

    @Input()
    public percentageBarStyle = '';

    @Input()
    public numberContainerStyle = '';

    public get hasValue(): boolean {
        return this.value != null;
    }

    public getComponentId(type: string) {
        return `${this.controlId}-${type}`;
    }
}
