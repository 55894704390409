import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TooltipKey as BaseTooltipKey } from '@profis-engineering/gl-model/external/tooltip';
import { ICameraValues3d, LoadsVisibilityInfo } from '@profis-engineering/gl-model/gl-model';
import { DeepPartial } from 'ts-essentials';
import { PunchDesignDetails, PunchPropertyId } from '../services/design.service';
import { BaseUpdate, GlModel, Model, ModelUpdateClass, ScreenshotSettings, UnitText2D } from './gl-model';
import { PunchBaseMaterial } from './components/punch-base-material';
import { PunchSceneCoordinateSystem } from './components/punch-scene-coordinate-system';
import { PunchCoordinateSystem } from './components/punch-coordinate-system';

export interface PunchModel extends Model {
    baseMaterial: BaseMaterialModel;
    visibilityModel: VisibilityModel;
}

export type PunchPartialModel = DeepPartial<PunchModel>;
export type PunchUnitText2D = UnitText2D<PunchPropertyId>;

export interface VisibilityModel {
    ConcreteDimensionsVisible: boolean;
    TransparentConcrete: boolean;
    AnchorSpacingDimensionsVisible: boolean;
    AnchorEdgeDistanceDimensionsVisible: boolean;
}

export interface BaseMaterialModel {
    width: number;
    length: number;
    height: number;
}

export type PunchTooltipKey = BaseTooltipKey |
    'BaseMaterial';

export abstract class PunchBaseUpdate extends BaseUpdate<PunchPropertyId> {}
export type PunchModelUpdateClass = ModelUpdateClass<PunchPropertyId>;

export interface PunchModelUpdate {
    BaseMaterialUpdate?: PunchModelUpdateClass;
}

export class PunchGlModel extends GlModel<
    PunchModel,
    PunchPropertyId,
    PunchTooltipKey,
    PunchModelUpdate,
    PunchDesignDetails
> {
    protected override initializeComponentDefinitions(modelUpdate: PunchModelUpdate): void {
        this.componentDefinitions = [
            {
                componentType: PunchBaseMaterial,
                options: {
                    updateModelCtor: modelUpdate?.BaseMaterialUpdate
                }
            },
            {
                componentType: PunchSceneCoordinateSystem
            },
            {
                componentType: PunchCoordinateSystem
            },
        ];
    }

    protected override calculateCameraValues3d(): ICameraValues3d {
        return {
            alpha: Math.PI / 6,
            beta: Math.PI / 4,
            radius: 12500,
            betaMin: -Math.PI / 2 + 0.01,
            betaMax: Math.PI / 2 - 0.01,
            radiusMin: 1000,
            radiusMax: 30000
        };
    }

    protected override setLights(): void {
        this.frontLight.direction = new Vector3(0.35, 4, 1);
        this.frontLight.specular = Color3.Black();
        this.frontLight.intensity = 0.575;

        this.backLight.direction = new Vector3(-0.35, -4, -1);
        this.backLight.specular = Color3.Black();
        this.backLight.intensity = 0.575;

        this.groundLight.direction = Vector3.Zero();
        this.groundLight.specular = Color3.Black();
        this.groundLight.diffuse = Color3.White();
        this.groundLight.groundColor = Color3.White();
        this.groundLight.intensity = 0.4;
    }

    protected override ensureScreenShotSettingsInternal(): Vector3 {
        // TODO TEAM: what is this magic number?
        return new Vector3(4420, -8840, -7650);
    }

    /**
     * Method sets visibility for scene meshes for generating screenshot
     */
    protected override prepareVisibilityForScreenshot(zoomed: boolean, info: LoadsVisibilityInfo, preview: boolean, extraInfo: ScreenshotSettings) {
        // no highlighting
        for (const highlightedDimension in this.model.highlightedDimensions) {
            this.model.highlightedDimensions[highlightedDimension] = false;
        }

        this.scene3d.clearColor = new Color4(1, 1, 1, 1);

        if (extraInfo.isThumbnail) {
            this.scene3d.clearColor = new Color4(0.933, 0.933, 0.933, 1);

            if (this.model.sceneCoordinateSystem != null) {
                this.model.sceneCoordinateSystem.hidden = true;
            }
        }
        else if (extraInfo.preview) {
            if (this.model.sceneCoordinateSystem != null) {
                this.model.sceneCoordinateSystem.hidden = true;
            }
        }

        // Shows or hides the coordinate system, along with some minor extra settings.
        // (+) In actual report, coordinate system should be visible.
        // (+) On report export dialog and home page, don't show coordinate system.
        this.model.sceneCoordinateSystem!.report = true;
        this.model.screenshot = true;

        // Enabling the visibility of all elements.
        this.model.visibilityModel.AnchorEdgeDistanceDimensionsVisible = true;
        this.model.visibilityModel.AnchorSpacingDimensionsVisible = true;
        this.model.visibilityModel.ConcreteDimensionsVisible = true;
        this.model.visibilityModel.TransparentConcrete = true;

        this.refreshComponents();
    }
}
