<div class="notifications box-section box-section-white" *ngIf="isVisible">
    <div class="box-section-header-with-img">
        <div class="image">
            <div class="pe-ui-sp-sprite pe-ui-sp-sprite-info"></div>
        </div>

        <span class="box-section-title notification-title drag-handle-static"
            [l10n]="'Agito.Hilti.Profis3.Notifications'"
            [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.DragToMove' | l10n: localizationService.selectedLanguage"
            [class.flex]="collapsed">
        </span>

        <div class="notification-indicator-wrapper" *ngIf="collapsed">
            <div class="notification-indicator-container notification-{{NotificationTypeName[NotificationType.info]}}"
                *ngIf="getNotificationCount(NotificationType.info) > 0">
                <div class="notification-indicator-icon pe-ui-sp-sprite pe-ui-sp-sprite-{{NotificationTypeImage[NotificationType.info]}}"></div>
                <div class="notification-indicator-content">{{getNotificationCount(NotificationType.info)}}</div>
            </div>

            <div class="notification-indicator-container notification-{{NotificationTypeName[NotificationType.alert]}}"
                *ngIf="getNotificationCount(NotificationType.alert) > 0">
                <div class="notification-indicator-icon pe-ui-sp-sprite pe-ui-sp-sprite-{{NotificationTypeImage[NotificationType.alert]}}"></div>
                <div class="notification-indicator-content">{{getNotificationCount(NotificationType.alert)}}</div>
            </div>

        </div>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="box-section-content">
        <div *ngIf="hasInfoMessageVisible">
            <div  class="buttons" *ngFor="let notification of beforeSCNotifications">
                <pe-notification [notification]="notification"></pe-notification>
            </div>
        </div>

        <div class="scope-checks" *ngIf="hasAlertScopeChecks">
            <div *ngFor="let scopeCheck of alertScopeChecks;">
                <pe-notification-scope-check [scopeCheck]="scopeCheck"></pe-notification-scope-check>
            </div>
        </div>

        <div *ngIf="hasInfoMessageVisible">
            <div class="buttons" *ngFor="let notification of betweenSCNotifications">
                <pe-notification [notification]="notification"></pe-notification>
            </div>
        </div>

        <div class="scope-checks" *ngIf="hasInfoScopeChecks && hasInfoMessageVisible">
            <div *ngFor="let scopeCheck of infoScopeChecks;">
                <pe-notification-scope-check [scopeCheck]="scopeCheck"></pe-notification-scope-check>
            </div>
        </div>

        <div *ngIf="hasInfoMessageVisible">
            <div  class="buttons" *ngFor="let notification of afterSCNotifications">
                <pe-notification [notification]="notification"></pe-notification>
            </div>
        </div>
    </div>
</div>
