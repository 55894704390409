<pe-modal-header [modalInstance]="modalInstance" [title]="getTitle()"></pe-modal-header>

<pe-modal-body>
    <div>

        <span l10n="SP.PostInstalled.TransverseEccentricity.InfoPopUp.PartOne"></span>
        <br />
        <div class="info-dialog-image">
            <img class="image" [src]="transverseEccentricityImage" alt="transverse-eccentricity-image">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="info-dialog-ok-button" [look]="'Primary'" (buttonClick)="close()" [text]="translate('SP.General.OK')">
    </pe-button>
</pe-modal-footer>
