import { NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Command, commandFromService } from '../../command';
import { getSpriteAsIconStyle } from '../../sprites';
import { StrengthRegion, StrengthTab } from './static-menu.strength.definitions';

export function createSPMenu_ExistingReinforcement(): StrengthTab {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement',
        Id: 3,
        Name: 'existingreinforcement-tab',
        Image: 'tab-existing-reinforcement',
        IconImage: getSpriteAsIconStyle('sprite-tab-existing-reinforcement'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_ExistingReinforcement_Materials(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone1(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone2(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone3(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone4(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone5(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone6(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone7(),
            createSPMenu_ExistingReinforcement_TensileReinforcement_Zone8(),
        ]
    };
}

function createSPMenu_ExistingReinforcement_Materials(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionMaterials',
        Id: 30,
        Name: 'materials-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'materials-reinforcement-yield-strength',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionMaterials.ReiforcementYieldStrength',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'reinforcementYieldStrength'
            },
            {
                ControlType: 'TextBox',
                Name: 'materials-reinforcement-yield-strength-sia',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionMaterials.ReiforcementYieldStrength.Sia',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'reinforcementYieldStrengthSia'
            }
        ]
    };
}


function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone1(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone1',
        Id: 31,
        Name: 'tensile-reinforcement-zone1-section',
        Controls: [
            // TODO: it might come back later with https://hilti.atlassian.net/browse/PSP-178
            // {
            //     ControlType: 'Checkbox',
            //     Name: 'define-tensioned-bars',
            //     DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineBars',
            //     UIPropertyId: 'definedTensionBars'
            // },
            {
                ControlType: 'TextBox',
                Name: 'zone1-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone1CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone1Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone1EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone1-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone1DefineShearReinforcement'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone1-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone1ReinforcementContribution'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone2(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone2',
        Id: 32,
        Name: 'tensile-reinforcement-zone2-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'zone2-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone2CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone2-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone2Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone2-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone2EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone2-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone2DefineShearReinforcement'
            }            ,
            {
                ControlType: 'TextBox',
                Name: 'zone2-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone2ReinforcementContribution'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone3(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone3',
        Id: 33,
        Name: 'tensile-reinforcement-zone3-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'zone3-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone3CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone3Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone3EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone3-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone3DefineShearReinforcement'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone3-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone3ReinforcementContribution'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone4(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone4',
        Id: 34,
        Name: 'tensile-reinforcement-zone4-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'zone4-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone4CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone4Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone4EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone4-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone4DefineShearReinforcement'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone4-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone4ReinforcementContribution'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone5(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone5',
        Id: 35,
        Name: 'tensile-reinforcement-zone5-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'zone5-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone5CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone5Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone5EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone5-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone5DefineShearReinforcement'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone5-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone5ReinforcementContribution'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone6(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone6',
        Id: 36,
        Name: 'tensile-reinforcement-zone6-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'zone6-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone6CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone6Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup),
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone6EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone6-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone6DefineShearReinforcement'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone6-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone6ReinforcementContribution'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone7(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone7',
        Id: 37,
        Name: 'tensile-reinforcement-zone7-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'zone7-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone7CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone7Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone7EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone7-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone7DefineShearReinforcement'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone7-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone7ReinforcementContribution'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement_Zone8(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionZone8',
        Id: 38,
        Name: 'tensile-reinforcement-zone8-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'zone8-cross-sectional-area',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalArea',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'zone8CrossSectionalArea',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCrossSectionalAreaPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-cover',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.Cover',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone8Cover',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenCoverPopup)
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-effective-height',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeight',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'zone8EffectiveHeight',
                TooltipType: TooltipType.Popup,
                Command: commandFromService(Command.OpenEffectiveHeightPopup)
            },
            {
                ControlType: 'Checkbox',
                Name: 'zone8-define-shear-reinforcement',
                DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.DefineShearReinforcement',
                UIPropertyId: 'zone8DefineShearReinforcement'
            },
            {
                ControlType: 'TextBox',
                Name: 'zone8-reinforcement-contribution',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.ReinforcementContribution',
                UnitGroup: UnitGroup.Force,
                UIPropertyId: 'zone8ReinforcementContribution'
            }
        ]
    };
}
