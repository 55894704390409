import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { includeSprites } from '../../sprites';
import { LoadsComponentHelper } from '../../loads-component-helper';
import { LoadCombinationRowData } from '../loads-row/loads-row.component';
import { LoadCombination, LoadType } from '../../services/design.service';

export const DEFAULT_MAX_LOADS_HEIGHT = 334;

@Component({
    selector: 'app-loads',
    templateUrl: './loads.component.html',
    styleUrls: ['../loads-row/loads-base.scss', './loads.component.scss'],
})
export class LoadsComponent implements OnInit, OnChanges {
    private static readonly loadsRowHeight: number = 32; // sync with css variable @loads-row-height
    private static readonly resizedAndBorderPadding: number = 6; // 4 resizer + 2 borders

    @Input()
    public resize3d!: (resetHighlightedComponents?: boolean) => void;

    @Input()
    public unitForce!: UnitType;

    @Input()
    public numberOfZones!: number;

    @Input()
    public loadCombinations!: LoadCombination[];

    @Output()
    public loadCombinationsChange = new EventEmitter<LoadCombination[]>();

    public collapsed = false;
    public helper: LoadsComponentHelper;

    constructor(
        private localization: LocalizationService,
        private unit: UnitService,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        this.helper = new LoadsComponentHelper(
            localization,
            unit
        );
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement,
            'sprite-anchor-shock',
            'sprite-lines-expanded',
            'sprite-lines'
        );

        this.setMinimumHeight();
    }

    public ngOnChanges(): void {
        this.helper.numberOfZones = this.numberOfZones;
        this.helper.unitForce = this.unitForce;
    }

    public get selectedLoad() {
        return this.loadCombinations[0];
    }

    public get selectedLoadTypeName() {
        return this.translate('SP.Loads.' + LoadType[this.selectedLoad.loadTypeId]);
    }

    public get zoneHeaderFlex() {
        const zoneHeaderFlex = this.helper.numberOfZones * this.helper.headerFlexValue;

        if (this.helper.numberOfZones == 1) {
            return { flex: 2 * zoneHeaderFlex };
        }
        else {
            return { flex: zoneHeaderFlex };
        }
    }

    public get zonesHeader() {
        return `${this.translate('SP.Loads.Header.Force')} [${this.unit.getUnitStrings(this.unitForce)[0]}]`;
    }

    public get titleTranslation() {
        return 'Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.ControlLoadCombinations.Title';
    }

    public get loadsContainer() {
        return this.elementRef.nativeElement.querySelector('.loads-container') as HTMLElement;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public formatZone(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unit.getDefaultUnit(UnitGroup.Force);
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Force);
        const precision = this.unit.getPrecision(defaultUnit);
        const defaultValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(defaultValue, precision);
    }

    public onCollapsedChanged() {
        this.setMinimumHeight();
        if (this.resize3d != null) {
            this.resize3d();
        }
    }

    private setMinimumHeight() {
        const minimumHeight = !this.collapsed
            ? LoadsComponent.loadsRowHeight * 3 + LoadsComponent.resizedAndBorderPadding
            : 0;

        this.loadsContainer.style.minHeight = minimumHeight + 'px';
    }

    public onLoadChanged(rowData: LoadCombinationRowData) {
        this.loadCombinations[rowData.index] = rowData.loadCombination;
        this.loadCombinationsChange.emit(this.loadCombinations);
    }
}
