import { Injectable } from '@angular/core';
import { Change, ChangesServiceBase } from '@profis-engineering/pe-ui-common/services/changes.common';

@Injectable({
  providedIn: 'root'
})
export class ChangesService {
    private baseService!: ChangesServiceBase;

    public setBaseService(baseService: ChangesServiceBase): void {
        this.baseService = baseService;
    }

    public getDeepChanges(source: object, target: object, ignoreUndefined?: boolean): Record<string, Change> {
        return this.baseService.getDeepChanges(source, target, ignoreUndefined);
    }

    public getShallowChanges(source: object, target: object, ignoreUndefined?: boolean): Record<string, Change> {
        return this.baseService.getShallowChanges(source, target, ignoreUndefined);
    }

    public chainChanges(...changes: Change[][]): Change[] {
        return this.baseService.chainChanges(...changes);
    }
}
