import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DataService } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';

type NumericTextBoxPropsBound = Pick<NumericTextBoxProps, 'id' | 'title' | 'placeholder' | 'unit' | 'minValue' | 'maxValue' | 'tooltip' | 'precision'>;

type ControlPropertyId = 'alphaCC';

@Component({
    selector: 'app-add-edit-design-inputs-concrete-material',
    templateUrl: './add-edit-design-inputs-concrete-material.component.html',
    styleUrls: ['./add-edit-design-inputs-concrete-material.component.scss'],
})
export class AddEditDesignInputsConcreteMaterialComponent implements OnInit, OnChanges {
    @Input()
    public alphaCC: number | null = null;

    @Output()
    public alphaCCChange = new EventEmitter<number | null>();

    @Input()
    public etaT = 1;

    @Output()
    public etaTChange = new EventEmitter<number>();

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designStandardId!: number | undefined;

    @Input()
    public etaTValues!: DropdownItem<number>[];

    public alphaCCTextBox!: NumericTextBoxPropsBound;
    public etaTDropDown!: DropdownProps<number>;

    constructor(
        private localizationService: LocalizationService,
        private unit: UnitService,
        private dataService: DataService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['regionId']?.firstChange === false) {
            this.onRegionChange();
        }
    }

    public ngOnInit(): void {
        this.initControls();
        this.onRegionChange();
    }

    private updateControl(control: NumericTextBoxPropsBound, propertyId: ControlPropertyId) {
        const regionId = this.regionId;
        const propertyValue = this.dataService.getPropertyDetail(propertyId, { regionId });

        control.maxValue = propertyValue.maxValue;
        control.minValue = propertyValue.minValue;
        control.placeholder = propertyValue.defaultValue ?? this.localizationService.getString('SP.Placeholder.Default');
        control.precision = propertyValue.precision;
    }

    private onRegionChange() {
        this.updateControl(this.alphaCCTextBox, 'alphaCC');
    }

    private initControls() {
        this.alphaCCTextBox = {
            id: 'concrete-material-alphaCC',
            title: this.localizationService.getString('SP.ReductionFactors.AlphaCC'),
            tooltip: {
                title: this.localizationService.getString('SP.ReductionFactors.AlphaCC'),
                content: this.localizationService.getString('SP.ReductionFactors.AlphaCC.Tooltip')
            },
            unit: UnitType.None
        };

        this.etaTDropDown = {
            id: 'concrete-material-etaT',
            title: this.localizationService.getString('SP.ReductionFactors.EtaT'),
            items: this.etaTValues,
            tooltip: {
                title: this.localizationService.getString('SP.ReductionFactors.EtaT'),
                content: this.localizationService.getString('SP.ReductionFactors.EtaT.Tooltip')
            }
        };
    }

    public isSIADesignStandard(designStandardId: number | undefined) {
        return designStandardId == 2;
    }
}
