import { Component, ViewEncapsulation } from '@angular/core';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';

@Component({
    templateUrl: './info-dialog-reinforcement-effectiveness.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})

export class InfoDialogReinforcementEffectivenessComponent extends InfoDialogBaseComponent {

    public getTitle(): string {
        return this.translate('SP.Loads.ReinforcementEffectiveness.InfoPopUp.Title');
    }

}
