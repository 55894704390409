import { Injectable } from '@angular/core';
import { BrowserServiceBase } from '@profis-engineering/pe-ui-common/services/browser.common';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {
    private baseService!: BrowserServiceBase;

    public setBaseService(baseService: BrowserServiceBase): void {
        this.baseService = baseService;
    }

    public downloadBlob(blob: Blob, fileName: string, storeInTemp: boolean, openAfterSave: boolean, filePath?: string): Promise<string> {
        return this.baseService.downloadBlob(blob, fileName, storeInTemp, openAfterSave, filePath);
    }

    public blobToBase64(blob: Blob): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64WithMetadata = reader.result as string;
                const base64MetadataIndex = base64WithMetadata.lastIndexOf(',');
                const base64 = base64WithMetadata.substring(base64MetadataIndex + 1);

                resolve(base64);
            };
            reader.readAsDataURL(blob);
        });
    }

    public blobToDataUrl(blob: Blob): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });
    }

    public readAsText(input: File | Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const temporaryFileReader = new FileReader();

            temporaryFileReader.addEventListener('error', () => {
                temporaryFileReader.abort();
                reject(new Error('Problem parsing input file.'));
            });
            temporaryFileReader.addEventListener('load', () => {
                resolve(temporaryFileReader.result as string);
            });
            temporaryFileReader.readAsText(input);
        });
    }
}
