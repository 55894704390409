import { PunchRegion, PunchTab } from './static-menu.punch.definitions';
import { getSpriteAsIconStyle } from '../../sprites';
import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';

export function createSPMenu_PostInstalledElement(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled',
        Id: 4,
        Name: 'postinstalled-tab',
        Image: 'tab-supplementary-reinforcement',
        IconImage: getSpriteAsIconStyle('sprite-tab-supplementary-reinforcement'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_PostInstalledElement_ShearReinforcement(),
        ]
    };
}

function createSPMenu_PostInstalledElement_ShearReinforcement(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement',
        Id: 40,
        Name: 'strengthening-element-section',
        Controls: [
            {
                ControlType: 'PopupGrid',
                Name: 'postinstalled-family-popup-grid',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Family',
                CodelistName: 'fastenerFamilyGroups',
                UIPropertyId: 'fastenerFamilyGroupId',
                WrapName: true
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-type-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Type',
                CodelistName: 'fastenerFamilies',
                UIPropertyId: 'fastenerFamilyId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'postinstalled-size-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabPostInstalled.RegionStrengtheningElement.Size',
                CodelistName: 'fasteners',
                UIPropertyId: 'fastenerId'
            }
        ]
    };
}