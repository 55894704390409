import type schema from './environment.local.json';
import { EnvironmentHelper } from '@profis-engineering/pe-ui-common/helpers/environment-helper';
import { QueryService } from '../sp/services/query.service';

export type Environment = typeof schema & {
    baseUrl: string;
    debugGlModel: boolean;
    /** contains files hashes like translations */
    manifest?: Record<string, string>;
};

export const environment: Environment = getEnvironmentSettings();

const queryService = new QueryService();
queryService.initQuery();

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0].href;

if (queryService.featureQueryParamContains('sphtmlreport'))
    environment.exportHtmlReport = true;

// set debug
environment.debugGlModel = queryService.featureQueryParamContains('debug');

declare global {
    interface Window {
        environmentSP: Environment;
    }
}

function getEnvironmentSettings(): Environment {
    const env: Environment = window.environmentSP;
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion] = helper.getServiceVersions();

    if (serviceVersion != '') {
        env.designServiceUrl = helper.formatServiceVersion(env.designServiceUrl, serviceVersion);
        env.calculationServiceUrl = helper.formatServiceVersion(env.calculationServiceUrl, serviceVersion);
        env.reportServiceUrl = helper.formatServiceVersion(env.reportServiceUrl, serviceVersion);
        env.htmlReportServiceUrl = helper.formatServiceVersion(env.htmlReportServiceUrl, serviceVersion);
    }

    return env ?? {};
}
