export function registerGlobalStyles() {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(`
        :root {
            --pe-ui-sp-color-chilli: #a80015;
            --pe-ui-sp-color-white: #fff;
            --pe-ui-sp-light-gambogeish-gray: #e7e5e2;
            --pe-ui-sp-color-iron: #524f53;
        }`);
    document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
}
