import { StrengthBaseUpdate, StrengthModel } from '../../../web-gl/strength-gl-model';
import { strengthPropertyValueChanged } from '../update';

export class OpeningUpdate extends StrengthBaseUpdate {

    @strengthPropertyValueChanged('defineOpening')
    public updateDefineOpening(defineOpening: boolean, model: StrengthModel) {
        model.opening.defineOpening = defineOpening;
        if (defineOpening) {
            model.zones.numberOfZones = 8;
            model.visibilityModel.AnchorEdgeDistanceDimensionsVisible = false;
            model.visibilityModel.AnchorSpacingDimensionsVisible = false;
        }
    }

    @strengthPropertyValueChanged('openingLength')
    public updateOpeningLength(openingLength: number, model: StrengthModel) {
        model.opening.openingLength = openingLength;
    }

    @strengthPropertyValueChanged('openingWidth')
    public updateOpeningWidth(openingWidth: number, model: StrengthModel) {
        model.opening.openingWidth = openingWidth;
    }

    @strengthPropertyValueChanged('openingOriginX')
    public updateOpeningOriginX(openingOriginX: number, model: StrengthModel) {
        model.opening.openingOriginX = openingOriginX;
    }

    @strengthPropertyValueChanged('openingOriginY')
    public updateOpeningOriginY(openingOriginY: number, model: StrengthModel) {
        model.opening.openingOriginY = openingOriginY;
    }
}
