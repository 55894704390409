import { Injectable } from '@angular/core';
import { FeatureFlagFromPeUi, FeatureVisibilityServiceBase } from '@profis-engineering/pe-ui-common/services/feature-visibility.common';
import uniq from 'lodash-es/uniq';
import { environment } from '../../environments/environment';
import { substringAfterLast } from '../helpers/string-helper';
import { QueryService } from './query.service';

export type FeatureFlag =
    FeatureFlagFromPeUi |
    'SP_Wasm' |
    'SP_ContentFeature_Strength' |
    'SP_ContentFeature_Punch' |
    'SP_StrengthRegions' |
    'SP_PunchRegions';

/** Below interfaces can be removed once we use common version with updated base service (that contains allFlags method) */
export interface LDFlagSet {
    [key: string]: unknown;
}

export interface FeatureVisibilityServiceBaseInternal extends FeatureVisibilityServiceBase<FeatureFlag> {
    allFlags: () => LDFlagSet;
}

@Injectable({
    providedIn: 'root'
})
export class FeatureVisibilityService {
    private baseService!: FeatureVisibilityServiceBaseInternal;

    private configEnabledFeatures: { key: string; multivariantValue?: unknown; enabled: boolean }[];

    constructor(private queryService: QueryService) {
        this.queryService = queryService;
        this.configEnabledFeatures = environment.featuresConfig?.filter(f => f.enabled) ?? [];
    }

    public setBaseService(baseService: FeatureVisibilityServiceBaseInternal): void {
        this.baseService = baseService;
    }

    public isFeatureEnabled(featureKey: FeatureFlag): boolean {
        return this.configEnabledFeatures?.some(f => f.key == substringAfterLast(featureKey, '_')) ||
            this.isFeatureEnabledByQuery(featureKey) ||
            this.baseService.isFeatureEnabled(featureKey);
    }

    public getAllEnabledFlags(): string[] {
        // TODO TEAM: getAllEnabledFlags() should support returning array of Feature objects, no matter what variant value a feature holds
        const configEnabledFeatures = this.configEnabledFeatures.filter(x => !x.multivariantValue).map(f => f.key.toLowerCase());
        const queryEnabledFlags = this.queryService.getQueryValuesForParam('features') ?? [];
        let enabledLdFlags: string[] = [];

        // try/catch is used to avoid breaking the app in case of ldClient not being defined in the base service
        // can be removed once fixed in common version
        try {
            // allFlags method can be replaced with base service one once it's properly implemented in later common version
            enabledLdFlags = this.getEnabledFeaturesFromLDFlagSet(this.baseService.allFlags());
        }
        catch {
            // Do nothing
        }

        const allEnabledFlags = [...enabledLdFlags, ...queryEnabledFlags, ...configEnabledFeatures];
        return uniq(allEnabledFlags);
    }

    public getFeatureValue<TValue>(feature: FeatureFlag, defaultValue: TValue): TValue {
        const configFeature = this.configEnabledFeatures.find(f => f.key === substringAfterLast(feature, '_'));

        if (configFeature) {
            return configFeature.multivariantValue as TValue;
        }

        return this.baseService.getFeatureValue(feature, defaultValue);
    }

    public isFeatureEnabledByQuery(featureKey: string): boolean {
        const queryEnabledFlags = this.queryService.getQueryValuesForParam('features');
        if (!queryEnabledFlags)
            return false;

        // If spdemo is enabled, enable only content features
        const enableAllFeatures = featureKey.includes('_ContentFeature_') && queryEnabledFlags.includes('spdemo');
        // If featureKey contains LaunchDarkly prefixes, remove them for query feature checks
        const modifiedFeatureKey = substringAfterLast(featureKey, '_');
        if (!modifiedFeatureKey)
            return false;

        return enableAllFeatures || queryEnabledFlags.includes(modifiedFeatureKey.toLowerCase());
    }

    private getEnabledFeaturesFromLDFlagSet(ldFlagSet: LDFlagSet): string[] {
        return Object.entries(ldFlagSet)
            .filter(([, enabled]) => typeof enabled === 'boolean' && enabled)
            .map(([key,]) => key.toLowerCase());
    }
}
