import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { MeasurementPlane, AxisDirection } from './measurements/measurements-helper';

export const measurementsBehindAlphaIndex = 1;
export const baseMaterialAlphaIndex = 2;
export const zonesAlphaIndex = 3;
export const measurementsFrontAlphaIndex = 4;

export interface MeasurementMetaData {
    planeNormalDirection: AxisDirection;
    normalValue: number;
}

/**
 * Gets the meta data created purposes of calculating alpha index of measurements
 * @param plane Measurements 2D plane
 * @param planeOrigin Origin of measurements plane
 */
export function MeasurementMetaData(plane: MeasurementPlane, planeOrigin: Vector3) {
    return {
        'MeasurementMetaData': {
            planeNormalDirection: GetPlaneNormalDirection(plane, planeOrigin),
            normalValue: GetPlaneNormalValueOfOrigin3DVector(plane, planeOrigin)
        }
    };
}

/**
 * Gets the value of the given origin vector corresponding to planes normal vector axis
 * @param plane Measurements 2D plane
 * @param planeOrigin Origin of measurements plane
 */
function GetPlaneNormalValueOfOrigin3DVector(plane: MeasurementPlane, planeOrigin: Vector3) {
    switch(plane) {
        case MeasurementPlane.YX:
        case MeasurementPlane.XY:
            return planeOrigin.y;
        case MeasurementPlane.ZX:
        case MeasurementPlane.XZ:
            return planeOrigin.z;
        case MeasurementPlane.ZY:
        case MeasurementPlane.YZ:
            return planeOrigin.x;
    }
}

/**
 * Calculates the Direction of the 2D planes normal vector in 3D space
 * @param plane Measurements 2D plane
 * @param planeOrigin Origin of measurements plane
 */
function GetPlaneNormalDirection(plane: MeasurementPlane, planeOrigin: Vector3) {
    switch(plane) {
        case MeasurementPlane.YX:
        case MeasurementPlane.XY:
            return planeOrigin.y >= 0 ? AxisDirection.ZPositive : AxisDirection.ZNegative;
        case MeasurementPlane.ZX:
        case MeasurementPlane.XZ:
            return planeOrigin.z >= 0 ? AxisDirection.YPositive : AxisDirection.YNegative;
        case MeasurementPlane.ZY:
        case MeasurementPlane.YZ:
            return planeOrigin.x >= 0 ? AxisDirection.XPositive : AxisDirection.XNegative;
    }
}

/**
 * Gets the alpha index for measurement based on given parameters
 * @param cameraPosition Camera position
 * @param metadata measurement metadata
 */
export function GetMeasurementsAlphaIndex(cameraPosition: Vector3, metadata: MeasurementMetaData) : number {

    switch(metadata.planeNormalDirection) {
        case AxisDirection.XPositive:
            return cameraPosition.x > metadata.normalValue ? measurementsFrontAlphaIndex : measurementsBehindAlphaIndex;
        case AxisDirection.XNegative:
            return cameraPosition.x < metadata.normalValue ? measurementsFrontAlphaIndex : measurementsBehindAlphaIndex;
        case AxisDirection.YPositive:
            return cameraPosition.z > metadata.normalValue ? measurementsFrontAlphaIndex : measurementsBehindAlphaIndex;
        case AxisDirection.YNegative:
            return cameraPosition.z < metadata.normalValue ? measurementsFrontAlphaIndex : measurementsBehindAlphaIndex;
        case AxisDirection.ZPositive:
            return cameraPosition.y > metadata.normalValue ? measurementsFrontAlphaIndex : measurementsBehindAlphaIndex;
        case AxisDirection.ZNegative:
            return cameraPosition.y < metadata.normalValue ? measurementsFrontAlphaIndex : measurementsBehindAlphaIndex;
    }
}