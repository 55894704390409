<pe-modal-header
    id="export-report-dialog"
    [modalInstance]="modalInstance"
    [title]="'Agito.Hilti.Profis3.ExportReport.Title' | l10n: localizationService.selectedLanguage"
    [closeButtonDisabled]="submitted"
    [closeButtonId]="'export-report-close-button'">
</pe-modal-header>

<form class="form" #ngForm="ngForm" (ngSubmit)="save()" [ngClass]="{ 'submitted': 'submitted' }" novalidate>

    <pe-modal-body *ngIf="isLoaded">
        <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.summary }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.Summary"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.summary = !isSectionCollapsed.summary"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-sp-sprite" [ngClass]="getArrowClass(isSectionCollapsed.summary)"></span>
            </button>
        </p>

        <!-- Summary section -->
        <app-export-report-summary-strength *ngIf="designDetails.designTypeId == strengthDesignTypeId"
            [collapsed]="isSectionCollapsed.summary"
            [designDetails]="$any(designDetails)"
            [modelImage]="modelImage">
        </app-export-report-summary-strength>
        <app-export-report-summary-punch *ngIf="designDetails.designTypeId == punchDesignTypeId"
            [collapsed]="isSectionCollapsed.summary"
            [designDetails]="$any(designDetails)"
            [modelImage]="modelImage">
        </app-export-report-summary-punch>

        <!-- Project detail section -->
        <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.projectDetails }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.ProjectDetails"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.projectDetails = !isSectionCollapsed.projectDetails"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-sp-sprite" [ngClass]="getArrowClass(isSectionCollapsed.projectDetails)"></span>
            </button>
        </p>
        <div class="project-details" [hidden]="isSectionCollapsed.projectDetails" id="export-report-details-section">
            <pe-export-report-project-details
                [designName]="exportReportProjectDetailsInputs.designName"
                [fasteningPoint]="exportReportProjectDetailsInputs.fasteningPoint"
                (fasteningPointChange)="exportReportProjectDetailsInputs.fasteningPoint = $any($event).detail"
                [fasteningPointTitle]="exportReportProjectDetailsInputs.fasteningPointTitle"
                [fasteningPointId]="exportReportProjectDetailsInputs.fasteningPointId"
                [notes]="exportReportProjectDetailsInputs.notes"
                (notesChange)="exportReportProjectDetailsInputs.notes = $any($event).detail"
                [includeDetailsInReport]="exportReportProjectDetailsInputs.includeDetailsInReport"
                [isIncludeDetailsInReport]="exportReportProjectDetailsInputs.isIncludeDetailsInReport"
                [reportDisabled]="exportReportProjectDetailsInputs.reportDisabled"
                [submitted]="submitted">
            </pe-export-report-project-details>
        </div>

        <!-- TODO - It might come back after production with https://hilti.atlassian.net/browse/PSP-171 -->
        <!-- Comments and notes section -->
        <!-- <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.commentAndNotes }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.CommentAndNotes"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.commentAndNotes = !isSectionCollapsed.commentAndNotes"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-sp-sprite" [ngClass]="getArrowClass(isSectionCollapsed.commentAndNotes)"></span>
            </button>
        </p>
        <div class="section comment-and-notes" [hidden]="isSectionCollapsed.commentAndNotes"
            id="export-report-customcomments-section">
            <div class="control-row">
                <p class="control-label" l10n="Agito.Hilti.Profis3.ReportTemplates.Note.Description"></p>
            </div>
            <div class="control-row">
                <pe-text-area
                    [id]="'report-templates-note-load-textarea'"
                    [title]="'Agito.Hilti.Profis3.ReportTemplates.NoteLoads' | l10n: localizationService.selectedLanguage"
                    [value]="noteLoads"
                    (valueChange)="noteLoads = $any($event).detail"
                    [disabled]="submitted || reportDisabled"
                    [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.Note.Placeholder'| l10n: localizationService.selectedLanguage"
                    [marginBottom]="'None'">
                </pe-text-area>
            </div>
            <div class="control-row">
                <pe-text-area
                    [id]="'report-templates-note-installation-data-textarea'"
                    [title]="'Agito.Hilti.Profis3.ReportTemplates.NoteInstallationData' | l10n: localizationService.selectedLanguage"
                    [value]="noteInstallationData"
                    (valueChange)="noteInstallationData = $any($event).detail"
                    [disabled]="submitted || reportDisabled"
                    [placeholder]="'Agito.Hilti.Profis3.ReportTemplates.Note.Placeholder'| l10n: localizationService.selectedLanguage"
                    [marginBottom]="'None'">
                </pe-text-area>
            </div>
        </div> -->

        <!-- TODO - It might come back after production with https://hilti.atlassian.net/browse/PSP-258 -->
        <!-- Custom pictures section -->
        <!-- <p class="title-strike title" id="export-report-custompictures-title-arrow"
            [ngClass]="{ 'no-margin': isSectionCollapsed.customPicturesAvailable , 'disabled-section': disableCustomImages }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.Pictures"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.customPicturesAvailable = !isSectionCollapsed.customPicturesAvailable"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-sp-sprite" [ngClass]="getArrowClass(isSectionCollapsed.customPicturesAvailable)"></span>
            </button>
        </p>
        <div class="section-pictures" id="export-report-custompictures-section"
            [ngClass]="{ 'hidden': isSectionCollapsed.customPicturesAvailable, 'disabled-section': disableCustomImages }"
            [hidden]="isSectionCollapsed.customPicturesAvailable">
            <div class="main-content-container" *ngFor="let i of [1,2,3]">
                <div class="row-control">
                    <p class="control-label" l10n="Agito.Hilti.Profis3.ExportReport.Picture"></p>

                    <div class="picture-container">
                        <div class="picture-image">
                            <div class="picture-image-img-wrapper">
                                <div id="export-report-custompictures-section-picture{{i}}-remove-button"
                                    class="picture-image-btn-remove pe-ui-sp-sprite pe-ui-sp-sprite-x"
                                    *ngIf="customPictures.get(i) && customPictures.get(i)?.imgUrl !== ''"
                                    (click)="removeCustomPicture(i)"
                                    [class.disabled]="submitted"
                                    [ngbTooltip]="'Agito.Hilti.Profis3.ExportReport.RemovePictureTooltip' | l10n: localizationService.selectedLanguage">
                                </div>

                                <img id="export-report-custompictures-section-picture{{i}}"
                                    *ngIf="customPictures.get(i) && customPictures.get(i)?.imgUrl !== ''"
                                    [src]="customPictures.get(i) != undefined ? (customPictures.get(i)?.imgUrl ?? '') : ''"
                                    alt="export report custom pictures" />
                            </div>
                        </div>
                        <div class="picture-upload">
                            <div class="tooltip-wrapper">
                                <pe-button
                                    id="export-report-custompictures-picture{{i}}-upload-button"
                                    class=""
                                    [look]="'Ghost'"
                                    (buttonClick)="selectCustomPicture(i)"
                                    [text]="'Agito.Hilti.Profis3.ReportTemplates.UploadLogo' | l10n: localizationService.selectedLanguage"
                                    [width]="'176px'">
                                </pe-button>
                            </div>

                            <input id="export-report-custompictures-picture{{i}}-upload-input" #customPicturesInput
                                class="picture-upload-input" (change)="customPictureSelected(i)"
                                name="report-templates-picture{{i}}-upload-input" type="file" accept=".jpg,.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="picture-upload-description" [hidden]="isSectionCollapsed.customPicturesAvailable">
            <div class="picture-upload-description" l10n="Agito.Hilti.Profis3.ExportReport.UploadPictureDescription">
            </div>
        </div> -->

        <!-- Layout section -->
        <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.layout }">
            <span l10n="Agito.Hilti.Profis3.ExportReport.Layout"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.layout = !isSectionCollapsed.layout"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-sp-sprite" [ngClass]="getArrowClass(isSectionCollapsed.layout)"></span>
            </button>
        </p>
        <div class="company-layout" [hidden]="isSectionCollapsed.layout" id="export-report-layout-section">
            <pe-export-report-company-layout
                [templateDropdown]="exportReportCompanyLayoutInputs.templateDropdown"
                [reportTemplate]="exportReportCompanyLayoutInputs.reportTemplate"
                [reportCompanyName]="exportReportCompanyLayoutInputs.reportCompanyName"
                (reportCompanyNameChange)="exportReportCompanyLayoutInputs.reportCompanyName = $any($event).detail"
                [reportAddress]="exportReportCompanyLayoutInputs.reportAddress"
                (reportAddressChange)="exportReportCompanyLayoutInputs.reportAddress = $any($event).detail"
                [reportContactPerson]="exportReportCompanyLayoutInputs.reportContactPerson"
                (reportContactPersonChange)="exportReportCompanyLayoutInputs.reportContactPerson = $any($event).detail"
                [reportPhoneNumber]="exportReportCompanyLayoutInputs.reportPhoneNumber"
                (reportPhoneNumberChange)="exportReportCompanyLayoutInputs.reportPhoneNumber = $any($event).detail"
                [reportEmail]="exportReportCompanyLayoutInputs.reportEmail"
                (reportEmailChange)="exportReportCompanyLayoutInputs.reportEmail = $any($event).detail"
                (reportEmailValid)="exportReportCompanyLayoutInputs.reportEmailValid = $any($event).detail"
                [reportTypeDropdown]="exportReportCompanyLayoutInputs.reportTypeDropdown"
                [languageDropdown]="exportReportCompanyLayoutInputs.languageDropdown"
                [paperSizeDropdown]="exportReportCompanyLayoutInputs.paperSizeDropdown"
                [firstPageNumber]="exportReportCompanyLayoutInputs.firstPageNumber"
                (firstPageNumberChange)="exportReportCompanyLayoutInputs.firstPageNumber = $any($event).detail"
                [loadCombinationDropdown]="exportReportCompanyLayoutInputs.loadCombinationDropdown"
                [loadCaseDropdown]="exportReportCompanyLayoutInputs.loadCaseDropdown"
                [loadCaseHandrailDropdown]="exportReportCompanyLayoutInputs.loadCaseHandrailDropdown"
                [reportTemplateDisabled]="exportReportCompanyLayoutInputs.reportTemplateDisabled"
                [isLoadCombinationDropdownVisible]="exportReportCompanyLayoutInputs.isLoadCombinationDropdownVisible"
                [displayLoadCaseDropdown]="exportReportCompanyLayoutInputs.displayLoadCaseDropdown"
                [handrailSafetyDesign]="exportReportCompanyLayoutInputs.handrailSafetyDesign"
                [isExternalOnlineRussianUser]="exportReportCompanyLayoutInputs.isExternalOnlineRussianUser"
                [formatedReportType]="formattedReportType"
                [formatedPaperSize]="formattedPaperSize"
                [submitted]="submitted"
                (reportTypeChangeToggle)="onReportTypeChange($any($event).detail)">
            </pe-export-report-company-layout>
        </div>

         <!-- TODO - It might come back after production with https://hilti.atlassian.net/browse/PSP-170 -->
        <!-- <p class="title-strike title" [ngClass]="{ 'no-margin': isSectionCollapsed.trimbleConnect, 'disabled-section': disableTrimbleConnect}">
            <span l10n="Agito.Hilti.Profis3.ExportReport.TrimbleConnect.Title"></span>
            <button class="section-collapse" type="button"
                (click)="isSectionCollapsed.trimbleConnect = !isSectionCollapsed.trimbleConnect"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.ShowHide' | l10n: localizationService.selectedLanguage">
                <span class="pe-ui-sp-sprite" [ngClass]="getArrowClass(isSectionCollapsed.trimbleConnect)"></span>
            </button>
        </p>
        <div class="section trimble-connect" [ngClass]="{ 'enabled': exportTrimbleConnectInput.trimbleConnectChecked, 'disabled-section': disableTrimbleConnect }"
            [hidden]="isSectionCollapsed.trimbleConnect" id="export-report-trimbleconnect-section">
            <pe-export-trimble-connect
                [trimbleConnectChecked]="exportTrimbleConnectInput.trimbleConnectChecked"
                (trimbleConnectCheckedChange)="exportTrimbleConnectInput.trimbleConnectChecked = $any($event).detail"
                [trimbleConnectLocation]="exportTrimbleConnectInput.trimbleConnectLocation"
                (trimbleConnectLocationChange)="exportTrimbleConnectInput.trimbleConnectLocation = $any($event).detail"
                [trimbleConnectReportName]="exportTrimbleConnectInput.trimbleConnectReportName"
                (trimbleConnectReportNameChange)="exportTrimbleConnectInput.trimbleConnectReportName = $any($event).detail"
                [trimbleConnectFolderId]="exportTrimbleConnectInput.trimbleConnectFolderId"
                [trimbleConnectTooltip]="exportTrimbleConnectInput.trimbleConnectTooltip"
                [isOfflineOnLine]="exportTrimbleConnectInput.isOfflineOnLine"
                [submitted]="submitted"
                [reportDisabled]="true">
            </pe-export-trimble-connect>
        </div> -->
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'export-report-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [text]="'Agito.Hilti.Profis3.ExportReport.Cancel' | l10n: localizationService.selectedLanguage"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            *ngIf="exportHtmlReportEnabled"
            [id]="'export-report-html-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="exportHtmlReport()"
            [text]="'HTML'"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'export-report-save-button'"
            [look]="'Cta'"
            class="modal-button"
            type="submit"
            [disabled]="!formValid || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.ExportReport.SaveAndExport' | l10n: localizationService.selectedLanguage">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
