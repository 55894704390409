import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { CreatePolygon } from '@babylonjs/core/Meshes/Builders/polygonBuilder';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { FloatArray } from '@babylonjs/core/types';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import earcut from 'earcut';
import isEqual from 'lodash-es/isEqual';
import { ZoneNumber } from '../../services/data.service';
import { Text2D } from '../gl-model';
import { StrengthComponent, StrengthComponentConstructor } from '../strength-component';
import { BaseMaterialModel, BaseMaterialVisibilityModel, OpeningModel, PostInstalledElementModel, ZonesModel } from '../strength-gl-model';
import { baseMaterialAlphaIndex, zonesAlphaIndex } from './alpha-index-helper';
import { calculateHoleDiameter } from './opening';
import { CalculateElementPositions, numberOfSides } from './post-installed-element-helper';
import { GetStrengtheningElementDefinition, GetZoneDetailsList, GetZoneRotation, GetZoneZOffset, ZoneDetails, ZoneNumberHeightOffset, ZoneNumberingAlpha, ZoneVerticalPosition } from './zones-helper';

interface BaseMaterialModelUpdateData {
    visibilityInfos: BaseMaterialVisibilityModel;
    baseMaterial: BaseMaterialModel;
    zones: ZonesModel;
    opening: OpeningModel;
    postInstalledElement: PostInstalledElementModel;
}

export const baseMaterialColor = Color3.FromHexString('#cbcabf');
export const baseMaterialDarkColor = Color3.FromHexString('#a9a89a');

export class StrengthBaseMaterial extends StrengthComponent {
    private baseMaterialMaterial!: StandardMaterial;
    private baseMaterialMaterialDark!: StandardMaterial;

    private zoneMeshesTop: Mesh[];
    private zoneMeshesBottom: Mesh[];
    private recessCylinders: Mesh[];
    private recessHoleBottoms: Mesh[];

    private zoneNumberingTextTop: Text2D[];
    private zoneNumberingTextBottom: Text2D[];

    private baseMaterialModelUpdateData: BaseMaterialModelUpdateData;

    constructor(ctor: StrengthComponentConstructor) {
        super(ctor);
        this.zoneMeshesTop = new Array<Mesh>(4);
        this.zoneMeshesBottom = new Array<Mesh>(4);
        this.zoneNumberingTextTop = new Array<Text2D>();
        this.zoneNumberingTextBottom = new Array<Text2D>();
        this.recessCylinders = new Array<Mesh>();
        this.recessHoleBottoms = new Array<Mesh>();

        this.baseMaterialModelUpdateData = {} as BaseMaterialModelUpdateData;

        this.initMaterial();
    }

    public update(): void {
        // Only update if the relevant values have actually changed
        const modelUpdateData = {
            visibilityInfos: this.getVisibilityInfos(),
            baseMaterial: this.model.baseMaterial,
            zones: this.model.zones,
            opening: this.model.opening,
            postInstalledElement: this.model.postInstalledElement,
        } as BaseMaterialModelUpdateData;
        if (isEqual(this.baseMaterialModelUpdateData, modelUpdateData)) {
            return;
        }
        this.baseMaterialModelUpdateData = modelUpdateData;

        // Ensure the meshes are created
        this.ensureBaseMaterialSides();
        this.ensureZonesMeshes();
    }

    private getVisibilityInfos(): BaseMaterialVisibilityModel {
        return {
            ZonesVisible: this.model.visibilityModel.ZonesVisible,
            ZonesNumberingVisible: this.model.visibilityModel.ZonesNumberingVisible,
            TransparentConcrete: this.model.visibilityModel.TransparentConcrete,
        };
    }

    private ensureZonesMeshes() {
        this.zoneMeshesTop.forEach(x => x?.dispose());
        this.zoneMeshesBottom.forEach(x => x?.dispose());
        this.recessCylinders.forEach(x => x?.dispose());
        this.recessHoleBottoms.forEach(x => x?.dispose());

        this.zoneNumberingTextTop.forEach(x => x?.setEnabled(false));
        this.zoneNumberingTextBottom.forEach(x => x?.setEnabled(false));

        const zoneDetailsList = GetZoneDetailsList(this.model);
        for (const zoneDetails of zoneDetailsList) {
            this.zoneMeshesTop[zoneDetails.index - 1] = this.createZoneMesh(zoneDetails, ZoneVerticalPosition.Top);
            this.zoneMeshesBottom[zoneDetails.index - 1] = this.createZoneMesh(zoneDetails, ZoneVerticalPosition.Bottom);

            if (this.model.visibilityModel.ZonesNumberingVisible) {
                if (!this.model.visibilityModel.TransparentConcrete) {
                    this.sizeAnchorNumberingText(this.zoneNumberingTextTop, zoneDetails, this.model.baseMaterial.height / 2 + ZoneNumberHeightOffset);
                }
                this.sizeAnchorNumberingText(this.zoneNumberingTextBottom, zoneDetails, - this.model.baseMaterial.height / 2 - ZoneNumberHeightOffset);
            }
        }

        const diameter = calculateHoleDiameter(this.model.postInstalledElement.anchorDiameter);
        for (let i = 0; i < this.recessCylinders.length; i++) {
            this.recessCylinders[i].scaling.x = diameter;
            this.recessCylinders[i].scaling.z = diameter;

            this.recessHoleBottoms[i].scaling.x = diameter;
            this.recessHoleBottoms[i].scaling.z = diameter;
        }
    }

    private createZoneMesh(zoneDetails: ZoneDetails, position: ZoneVerticalPosition) {
        const name = zoneDetails.name + ZoneVerticalPosition[position];

        const shape: Vector3[] = [];
        const holes: Vector3[][] = [];

        shape.push(new Vector3(zoneDetails.zoneDimensions.x - zoneDetails.zoneDimensions.length / 2, 200, zoneDetails.zoneDimensions.y - zoneDetails.zoneDimensions.width / 2));
        shape.push(new Vector3(zoneDetails.zoneDimensions.x - zoneDetails.zoneDimensions.length / 2, 200, zoneDetails.zoneDimensions.y + zoneDetails.zoneDimensions.width / 2));
        shape.push(new Vector3(zoneDetails.zoneDimensions.x + zoneDetails.zoneDimensions.length / 2, 200, zoneDetails.zoneDimensions.y + zoneDetails.zoneDimensions.width / 2));
        shape.push(new Vector3(zoneDetails.zoneDimensions.x + zoneDetails.zoneDimensions.length / 2, 200, zoneDetails.zoneDimensions.y - zoneDetails.zoneDimensions.width / 2));

        const diameter = calculateHoleDiameter(this.model.postInstalledElement.anchorDiameter);

        // Recess
        if (this.model.postInstalledElement.depthOfRecess > 0 && GetStrengtheningElementDefinition(this.model, zoneDetails.index) && position == ZoneVerticalPosition.Bottom) {
            const positions = CalculateElementPositions(this.model, zoneDetails.index, 30);
            for (let i = 0; i < positions.length; i++) {
                const holeShape = this.getCircleShape(new Vector3(positions[i].x, 200, positions[i].z), diameter);
                holes.push(holeShape);

                const holeShapeBottom = this.getCircleShape(Vector3.Zero(), 1);

                const cylinder = CreateCylinder('RecessCylinder' + i, { tessellation: numberOfSides, diameter: 1, height: this.model.postInstalledElement.depthOfRecess, cap: Mesh.NO_CAP, sideOrientation: Mesh.BACKSIDE }, this.scene);
                cylinder.position.x = positions[i].x;
                cylinder.position.y = - this.model.baseMaterial.height / 2 + this.model.postInstalledElement.depthOfRecess / 2;
                cylinder.position.z = positions[i].z;
                cylinder.material = this.baseMaterialMaterial;
                cylinder.setEnabled(true);
                cylinder.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
                this.recessCylinders.push(cylinder);

                const holeBottomMesh = CreatePolygon('RecessBottom' + i, { shape: holeShapeBottom, sideOrientation: Mesh.BACKSIDE }, this.scene, earcut);
                holeBottomMesh.position.x = positions[i].x;
                holeBottomMesh.position.y = - this.model.baseMaterial.height / 2 + this.model.postInstalledElement.depthOfRecess;
                holeBottomMesh.position.z = positions[i].z;
                holeBottomMesh.material = this.baseMaterialMaterialDark;
                holeBottomMesh.setEnabled(true);
                holeBottomMesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
                this.recessHoleBottoms.push(holeBottomMesh);
            }
        }

        const mesh = CreatePolygon(name, { shape: shape, holes: holes }, this.scene, earcut);

        const materialName = zoneDetails.name + 'Material';
        const material = this.cache.materialCache.create(materialName, () => new StandardMaterial(materialName, this.scene));

        material.diffuseColor = this.model.visibilityModel.TransparentConcrete ? zoneDetails.colorTransparent : zoneDetails.color;
        material.alpha = this.model.visibilityModel.TransparentConcrete ? 0.75 : 1;

        mesh.setEnabled(true);
        mesh.material = this.model.visibilityModel.ZonesVisible ? material : this.baseMaterialMaterial;

        this.centerMeshPivot(mesh);
        mesh.rotation.set(GetZoneRotation(position), 0, 0);
        mesh.position.y = GetZoneZOffset(this.model, position);
        mesh.alphaIndex = zonesAlphaIndex;

        // rotation
        mesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);

        const tooltipText = this.generateTooltipText(zoneDetails.index, zoneDetails.load);
        mesh.onPickingInfosChanged = this.onZonePickingInfosChanged(mesh, tooltipText, zoneDetails.name, position);

        return mesh;
    }

    private centerMeshPivot(mesh: Mesh) {
        const boundingInfo = mesh.getBoundingInfo();
        const boundingBox = boundingInfo.boundingBox;
        const center = boundingBox.centerWorld;
        mesh.setPivotPoint(center);
    }

    private getCircleShape(position: Vector3, size: number): Vector3[] {
        const circlePoints: Vector3[] = [];
        this.cache.commonCache.getCirclePoints(numberOfSides, 1).forEach((point) => {
            circlePoints.push(new Vector3(position.x + point.x * size, position.y, position.z + point.y * size));
        });
        return circlePoints;
    }

    private ensureBaseMaterialSides() {
        const mesh = this.cache.meshCache.create('BaseMaterialSides', () => this.createSidesMesh());
        mesh.setEnabled(true);

        // rotation mesh
        mesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);

        this.setBaseMaterialMeshProperties(mesh);
    }

    private setBaseMaterialMeshProperties(mesh: Mesh) {
        this.baseMaterialMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;
        mesh.material = this.baseMaterialMaterial;

        mesh.alphaIndex = baseMaterialAlphaIndex;

        mesh.scaling.x = this.model.baseMaterial.length;
        mesh.scaling.y = this.model.baseMaterial.height;
        mesh.scaling.z = this.model.baseMaterial.width;

        mesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
    }

    private createSidesMesh() {
        const positions = [
            -0.5, -0.5, -0.5,
            0.5, -0.5, -0.5,
            0.5, 0.5, -0.5,

            -0.5, -0.5, -0.5,
            0.5, 0.5, -0.5,
            -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5,
            -0.5, -0.5, -0.5,
            -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5,
            -0.5, 0.5, -0.5,
            -0.5, 0.5, 0.5,

            -0.5, -0.5, 0.5,
            0.5, 0.5, 0.5,
            0.5, -0.5, 0.5,

            -0.5, -0.5, 0.5,
            -0.5, 0.5, 0.5,
            0.5, 0.5, 0.5,

            0.5, -0.5, 0.5,
            0.5, 0.5, -0.5,
            0.5, -0.5, -0.5,

            0.5, -0.5, 0.5,
            0.5, 0.5, 0.5,
            0.5, 0.5, -0.5,
        ];
        const indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

        return this.createMesh(positions, indices, 'BaseMaterialSides');
    }

    private createMesh(positions: number[], indices: number[], name: string) {
        const mesh = new Mesh(name, this.scene);
        const normals: FloatArray = [];
        const vertexData = new VertexData();

        VertexData.ComputeNormals(positions, indices, normals);

        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals;
        vertexData.applyToMesh(mesh);

        return mesh;
    }

    private initMaterial() {
        this.baseMaterialMaterial = this.cache.materialCache.create('BaseMaterialMaterial', () => new StandardMaterial('BaseMaterialMaterial', this.scene));
        this.baseMaterialMaterial.diffuseColor = baseMaterialColor;

        this.baseMaterialMaterialDark = this.cache.materialCache.create('BaseMaterialMaterialDark', () => new StandardMaterial('BaseMaterialMaterialDark', this.scene));
        this.baseMaterialMaterialDark.diffuseColor = baseMaterialDarkColor;
    }

    private onZonePickingInfosChanged(mesh: Mesh, tooltipText: string, name: string, position: ZoneVerticalPosition) {
        return (pickingInfos: PickingInfo[]) => {
            const { isHighlighted } = this.highlight(pickingInfos, name, (pickedMesh) => pickedMesh === mesh);
            const isVisible = (position == ZoneVerticalPosition.Top) ? this.camera.position.y > 0 : this.camera.position.y < 0;

            if (isVisible && this.model.visibilityModel.ZonesVisible) {
                if (isHighlighted) {
                    this.tooltip.showText(tooltipText);
                }
                else {
                    this.tooltip.hideText(tooltipText);
                }
            }
        };
    }

    private generateTooltipText(zoneNumber: ZoneNumber, loadValue: number) {
        const loadString = this.unitConverter.formatWithUnit(loadValue, UnitGroup.Force);
        return `<tspan>Z${zoneNumber}: </tspan>V<sub>Ed</sub><tspan> = ${loadString}</tspan>`;
    }

    private sizeAnchorNumberingText(zoneTextArray: Text2D[], zoneDetails: ZoneDetails, height: number) {
        let zoneText = zoneTextArray[zoneDetails.index];
        const zoneId = 'Z' + this.unitConverter.format(zoneDetails.index);

        if (zoneText == null) {
            zoneText = zoneTextArray[zoneDetails.index] = this.createText2D();
            zoneText.setText(zoneId);
            zoneText.setColor(Color3.Black());
        }

        zoneText.setText(zoneId);
        zoneText.setAlpha(ZoneNumberingAlpha);
        zoneText.mesh!.position.x = zoneDetails.zoneDimensions.x;
        zoneText.mesh!.position.y = height;
        zoneText.mesh!.position.z = zoneDetails.zoneDimensions.y;

        // rotation
        zoneText.mesh!.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
    }

    public override dispose(): void {
        this.zoneNumberingTextTop.forEach(textTop => textTop?.dispose());
        this.zoneNumberingTextBottom.forEach(textBottom => textBottom?.dispose());
        super.dispose();
    }
}
