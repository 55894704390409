export enum CollapsingControls {
    OverlayZone1Utilization = 'SP_OverlayZone1Utilization',
    OverlayZone2Utilization = 'SP_OverlayZone2Utilization',
    OverlayZone3Utilization = 'SP_OverlayZone3Utilization',
    OverlayZone4Utilization = 'SP_OverlayZone4Utilization',
    OverlayZone5Utilization = 'SP_OverlayZone5Utilization',
    OverlayZone6Utilization = 'SP_OverlayZone6Utilization',
    OverlayZone7Utilization = 'SP_OverlayZone7Utilization',
    OverlayZone8Utilization = 'SP_OverlayZone8Utilization',

    OverlayZone1ExistingMemberUtilization = 'SP_OverlayZone1ExistingMemberUtilization',
    OverlayZone2ExistingMemberUtilization = 'SP_OverlayZone2ExistingMemberUtilization',
    OverlayZone3ExistingMemberUtilization = 'SP_OverlayZone3ExistingMemberUtilization',
    OverlayZone4ExistingMemberUtilization = 'SP_OverlayZone4ExistingMemberUtilization',
    OverlayZone5ExistingMemberUtilization = 'SP_OverlayZone5ExistingMemberUtilization',
    OverlayZone6ExistingMemberUtilization = 'SP_OverlayZone6ExistingMemberUtilization',
    OverlayZone7ExistingMemberUtilization = 'SP_OverlayZone7ExistingMemberUtilization',
    OverlayZone8ExistingMemberUtilization = 'SP_OverlayZone8ExistingMemberUtilization',

    OverlayZone1StrengthenedMemberUtilization = 'SP_OverlayZone1StrengthenedMemberUtilization',
    OverlayZone2StrengthenedMemberUtilization = 'SP_OverlayZone2StrengthenedMemberUtilization',
    OverlayZone3StrengthenedMemberUtilization = 'SP_OverlayZone3StrengthenedMemberUtilization',
    OverlayZone4StrengthenedMemberUtilization = 'SP_OverlayZone4StrengthenedMemberUtilization',
    OverlayZone5StrengthenedMemberUtilization = 'SP_OverlayZone5StrengthenedMemberUtilization',
    OverlayZone6StrengthenedMemberUtilization = 'SP_OverlayZone6StrengthenedMemberUtilization',
    OverlayZone7StrengthenedMemberUtilization = 'SP_OverlayZone7StrengthenedMemberUtilization',
    OverlayZone8StrengthenedMemberUtilization = 'SP_OverlayZone8StrengthenedMemberUtilization',
}

export const enum ZoneName {
    Unknown = 0,
    Zone1 = 1,
    Zone2 = 2,
    Zone3 = 3,
    Zone4 = 4,
    Zone5 = 5,
    Zone6 = 6,
    Zone7 = 7,
    Zone8 = 8,
}
