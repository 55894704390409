<pe-modal-header [modalInstance]="modalInstance" [title]="getTitle()"></pe-modal-header>

<pe-modal-body>
    <div>

        <span l10n="SP.InstallationConditions.DrillingAid.InfoPopUp.PartOne"></span>
        <div class="info-dialog-image">
            <img class="image" [src]="drillingAidImage" alt="drilling-aid-image">
        </div>
        <span l10n="SP.InstallationConditions.DrillingAid.InfoPopUp.PartTwo"></span>
        <br />
        &bull;&nbsp;
        <span l10n="SP.InstallationConditions.DrillingAid.InfoPopUp.NoDrillAid"></span>
        <br />
        &bull;&nbsp;
        <span l10n="SP.InstallationConditions.DrillingAid.InfoPopUp.DrillAid"></span>

    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="info-dialog-ok-button" [look]="'Primary'" (buttonClick)="close()" [text]="translate('SP.General.OK')">
    </pe-button>
</pe-modal-footer>
