import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { CheckboxButtonItem } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { NotificationLocation, NotificationType, NotificationTypeImage, NotificationTypeMap, NotificationTypeName } from '@profis-engineering/pe-ui-common/entities/notifications';
import { CollapsingControls } from '../../collapsing-controls';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

export interface INotificationsComponentInput {
    isVisible: () => boolean;
    isInfoMessageVisible: () => boolean;
    notifications: INotification[];
    scopeChecks: INotificationScopeCheck[];
}

export interface INotificationScopeCheck {
    type : NotificationType;
    message: string;
    actionButtons: INotificationButton[];
    hasSupportButton?: () => boolean;
    supportButtonClick: () => void;
}

export interface INotification {
    condition: () => boolean;
    text: string;
    doesCount?: boolean;
    class?: string;
    button?: INotificationButton;
    checkbox?: INotificationCheckbox;
    additionalText?: string;
    additionalButton?: INotificationButton;
    notificationLocation: NotificationLocation;
}

export interface INotificationButton {
    condition: () => boolean;
    disabled: () => boolean;
    click: () => void;
    buttonTitle: string;
    tooltip?: string;
    disableTooltip?: () => boolean;
}

export interface INotificationCheckbox {
    condition: () => boolean;
    items: CheckboxButtonItem<boolean>[];
    value: boolean;
    valueChange: () => void;
}

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnChanges {
    @Input()
    public inputs!: INotificationsComponentInput;

    @Input()
    public isVisible = false;

    public collapsed = false;

    public NotificationType = NotificationTypeMap;
    public NotificationTypeImage = NotificationTypeImage;
    public NotificationTypeName = NotificationTypeName;

    private localInputs!: INotificationsComponentInput;

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    public get beforeSCNotifications() {
        if (this.localInputs.isVisible() || this.localInputs.notifications) {
            return this.localInputs.notifications.filter((item) => item.notificationLocation == NotificationLocation.Start && item.condition());
        }
        else {
            return [];
        }
    }

    public get betweenSCNotifications() {
        if (this.localInputs.isVisible() || this.localInputs.notifications) {
            return this.localInputs.notifications.filter((item) => item.notificationLocation == NotificationLocation.Middle && item.condition());
        }
        else {
            return [];
        }
    }

    public get afterSCNotifications() {
        if (this.localInputs.isVisible() || this.localInputs.notifications) {
            return this.localInputs.notifications.filter((item) => item.notificationLocation == NotificationLocation.End && item.condition());
        }
        else {
            return [];
        }
    }

    public get hasAlertScopeChecks() {
        return this.alertScopeChecks.length > 0;
    }

    public get alertScopeChecks() {
        if (this.localInputs.isVisible() && this.localInputs.scopeChecks) {
            return this.localInputs.scopeChecks.filter((sc) => sc.type == NotificationType.alert);
        }
        else {
            return [];
        }
    }

    public get hasInfoMessageVisible()
    {
        return this.localInputs.isInfoMessageVisible();
    }

    public get hasInfoScopeChecks() {
        return this.infoScopeChecks.length > 0;
    }

    public get infoScopeChecks() {
        if (this.localInputs.isVisible() && this.localInputs.scopeChecks) {
            return this.localInputs.scopeChecks.filter((sc) => sc.type == NotificationType.info);
        }
        else {
            return [];
        }
    }

    public ngOnInit() {
        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.NotificationsDesign);
    }

    public ngOnChanges(): void {
        this.localInputs = this.inputs;
    }

    public getNotificationCount(type: NotificationType) {
        let scCount = this.localInputs.scopeChecks.filter((item) => item.type == type).length;

        if (type == NotificationType.info) {
            scCount += this.localInputs.notifications.filter((item) => item.doesCount && item.condition()).length;
        }

        return scCount;
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.NotificationsDesign, collapsed);
    }
}

