import {
    Tooltip
} from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { UnitType as Unit, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { LoadType } from './services/design.service';
import { LocalizationService } from './services/localization.service';
import { UnitService } from './services/unit.service';

export interface LoadCombinationColumnProps<T> {
    name: T;
    utilization: T;
    zone1: T;
    zone2: T;
    zone3: T;
    zone4: T;
    zone5: T;
    zone6: T;
    zone7: T;
    zone8: T;
}

export class LoadsComponentHelper {
    private static readonly typeButtonWidth: number = 32; // sync with css variable @type-button-width

    public staticTooltip!: Tooltip;
    public headerFlexValue = 0.7;
    public numberOfZones = 4;
    public unitForce!: UnitType;

    constructor(
        private localization: LocalizationService,
        private unit: UnitService
    ) {
        this.staticTooltip = {
            title: this.translate('SP.LoadType.Static.Tooltip.Title'),
            content: this.translate('SP.LoadType.Static.Tooltip')
        };
    }

    public get columnVisibilities(): LoadCombinationColumnProps<boolean> {
        return {
            name: true,
            utilization: true,
            zone1: this.isZoneVisible(1),
            zone2: this.isZoneVisible(2),
            zone3: this.isZoneVisible(3),
            zone4: this.isZoneVisible(4),
            zone5: this.isZoneVisible(5),
            zone6: this.isZoneVisible(6),
            zone7: this.isZoneVisible(7),
            zone8: this.isZoneVisible(8),
        };
    }

    public get columnTypeWidth() {
        return (LoadsComponentHelper.typeButtonWidth + 2); // +2 (both borders)
    }

    public get zoneOneFlex() {
        if (this.numberOfZones == 1) {
            return { flex: 2 * this.headerFlexValue };
        }
        else {
            return { flex: this.headerFlexValue };
        }
    }

    public isZoneVisible(zoneNumber: number) {
        return this.numberOfZones >= zoneNumber;
    }

    public getSizeFlexOrNull(size: number | undefined): string | null {
        return size != null ? size + 'px' : null;
    }

    public getPrecision(unit: Unit) {
        this.unit.getPrecision(unit);
    }

    public formatPercentage(valueStr?: string): string {
        if (valueStr == null)
            return '';

        const value = +valueStr;
        switch (value) {
            case undefined:
            case null:
                return '';
            case Number.NEGATIVE_INFINITY:
                return '-∞ %';
            case Number.POSITIVE_INFINITY:
                return '∞ %';
            default:
                return this.unit.formatNumber(value, 2) + '%';
        }
    }

    public loadLoadTypeToggleItems(loadIndex: number, loadImages: Map<LoadType, IIconStyle>) {
        const loadTypeToggleItems: ToggleButtonGroupItem<LoadType>[] = [];

        const item: ToggleButtonGroupItem<LoadType> = {
            id: '',
            value: LoadType.Static,
            image: loadImages.has(LoadType.Static) ? loadImages.get(LoadType.Static) : {},
            class: 'sprite-anchor-shock',
            tooltip: this.staticTooltip
        };
        this.setLoadTypeToggleItemId(item, loadIndex);
        loadTypeToggleItems.push(item);

        return loadTypeToggleItems;
    }

    public setLoadTypeToggleItemId(item: ToggleButtonGroupItem<LoadType>, loadIndex: number) {
        switch (item.value) {
            case LoadType.Static:
                item.id = `${loadIndex != null ? 'LC-table-static-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-static-loadtype-button'}`;
                break;
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }
}
