import { Injectable } from '@angular/core';
import { IUnitProviderObject } from '@profis-engineering/pe-ui-common/entities/design';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitPrecision, UnitServiceBase, UnitValue } from '@profis-engineering/pe-ui-common/services/unit.common';
import { DesignTypeId, designTypesById, PropertyId } from './design.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UnitService {
    private baseService!: UnitServiceBase;

    constructor(
        private userService: UserService
    ) {
    }

    public setBaseService(baseService: UnitServiceBase): void {
        const userService = this.userService;

        // pe-ui is still not modularized
        // here we are changing the base service that is used by everyone so we have to be careful
        const baseGetPrecision = baseService.getPrecision;
        baseService.getPrecision = function(unit: UnitType, uiProperty?: string, unitPrecision?: UnitPrecision) {
            // only for our designs
            if (userService.design?.designTypeId != null && designTypesById[userService.design.designTypeId as DesignTypeId] != null) {
                const customPrecision = userService.design.designDetails?.propertyDetails[uiProperty as PropertyId]?.precision;
                if (customPrecision != null) {
                    return customPrecision;
                }

                const extraPrecision = userService.design.designDetails?.propertyDetails[uiProperty as PropertyId]?.extraPrecision;
                if (extraPrecision != null) {
                    return baseGetPrecision.apply(this, [unit, uiProperty, unitPrecision]) + extraPrecision;
                }
            }

            return baseGetPrecision.apply(this, [unit, uiProperty, unitPrecision]);
        };

        this.baseService = baseService;
    }

    public getPrecision(unit: UnitType, uiProperty?: string, unitPrecision?: UnitPrecision): number {
        return this.baseService.getPrecision(unit, uiProperty, unitPrecision);
    }

    public formatUnit(unit: UnitType): string {
        return this.baseService.formatUnit(unit);
    }

    public formatUnitValue(value: UnitValue, precision?: number, decimalSeparator?: string, groupSeparator?: string, uiProperty?: string, toFixed?: boolean): string {
        return this.baseService.formatUnitValue(value, precision, decimalSeparator, groupSeparator, uiProperty, toFixed);
    }

    public formatNumber(value: number, decimals?: number, toFixed?: boolean | null, decimalSeparator?: string, groupSeparator?: string): string {
        return this.baseService.formatNumber(value, decimals, toFixed, decimalSeparator, groupSeparator);
    }

    public parseUnitValue(value: string, unitGroup: UnitGroup, defaultUnit?: UnitType, decimalSeparator?: string, groupSeparator?: string): UnitValue {
        return this.baseService.parseUnitValue(value, unitGroup, defaultUnit, decimalSeparator, groupSeparator);
    }

    public formatUnitValueArgs(value: number, unit?: UnitType, precision?: number, decimalSeparator?: string, groupSeparator?: string, uiProperty?: string, appendUnit?: boolean, toFixed?: boolean): string {
        return this.baseService.formatUnitValueArgs(value, unit, precision, decimalSeparator, groupSeparator, uiProperty, appendUnit, toFixed);
    }

    public formatUnitAsDefault(value: string, unitGroup: UnitGroup): string {
        return this.baseService.formatUnitAsDefault(value, unitGroup);
    }

    public convertUnitValueArgsToUnit(value: number, unitFrom: UnitType, unitTo: UnitType, useDefaulPrecision?: boolean): number {
        return this.baseService.convertUnitValueArgsToUnit(value, unitFrom, unitTo, useDefaulPrecision);
    }

    public getDefaultPrecision(): number {
        return this.baseService.getDefaultPrecision();
    }

    public convertInternalValueToDefaultUnitValue(value: number, unitGroup: UnitGroup): UnitValue {
        return this.baseService.convertInternalValueToDefaultUnitValue(value, unitGroup);
    }

    public convertUnitValueToInternalUnitValue(unitValue: UnitValue) : UnitValue | undefined {
        return this.baseService.convertUnitValueToInternalUnitValue(unitValue);
    }

    public getInternalUnit(unitGroup: UnitGroup): UnitType {
        return this.baseService.getInternalUnit(unitGroup);
    }

    public getDefaultUnit(unitGroup: UnitGroup, design?: IUnitProviderObject): UnitType {
        return this.baseService.getDefaultUnit(unitGroup, design);
    }

    public parseUnknownUnitValue(value: string, decimalSeparator?: string, groupSeparator?: string): UnitValue {
        return this.baseService.parseUnknownUnitValue(value, decimalSeparator, groupSeparator);
    }

    public getUnitStrings(unit: UnitType): string[] {
        return this.baseService.getUnitStrings(unit);
    }

    public incDecValueByUnit(unit: UnitType, stepValue?: number): number {
        return this.baseService.incDecValueByUnit(unit, stepValue);
    }

    public convertUnitValueToUnit(unitValue: UnitValue, unit: UnitType): UnitValue {
        return this.baseService.convertUnitValueToUnit(unitValue, unit);
    }

    public parseNumber(value: string, decimalSeparator?: string, groupSeparator?: string): number {
        return this.baseService.parseNumber(value, decimalSeparator, groupSeparator);
    }

    public appendPrecisionLossSymbolToValueString(value: number, valueString: string) : string {
        return this.baseService.appendPrecisionLossSymbolToValueString(value, valueString);
    }

    public appendUnitToValueString(value: number, valueString: string, unit?: UnitType) : string {
        return this.baseService.appendUnitToValueString(value, valueString, unit);
    }
}
