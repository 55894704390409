import { ButtonSize, NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { getSpriteAsIconStyle } from '../../sprites';
import { StrengthRegion, StrengthTab } from './static-menu.strength.definitions';

export function createSPMenu_BaseMaterial(): StrengthTab {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial',
        Id: 2,
        Name: 'base-material-tab',
        Image: 'tab-base-material',
        IconImage: getSpriteAsIconStyle('sprite-tab-base-material'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_BaseMaterial_BaseMaterial(),
            createSPMenu_BaseMaterial_Temperature(),
            createSPMenu_BaseMaterial_Geometry(),
            createSPMenu_BaseMaterial_Zones(),
            createSPMenu_BaseMaterial_Openings()
        ]
    };
}

function createSPMenu_BaseMaterial_BaseMaterial(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial',
        Id: 20,
        Name: 'basematerial-section',
        Controls: [
            {
                CodelistName: 'baseMaterial',
                ControlType: 'Dropdown',
                Name: 'basematerial-basematerial-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.BaseMaterial',
                UIPropertyId: 'baseMaterialId'
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccyl',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCyl',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'fcCylinder'
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccube',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCube',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'fcCube'
            },
            {
                CodelistName: 'aggregateSizes',
                ControlType: 'Dropdown',
                Name: 'basematerial-aggregatesize-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.AggregateSize.Sia',
                UIPropertyId: 'aggregateSizeId'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.UsePlasticStrain',
                Name: 'basematerial-useplasticstrain',
                UIPropertyId: 'plasticStrain',
                TooltipDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.UsePlasticStrain.Tooltip',
                TooltipTitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.UsePlasticStrain.Tooltip.Title'
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-epsilonv',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.EpsilonV',
                UnitGroup: UnitGroup.None,
                UIPropertyId: 'epsilonV',
            }
        ]
    };
}

function createSPMenu_BaseMaterial_Temperature(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature',
        Id: 21,
        Name: 'temperature-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'temperature-shortterm',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.ShortTerm',
                UnitGroup: UnitGroup.Temperature,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.ShortTerm',
                UIPropertyId: 'shortTermTemperature'
            },
            {
                ControlType: 'TextBox',
                Name: 'temperature-longterm',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.LongTerm',
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.LongTerm',
                UnitGroup: UnitGroup.Temperature,
                UIPropertyId: 'longTermTemperature'
            },
        ]
    };
}

export function createSPMenu_BaseMaterial_Geometry(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry',
        Name: 'geometry-section',
        Id: 22,
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'concrete-length',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Length',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Length',
                UIPropertyId: 'slabLength'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'concrete-width',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Width',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Width',
                UIPropertyId: 'slabWidth'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'concrete-height',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Height',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Height',
                UIPropertyId: 'slabHeight'
            },
        ]
    };
}

function createSPMenu_BaseMaterial_Zones(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionZones',
        Id: 23,
        Name: 'zones-section',
        Controls: [
            {
                ControlType: 'Dropdown',
                Name: 'numberofzones-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionZones.NumberOfZones',
                CodelistName: 'zonesNumber',
                UIPropertyId: 'zonesNumberId'
            },
            {
                ControlType: 'Group',
                Name: 'zonewidth',
                MultilineGroup: true,
                CollapsableGroup: true
            },
            {
                ControlType: 'TextBox',
                Size: 85,
                Name: 'z1width',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionZones.Z1Width',
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'zonewidth',
                UIPropertyId: 'zone1Length'
            },
            {
                ControlType: 'ToggleImageButton',
                Name: 'isz1widthlocked',
                ParentControlName: 'zonewidth',
                ButtonSize: ButtonSize.Normal,
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                Position: 'right',
                Size: 10,
                UIPropertyId: 'zone1LengthLock'
            },
            {
                ControlType: 'TextBox',
                Name: 'z2width',
                Size: 85,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionZones.Z2Width',
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'zonewidth',
                UIPropertyId: 'zone2Length'
            },
            {
                ControlType: 'ToggleImageButton',
                Name: 'isz2widthlocked',
                ParentControlName: 'zonewidth',
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                Position: 'right',
                Size: 10,
                UIPropertyId: 'zone2LengthLock'
            },
            {
                ControlType: 'TextBox',
                Name: 'z3width',
                Size: 85,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionZones.Z3Width',
                UnitGroup: UnitGroup.Length,
                ParentControlName: 'zonewidth',
                UIPropertyId: 'zone3Length'
            },
            {
                ControlType: 'ToggleImageButton',
                Name: 'isz3widthlocked',
                ParentControlName: 'zonewidth',
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                Position: 'right',
                Size: 10,
                UIPropertyId: 'zone3LengthLock'

            },
            {
                ControlType: 'TextBox',
                Name: 'z4width',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionZones.Z4Width',
                UnitGroup: UnitGroup.Length,
                Size: 85,
                ParentControlName: 'zonewidth',
                UIPropertyId: 'zone4Length'
            },
            {
                ControlType: 'ToggleImageButton',
                Name: 'isz4widthlocked',
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                ParentControlName: 'zonewidth',
                Position: 'right',
                Size: 10,
                UIPropertyId: 'zone4LengthLock'
            }
        ]
    };
}

function createSPMenu_BaseMaterial_Openings(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings',
        Name: 'openings-section',
        Id: 24,
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.DefineOpening',
                Name: 'define-opening',
                UIPropertyId: 'defineOpening'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening-length',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                UIPropertyId: 'openingLength'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening-width',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                UIPropertyId: 'openingWidth'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening-origin-x',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                UIPropertyId: 'openingOriginX'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening-origin-y',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                UIPropertyId: 'openingOriginY'
            }
        ]
    };
}
