import { Injectable } from '@angular/core';
import { Separator } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { NumberFormat } from '@profis-engineering/pe-ui-common/services/localization.common';
import { LocalizationService } from './localization.service';
import { UserSettingsService } from './user-settings.service';

// Equivalent to "d" standard short date format in .NET
const DefaultShortDateMomentFormat = 'L';

@Injectable({
    providedIn: 'root'
})
export class CultureInfoService {
    constructor(
        private localizationService: LocalizationService,
        private userSettingsService: UserSettingsService
    ) {}

    public get globalRegionCulture(): string {
        return this.userSettingsService.globalRegion.culture;
    }

    public get currentLanguage(): string {
        return this.localizationService.selectedLanguage;
    }

    private get numberFormat(): NumberFormat {
        return this.localizationService.numberFormat();
    }

    public get getNumberGroupSeparator(): string {
        const userthousandsSeparatorValue = this.userSettingsService.settings.application.general.thousandsSeparatorId.value;
        return (!userthousandsSeparatorValue || userthousandsSeparatorValue == Separator.Default)
            ? this.numberFormat.NumberGroupSeparator
            : this.userSettingsService.getThousandsSeparator().character;
    }

    public get getNumberDecimalSeparator(): string {
        const userDecimalSeparatorValue = this.userSettingsService.settings.application.general.decimalSeparatorId.value;
        return (!userDecimalSeparatorValue || userDecimalSeparatorValue == Separator.Default)
            ? this.numberFormat.NumberDecimalSeparator
            : this.userSettingsService.getDecimalSeparator().character;
    }

    public get getNumberGroupSizes() {
        // user only selects thousands and decimal separator so we hardcode the thousands
        // group size to 3 (every third digit you get a thousands separator)
        return [3];
    }

    public get globalRegionShortDatePattern(): string | undefined {
        return this.userSettingsService.globalRegion.dateTimeFormat?.ShortDatePattern;
    }

    public formatShortDatePattern(date: Date): string {
        return this.localizationService.moment(date).locale(this.globalRegionCulture).format(DefaultShortDateMomentFormat);
    }
}
