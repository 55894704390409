import { Injectable } from '@angular/core';
import { IModuleInitialData } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApplicationProviderService } from './application-provider.service';
import { TourService } from './tour.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataService {

    constructor(
        private applicationProviderService: ApplicationProviderService,
        private tourService: TourService
    ) { }

    public getInitialData(): IModuleInitialData {
        return {
            quickStartApplications: this.applicationProviderService.getQuickStartApplications(),
            appSettingsData: this.applicationProviderService.getAppSettings(),
            virtualTours: this.tourService.getVirtualTours(),
            designInfo: this.applicationProviderService.getDesignInfo(),
            designListInfo: this.applicationProviderService.getDesignListInfo(),
            designTypes: this.applicationProviderService.getDesignTypes(),
            favoritesInfo: this.applicationProviderService.getFavoritesInfo(),
            importDesignProvider: this.applicationProviderService.getImportDesignProvider()
        };
    }

    /**Update module data (e.g. on localization change)*/
    public updateModuleData(): IModuleInitialData {
        return {
            quickStartApplications: this.applicationProviderService.getQuickStartApplications(),
            appSettingsData: this.applicationProviderService.getAppSettings(),
            virtualTours: this.tourService.getVirtualTours(),
            designInfo: this.applicationProviderService.getDesignInfo(),
            designListInfo: this.applicationProviderService.getDesignListInfo()
        };
    }
}
