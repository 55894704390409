import { Injectable } from '@angular/core';
import { IGetStringOptions, ISanitizeTags, LocalizationServiceBase, NumberFormat } from '@profis-engineering/pe-ui-common/services/localization.common';
import type { Moment } from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';

type Translations = Partial<Record<string, string>>;
type LanguageTranslations = Partial<Record<string, Translations>>;

const reportTranslationsStartKeys = [
    'SP.',
    'Nolasoft.Hilti.SPS.',
    'Nolasoft.Hilti.Profis3.Report.EULA_1',
    'Nolasoft.Hilti.Profis3.Report.EULA_2',
    'Nolasoft.Hilti.Profis3.Report.EULA_TITLE'
];

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {
    private baseService!: LocalizationServiceBase;

    private translations?: Translations;
    private missingKeys: Record<string, undefined> = {};

    private languageTranslations: LanguageTranslations = {};

    public setBaseService(baseService: LocalizationServiceBase): void {
        this.baseService = baseService;

        this.baseService.addGetTranslationsHook(this.loadTranslations.bind(this));
    }

    public static readonly H1OlLi: Readonly<ISanitizeTags> = {
        h1: true,
        li: true,
        ol: true
    };

    public static readonly PBrB: Readonly<ISanitizeTags> = {
        p: true,
        br: true,
        b: true
    };

    public static readonly A: Readonly<ISanitizeTags> = {
        a: true
    };

    public static readonly SubSup: Readonly<ISanitizeTags> = {
        sub: true,
        sup: true
    };

    public static readonly I: Readonly<ISanitizeTags> = {
        i: true
    };

    public get selectedLanguage(): string {
        return this.baseService.selectedLanguage;
    }

    public get localizationChange(): Observable<void> {
        return this.baseService.localizationChange;
    }

    public get separatorChange(): Observable<void> {
        return this.baseService.separatorChange;
    }

    public sanitizeText(value: string, tags: ISanitizeTags): string {
        return this.baseService.sanitizeText(value, tags);
    }

    public numberFormat(): NumberFormat {
        return this.baseService.numberFormat();
    }

    public isHtml(value: string): boolean {
        return this.baseService.isHtml(value);
    }

    public moment(date?: Date): Moment {
        return this.baseService.moment(date);
    }

    public async fetchTranslations(name: string): Promise<Translations | undefined> {
        const urlName = environment.manifest != null
            ? environment.manifest[name]
            : name;

        // return undefined for missing translations
        // undefined return will default to en-us language
        if (urlName == null) {
            console.warn(`Translation manifest for ${name} not found`);
            return undefined;
        }

        const response = await fetch(`cdn/pe-ui-sp/translations/${urlName}`, {
            method: 'GET',
            // no-cache in local debug where we don't build the manifest file
            cache: environment.manifest != null ? undefined : 'no-cache'
        });

        // we have translations
        if (response.ok) {
            return (await response.json()) ?? {};
        }

        // missing translations
        if (response.status == 404) {
            return undefined;
        }

        // error
        throw new Error('loadTranslations error response', { cause: response });
    }

    public async loadTranslations(language = this.selectedLanguage): Promise<void> {
        // translations might already be loaded from before
        const currentTranslations = this.languageTranslations[language];
        if (currentTranslations != null) {
            this.translations = currentTranslations;
            return;
        }

        // load translations
        try {
            const translations = await this.fetchTranslations(`translation_${language}.json`);

            // mising translations, try en-US
            if (translations == null) {
                if (language != 'en-US') {
                    await this.loadTranslations('en-US');
                    return;
                }

                // even en-US is missing
                // clear all translations so we get keys as values
                this.translations = {};
                return;
            }

            // save translations
            this.languageTranslations[language] = this.translations = translations;
        }
        catch (error) {
            console.error(error);

            // clear all translations so we get keys as values
            this.translations = {};
            return;
        }
    }

    public filterReportTranslations(): Record<string, string> {
        if (!this.translations)
            return {};

        const entries = Object.entries(this.translations);
        const filteredEntries = entries.filter(([key]) => reportTranslationsStartKeys.some(reportKey => key.startsWith(reportKey)));
        const mappedEntries = filteredEntries.map(([key, value]) => [key, value]);

        return Object.fromEntries(mappedEntries);
    }

    /** Get translation for translation key.*/
    public getString(key: string, opts?: IGetStringOptions | undefined): string {
        if (key == null) {
            return undefined!;
        }

        const translation = this.translations?.[key] ?? this.baseService.getString(key, { optional: true });

        if (translation == null) {
            if (opts?.optional) {
                return undefined!;
            }

            if (!(key in this.missingKeys)) {
                this.missingKeys[key] = undefined;
                console.warn(`Missing localized string: ${key}`);
            }

            return `#?${key}?#`;
        }

        if (opts?.tags != null) {
            return this.sanitizeText(translation, opts.tags);
        }

        return translation;
    }

    public getKeyExists(key: string): boolean {
        return this.translations?.[key] != null || this.baseService.getKeyExists(key);
    }

    public hasTranslation(key: string): boolean {
        if (key == null || key.trim() == '' || !this.getKeyExists(key)) {
            return false;
        }

        const translation = this.getString(key, { optional: true });
        return translation != null && translation.trim() != '';
    }
}
