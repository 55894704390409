import { ZoneNumber } from '../../../services/data.service';
import { MeasurementData, MeasurementPlaneRowData } from './measurements';
import { GetEdgeDistanceX, GetEdgeDistanceY, GetMinEdgeDistanceX, GetMinEdgeDistanceXProperty, GetMinEdgeDistanceY, GetMinEdgeDistanceYProperty, GetSpacingX, GetSpacingXProperty, GetSpacingY, GetSpacingYProperty, GetStrengtheningElementDefinition, GetZoneLength } from '../zones-helper';
import { StrengthModel } from '../../strength-gl-model';

enum MeasurementPosition {
    XNegative = 1,
    XPositive = -1
}



export interface AnchorPositionData {
    edgeDistanceX: number;
    edgeDistanceY: number;
    spacingX: number;
    spacingY: number;
    rowCount: number;
    columnCount: number;
}

export function AnchorXMeasurements(model: StrengthModel) : MeasurementPlaneRowData {
    const measurements: MeasurementData[] = [];

    let xBaseOffset = -model.baseMaterial.length / 2;
    for (let index = 1; index <= model.zones.numberOfZones; index++) {
        const zoneNumber = index as ZoneNumber;
        const zoneLength = GetZoneLength(model, zoneNumber);
        const ZonesMeasurements = ZoneXMeasurements(model, zoneNumber, xBaseOffset, xBaseOffset + zoneLength);
        ZonesMeasurements.forEach(x => measurements.push(x));
        xBaseOffset += zoneLength;
    }

    return {
        enabled: true,
        measurements: measurements
    };
}

export function AnchorYMeasurementsXNegativeSide(model: StrengthModel) : MeasurementPlaneRowData[] {
    const measurementRows: MeasurementPlaneRowData[] = [];

    let xBaseOffset = -model.baseMaterial.length / 2;
    for (let index = 1; index <= model.zones.numberOfZones; index++) {
        const zoneNumber = index as ZoneNumber;
        const zoneLength = GetZoneLength(model, zoneNumber);

        if (GetMeasurementPosition(model, zoneNumber) == MeasurementPosition.XNegative &&
            GetStrengtheningElementDefinition(model, zoneNumber)) {

            const data = GetAnchorPositionsData(model, zoneNumber);

            // Values of points on 2D Plane
            const y1 = -model.baseMaterial.length / 2;
            const y2 = xBaseOffset + data.edgeDistanceX;
            const x1 = -model.baseMaterial.width / 2;
            const x2 = x1 + data.edgeDistanceY;
            const x3 = x2 + data.spacingY;

            const measurements: MeasurementData[] = [];

            // Edge distance
            if (model.visibilityModel.AnchorEdgeDistanceDimensionsVisible) {
                measurements.push({
                    x1: x1,
                    y1: y1,
                    x2: x2,
                    y2: y2,
                    property: GetMinEdgeDistanceYProperty(zoneNumber),
                    custom: {
                        value: data.edgeDistanceY
                    }
                });
            }

            // Spacing
            if (data.columnCount > 1 && model.visibilityModel.AnchorSpacingDimensionsVisible) {
                measurements.push({
                    x1: x2,
                    y1: y2,
                    x2: x3,
                    y2: y2,
                    property: GetSpacingYProperty(zoneNumber),
                    custom: false
                });
            }

            measurementRows.unshift(
                {
                    enabled: true,
                    measurements: measurements
                }
            );
        }
        xBaseOffset += zoneLength;
    }

    return measurementRows;
}

export function AnchorYMeasurementsXPositiveSide(model: StrengthModel) : MeasurementPlaneRowData[] {
    const measurementRows: MeasurementPlaneRowData[] = [];

    let xBaseOffset = -model.baseMaterial.length / 2;
    for (let index = 1; index <= model.zones.numberOfZones; index++) {
        const zoneNumber = index as ZoneNumber;
        const zoneLength = GetZoneLength(model, zoneNumber);
        xBaseOffset += zoneLength;

        if (GetMeasurementPosition(model, zoneNumber) == MeasurementPosition.XPositive &&
            GetStrengtheningElementDefinition(model, zoneNumber)) {

            const data = GetAnchorPositionsData(model, zoneNumber);

            // Values of points on 2D Plane
            const y1 = model.baseMaterial.length / 2;
            const y2 = xBaseOffset - data.edgeDistanceX;
            const x1 = -model.baseMaterial.width / 2;
            const x2 = x1 + data.edgeDistanceY;
            const x3 = x2 + data.spacingY;

            const measurements: MeasurementData[] = [];

            // Edge disatnce
            if (model.visibilityModel.AnchorEdgeDistanceDimensionsVisible) {
                measurements.push({
                    x1: x1,
                    y1: y1,
                    x2: x2,
                    y2: y2,
                    property: GetMinEdgeDistanceYProperty(zoneNumber),
                    custom: {
                        value: data.edgeDistanceY
                    }
                });
            }

            // Spacing
            if (data.columnCount > 1 && model.visibilityModel.AnchorSpacingDimensionsVisible) {
                measurements.push({
                    x1: x2,
                    y1: y2,
                    x2: x3,
                    y2: y2,
                    property: GetSpacingYProperty(zoneNumber),
                    custom: false
                });
            }

            measurementRows.push(
                {
                    enabled: true,
                    measurements: measurements
                }
            );
        }
    }

    return measurementRows;
}

function ZoneXMeasurements(model: StrengthModel, zoneNumber: ZoneNumber, zoneStartX: number, zoneEndX: number): MeasurementData[] {
    if (!GetStrengtheningElementDefinition(model, zoneNumber)) {
        return [];
    }

    const measurements: MeasurementData[] = [];

    const direction = GetMeasurementPosition(model, zoneNumber);
    const data = GetAnchorPositionsData(model, zoneNumber);

    // Values of points on 2D Plane
    const y1 = -model.baseMaterial.width / 2;
    const y2 = y1 + data.edgeDistanceY;
    const x1 = GetMeasurementStartX(direction, zoneStartX, zoneEndX);
    const x2 = x1 + data.edgeDistanceX * direction;
    const x3 = x2 + data.spacingX * direction;

    const spacingStartX = direction == MeasurementPosition.XNegative ? x2 : x3;
    const spacingEndX = direction == MeasurementPosition.XNegative ? x3 : x2;
    const edgeDistanceStartX = direction == MeasurementPosition.XNegative ? x1 : x2;
    const edgeDistanceEndX = direction == MeasurementPosition.XNegative ? x2 : x1;

    // Edge distance
    if (model.visibilityModel.AnchorEdgeDistanceDimensionsVisible) {
        measurements.push({
            x1: edgeDistanceStartX,
            x2: edgeDistanceEndX,
            y1: y1,
            y2: y2,
            property: GetMinEdgeDistanceXProperty(zoneNumber),
            custom: {
                value: data.edgeDistanceX
            }
        });
    }

    // Spacing
    if (data.rowCount > 1 && model.visibilityModel.AnchorSpacingDimensionsVisible) {
        measurements.push({
            x1: spacingStartX,
            x2: spacingEndX,
            y1: y2,
            y2: y2,
            property: GetSpacingXProperty(zoneNumber),
            custom: false
        });
    }

    return measurements;
}

function GetAnchorPositionsData(model: StrengthModel, zoneNumber: ZoneNumber) : AnchorPositionData {
    const minEdgeDistanceX = GetMinEdgeDistanceX(model, zoneNumber);
    const minEdgeDistanceY = GetMinEdgeDistanceY(model, zoneNumber);
    const spacingX = GetSpacingX(model, zoneNumber);
    const spacingY = GetSpacingY(model, zoneNumber);
    const zoneLength = GetZoneLength(model, zoneNumber);
    const zoneWidth = model.baseMaterial.width;
    const availableSpaceX = zoneLength - 2 * minEdgeDistanceX;
    const availableSpaceY = zoneWidth - 2 * minEdgeDistanceY;
    const rowCount = Math.floor(availableSpaceX / spacingX) + 1;
    const columnCount = Math.floor(availableSpaceY / spacingY) + 1;
    const edgeDistanceX = GetEdgeDistanceX(model, zoneNumber);
    const edgeDistanceY = GetEdgeDistanceY(model, zoneNumber);

    return {
        edgeDistanceX: edgeDistanceX,
        edgeDistanceY: edgeDistanceY,
        spacingX: spacingX,
        spacingY: spacingY,
        rowCount: rowCount,
        columnCount: columnCount
    };
}

function GetMeasurementPosition(model: StrengthModel, zoneNumber: ZoneNumber) {
    switch(zoneNumber) {
        case 1:
            return MeasurementPosition.XNegative;
        case 2:
            return model.zones.numberOfZones == 2 ? MeasurementPosition.XPositive : MeasurementPosition.XNegative;
        case 3:
            return MeasurementPosition.XPositive;
        case 4:
            return MeasurementPosition.XPositive;
        case 5:
            return MeasurementPosition.XPositive;
        case 6:
            return MeasurementPosition.XPositive;
        case 7:
            return MeasurementPosition.XPositive;
        case 8:
            return MeasurementPosition.XPositive;
    }
}

function GetMeasurementStartX(direction: MeasurementPosition, zoneStartX: number, zoneEndX: number) {
    switch(direction) {
        case MeasurementPosition.XNegative:
            return zoneStartX;
        case MeasurementPosition.XPositive:
            return zoneEndX;
    }
}
