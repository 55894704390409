import { Injectable } from '@angular/core';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';

@Injectable({
  providedIn: 'root'
})
export class MathService {
    private baseService!: MathServiceBase;

    public setBaseService(baseService: MathServiceBase): void {
        this.baseService = baseService;
    }

    public tryComputeUnitValue(value: string, unitGroup?: UnitGroup, unit?: UnitType, uiProperty?: string, precision?: number): string {
        return this.baseService.tryComputeUnitValue(value, unitGroup, unit, uiProperty, precision);
    }
}
