import { Injectable } from '@angular/core';
import { TourServiceBase } from '@profis-engineering/pe-ui-common/services/tour.common';
import { IVirtualTour } from '@profis-engineering/pe-ui-common/entities/module-initial-data';

@Injectable({
    providedIn: 'root'
})
export class TourService {
    private baseService!: TourServiceBase;

    public setBaseService(baseService: TourServiceBase): void {
        this.baseService = baseService;
    }

    public getVirtualTours(): IVirtualTour[] {
        return [];
    }
}
