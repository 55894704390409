import { Component, ViewEncapsulation } from '@angular/core';
import transverseEccentricityImage from '../../../../images/e_inst.png';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';

@Component({
    templateUrl: './info-dialog-transverse-eccentricity.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogTransverseEccentricityComponent extends InfoDialogBaseComponent {
    public transverseEccentricityImage = transverseEccentricityImage;

    public getTitle(): string {
        return this.translate('SP.PostInstalled.TransverseEccentricity.InfoPopUp.Title');
    }
}
