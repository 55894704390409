<div class="safety-factors-inputs content-group">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-partialSafetyFactor">
                <pe-numeric-text-box [id]="gammaSTextBox.id" [title]="gammaSTextBox.title"
                    [placeholder]="gammaSTextBox.placeholder" [value]="gammaS"
                    (valueChange)="gammaSChange.emit(gammaS = $any($event).detail)" [unit]="gammaSTextBox.unit"
                    [minValue]="gammaSTextBox.minValue" [maxValue]="gammaSTextBox.maxValue" [disabled]="disabled"
                    [tooltip]="gammaSTextBox.tooltip" [precision]="gammaSTextBox.precision">
                </pe-numeric-text-box>
            </div>

            <div class="control-partialSafetyFactor">
                <pe-numeric-text-box [id]="gammaCTextBox.id" [title]="gammaCTextBox.title"
                    [placeholder]="gammaCTextBox.placeholder" [value]="gammaC"
                    (valueChange)="gammaCChange.emit(gammaC = $any($event).detail)" [unit]="gammaCTextBox.unit"
                    [minValue]="gammaCTextBox.minValue" [maxValue]="gammaCTextBox.maxValue" [disabled]="disabled"
                    [tooltip]="gammaCTextBox.tooltip" [precision]="gammaCTextBox.precision">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>