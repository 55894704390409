import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { PropertyIdValue } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { includeSprites, productUiSprites } from '../../sprites';

export interface ProductSelectionInput {
    selectedProductId: number;
    products: ProductSelectionProduct[];
    propertyChange: (propertyChanges: PropertyIdValue[]) => Promise<void>;
}

export interface ProductSelectionProduct {
    id: number;
    name: string;
    image?: string;
}

@Component({
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.scss'],
    // https://hilti.atlassian.net/browse/PSP-1217 - uneeded css will be fixed in this task
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ProductSelectionComponent implements OnInit {

    @Input()
    public modalInstance!: ModalInstance<ProductSelectionInput>;

    public dataLoaded = false;

    public scrollElement!: Element;

    public productElementRowHeight = 90; // must be the same value as in css

    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>,
    )
    {}

    public ngOnInit(): void {
        this.scrollElement = document.querySelector('.modal') as Element;
        includeSprites(this.elementRef.nativeElement.shadowRoot, ...productUiSprites);
        this.dataLoaded = true;
    }

    public get selectedProductId() {
        return this.modalInstance.input!.selectedProductId;
    }

    public get products(): ProductSelectionProduct[] {
        return this.modalInstance.input!.products;
    }

    public productTrackById(index: number, item: ProductSelectionProduct) {
        return item.id;
    }

    public translate(key: string): string {
        return this.localizationService.getString(key);
    }

    public async selectProduct(product: ProductSelectionProduct) {
        await this.updateProperties(product.id);
        this.close();
    }

    public async updateProperties(productId: number) {
        const propertyChanges: PropertyIdValue[] = [];

        propertyChanges.push({ propertyId: 'fastenerFamilyGroupId', propertyValue: productId });

        await this.modalInstance.input!.propertyChange(propertyChanges);
    }

    public close() {
        this.modalInstance.close();
    }
}
