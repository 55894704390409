import { Injectable } from '@angular/core';
import { NumberServiceBase, NumberType } from '@profis-engineering/pe-ui-common/services/number.common';

@Injectable({
    providedIn: 'root'
})
export class NumberService {
    private baseService!: NumberServiceBase;

    public setBaseService(baseService: NumberServiceBase): void {
        this.baseService = baseService;
    }

    public format(value: number, decimals?: number, toFixed?: boolean, decimalSeparator?: string, groupSeparator?: string): string {
        return this.baseService.format(value, decimals, toFixed, decimalSeparator, groupSeparator);
    }

    public round(value: number, decimals?: number): number {
        return this.baseService.round(value, decimals);
    }

    public parse(value: string, type: NumberType, decimalSeparator?: string, groupSeparator?: string): number | null {
        return this.baseService.parse(value, type, decimalSeparator, groupSeparator);
    }

    public equals(x: number, y: number, precision: number): boolean {
        return this.baseService.equals(x, y, precision);
    }
}
