<div class="content-group" *ngIf="isLoaded">

    <div class="content design-region">
        <p class="title-strike" l10n="SP.ApplicationSettings.General"></p>

        <div class="content-controls region">
            <pe-dropdown
                [id]="regionDropdown.id"
                [title]="regionDropdown.title"
                [items]="regionDropdown.items"
                [selectedValue]="settings.regionId"
                (selectedValueChange)="onRegionDropdownChange($any($event).detail)"
                class="control"
                [disabled]="submitted"
            ></pe-dropdown>

            <div class="design-type-status control-row info">
                <p [class.error]="!isRegionValid">{{allowStatus}}</p>
            </div>
        </div>
        <div class="content-controls">
            <span class="local-regulations-disclaimer" l10n="SP.AddEditDesign.LocalRegulationsDisclaimer"></span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="allowQuickStart">
        <p class="title-strike" l10n="SP.AddEditDesign.MethodAndApprovals"></p>
        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown [id]="designStandardDropdown.id" [title]="designStandardDropdown.title"
                        [items]="designStandardDropdown.items" [selectedValue]="settings.designStandardId"
                        (selectedValueChange)="settings.designStandardId = $any($event).detail" class="design-standard-dropdown control"
                        [disabled]="submitted"></pe-dropdown>
                    <div class="design-standard-note" *ngIf="designStandardDescription != undefined">{{designStandardDescription}}</div>
                </div>
            </div>
        </div>

        <p class="title-strike" l10n="SP.ApplicationSettings.Units"></p>
        <div class="content-controls">
            <pe-dropdown [id]="lengthDropdown.id" [title]="lengthDropdown.title" [items]="lengthDropdown.items"
                [selectedValue]="settings.length" (selectedValueChange)="settings.length = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="areaDropdown.id" [items]="areaDropdown.items" [title]="areaDropdown.title"
                [selectedValue]="settings.area" (selectedValueChange)="settings.area = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="stressDropdown.id" [items]="stressDropdown.items" [title]="stressDropdown.title"
                [selectedValue]="settings.stress" (selectedValueChange)="settings.stress = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="forceDropdown.id" [items]="forceDropdown.items" [title]="forceDropdown.title"
                [selectedValue]="settings.force" (selectedValueChange)="settings.force = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="momentDropdown.id" [items]="momentDropdown.items" [title]="momentDropdown.title"
                [selectedValue]="settings.moment" (selectedValueChange)="settings.moment = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="temperatureDropdown.id" [items]="temperatureDropdown.items"
                [title]="temperatureDropdown.title" [selectedValue]="settings.temperature"
                (selectedValueChange)="settings.temperature = $any($event).detail" class="control"
                [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="forcePerLengthDropdown.id" [items]="forcePerLengthDropdown.items"
                [title]="forcePerLengthDropdown.title" [selectedValue]="settings.forcePerLength"
                (selectedValueChange)="settings.forcePerLength = $any($event).detail" class="control"
                [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="densityDropdown.id" [items]="densityDropdown.items" [title]="densityDropdown.title"
                [selectedValue]="settings.density" (selectedValueChange)="settings.density = $any($event).detail"
                class="control" [disabled]="submitted"></pe-dropdown>

            <pe-dropdown [id]="areaPerLengthDropdown.id" [items]="areaPerLengthDropdown.items"
                [title]="areaPerLengthDropdown.title" [selectedValue]="settings.areaPerLength"
                (selectedValueChange)="settings.areaPerLength = $any($event).detail" class="control"
                [disabled]="submitted"></pe-dropdown>
        </div>
    </div>
</div>
