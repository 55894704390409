<div class="steel-material-inputs content-group">
    <div class="content fixed-inputs">
        <div class="content-controls">
            <div class="control-steelMaterial">
                <pe-numeric-text-box [id]="eTextBox.id" [title]="eTextBox.title" [placeholder]="eTextBox.placeholder"
                    [value]="e" (valueChange)="eChange.emit(e = $any($event).detail)" [unit]="eUnit"
                    [minValue]="eTextBox.minValue" [maxValue]="eTextBox.maxValue" [disabled]="disabled"
                    [tooltip]="eTextBox.tooltip" [precision]="eTextBox.precision">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
