import { Component, ViewEncapsulation } from '@angular/core';
import drillingAidImage from '../../../../images/drilling-aid.png';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';
@Component({
    templateUrl: './info-dialog-drilling-aid.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogDrillingAidComponent extends InfoDialogBaseComponent {

    public drillingAidImage = drillingAidImage;

    public getTitle(): string {
        return this.translate('SP.InstallationConditions.DrillingAid.InfoPopUp.Title');
    }
}
