import { Injectable } from '@angular/core';
import { TooltipServiceBase } from '@profis-engineering/pe-ui-common/services/tooltip.common';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
    private baseService!: TooltipServiceBase;

    public setBaseService(baseService: TooltipServiceBase): void {
        this.baseService = baseService;
    }

    public show(text: string): void {
        this.baseService.show(text);
    }
    public hide(text: string): void {
        this.baseService.hide(text);
    }
}
