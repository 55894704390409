import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './sp/app.module';
import { environment } from './environments/environment';
import { registerGlobalStyles } from './global-styles';

interface Zone {
    root: NgZone;
    current: Zone;
    get: (name: string) => unknown;
    _properties: {
        zoneName: string;
    };
}

interface InternalNgZone extends NgZone {
    _inner: Zone;
}

declare const Zone: Zone;
declare const ngDevMode: boolean;

// correctly check if we are in pe-ui-sp zone
NgZone.isInAngularZone = () => Zone.current.get('zoneName') === 'pe-ui-sp';

registerGlobalStyles();

if (environment.production) {
    enableProdMode();
}

declare global {
    interface Window {
        modules: Module[];
    }
}

interface Module {
    name: string;
    bootstrap: () => void;
}

const modules = window.modules = window.modules ?? [];

modules.push({
    name: 'pe-ui-sp',
    bootstrap
});

function bootstrap() {
    // mark the zone with pe-ui-sp flag so we can correctly check in NgZone.isInAngularZone
    const ngZone = new NgZone({
        enableLongStackTrace: typeof ngDevMode === 'undefined' ? false : !!ngDevMode,
        shouldCoalesceEventChangeDetection: false,
        shouldCoalesceRunChangeDetection: false,
    });
    ngZone.runOutsideAngular = <T>(fn: (...args: unknown[]) => T): T => {
        return Zone.root.run(fn);
    };

    const zone = (ngZone as InternalNgZone)._inner;
    zone._properties.zoneName = 'pe-ui-sp';

    platformBrowserDynamic()
        .bootstrapModule(AppModule, { ngZone })
        .catch(err => console.error(err));
}
