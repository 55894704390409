import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { DataService } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';

type NumericTextBoxPropsBound = Pick<NumericTextBoxProps, 'id' | 'title' | 'placeholder' | 'unit' | 'minValue' | 'maxValue'>;

type ControlPropertyId = 'kc';

@Component({
    selector: 'app-add-edit-design-inputs-interface-shear-calculation',
    templateUrl: './add-edit-design-inputs-interface-shear-calculation.component.html',
    styleUrls: ['./add-edit-design-inputs-interface-shear-calculation.component.scss']
})
export class AddEditDesignInputsInterfaceShearCalculationComponent implements OnInit, OnChanges {

    @Input()
    public Kc: number | null = null;

    @Output()
    public KcChange = new EventEmitter<number | null>();

    @Input()
    public disabled!: boolean;

    @Input()
    public regionId!: number;

    @Input()
    public designStandardId!: number | undefined;

    public KcTextBox!: NumericTextBoxPropsBound;

    constructor(
        private localizationService: LocalizationService,
        private dataService: DataService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['regionId']?.firstChange === false) {
            this.onRegionChange();
        }
    }

    public ngOnInit(): void {
        this.initControls();
        this.onRegionChange();
    }

    private updateControl(control: NumericTextBoxPropsBound, appPropertyId: ControlPropertyId) {
        const regionId = this.regionId;
        const propertyValue = this.dataService.getPropertyDetail(appPropertyId);

        control.maxValue = propertyValue.maxValue;
        control.minValue = propertyValue.minValue;
        control.placeholder = propertyValue.defaultValue ?? this.localizationService.getString('SP.Placeholder.Default');
        control.unit = this.dataService.getPropertyDetail('unitAngle', { regionId }).defaultValue;
    }

    private onRegionChange() {
        this.updateControl(this.KcTextBox, 'kc');
    }

    private initControls() {
        this.KcTextBox = {
            id: 'interface-shear-calculation-parameters-kc-textbox',
            title: this.localizationService.getString('SP.InterfaceShear.Kc')
        };
    }

    public isSIADesignStandard(designStandardId: number | undefined) {
        return designStandardId == 2;
    }
}
