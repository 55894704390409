import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { getSpriteAsIconStyle } from '../../sprites';
import { PunchRegion, PunchTab } from './static-menu.punch.definitions';

export function createSPMenu_Application(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabApplication',
        Id: 6,
        Name: 'application-tab',
        Image: 'tab-application',
        IconImage: getSpriteAsIconStyle('sprite-tab-application-punch'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_Application_TabApplication(),
            createSPMenu_Application_TabColumns()
        ]
    };
}

function createSPMenu_Application_TabApplication(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabApplication.RegionApplication',
        Id: 60,
        Name: 'application-section',
        Controls: [
            {
                ControlType: 'ImageNameSelectionGroup',
                Name: 'application-basemember-image-name-selection',
                TitleDisplayKey: 'SP.Navigation.TabApplication.RegionApplication.BaseMember',
                CodelistName: 'baseMembers',
                UIPropertyId: 'baseMemberId'
            },
            {
                ControlType: 'ImageNameSelectionGroup',
                Name: 'application-compressionmember-image-name-selection',
                TitleDisplayKey: 'SP.Navigation.TabApplication.RegionApplication.CompressionMember',
                CodelistName: 'compressionMembers',
                UIPropertyId: 'compressionMemberId'
            }
        ]
    };
}
export function createSPMenu_Application_TabColumns(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabApplication.RegionColumnDimensions',
        Name: 'columns-section',
        Id: 61,
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'punch-length',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabApplication.RegionColumnDimensions.Length',
                TitleDisplayKey: 'SP.Navigation.TabApplication.RegionColumnDimensions.Length',
                UIPropertyId: 'punchLength'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'punch-width',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabApplication.RegionColumnDimensions.Width',
                TitleDisplayKey: 'SP.Navigation.TabApplication.RegionColumnDimensions.Width',
                UIPropertyId: 'punchWidth'
            }
        ]
    };
}
