import { Component, ViewEncapsulation } from '@angular/core';
import crossSectionalAreaImage from '../../../../images/a_s-and-d.png';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';

@Component({
    templateUrl: './info-dialog-as.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogCrossSectionalAreaComponent extends InfoDialogBaseComponent {
    public crossSectionalAreaImage = crossSectionalAreaImage;

    public getTitle(): string {
        return this.translate('SP.ExistingReinforcement.As.InfoPopUp.Title');
    }
}
