import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { CreateIUnitText2DCreateOptions } from '@profis-engineering/gl-model/components/base-component';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { StrengthPropertyId } from '../../../services/design.service';
import { StrengthComponent, StrengthComponentConstructor } from '../../strength-component';
import { StrengthUnitText2D } from '../../strength-gl-model';
import { GetMeasurementsAlphaIndex, MeasurementMetaData } from '../alpha-index-helper';
import { AnchorXMeasurements, AnchorYMeasurementsXNegativeSide, AnchorYMeasurementsXPositiveSide } from './measurements-anchors';
import { SlabHeightMeasurement, SlabLengthMeasurement, SlabWidthMeasurement } from './measurements-base-material';
import { ArrowDirection, CreateArrow, GetPlaneHeightfromPlaneOriginVector, GetPlaneYFromPlaneOriginVector, GetYAxis, MeasurementPlane, Project2DPlaneInto3D } from './measurements-helper';
import { ZonesXMeasurement, ZonesYMeasurement } from './measurements-zones';

/** A collection of measurements on a single plane */
interface MeasurementsPlaneData {
    /** Mesh consisting of all measurements on the plane */
    linesMesh?: LinesMesh | undefined;
    /** Array of all Measurement rows on this plane */
    measurementsRows: MeasurementPlaneRowData[];
    /** Specified plane this data is on */
    plane: MeasurementPlane;
    /** Origin point (0,0) of this plane */
    planeRoot: Vector3;
}

/** A collection of measurements in a single row */
export interface MeasurementPlaneRowData {
    /** Is enabled/displayed indicator */
    enabled: boolean;
    /** A collection of measurements in a this row */
    measurements: MeasurementData[];
}

/**
 * Data for a single measurement on 2D plane
 *       ___________________
 *      |(x1, y2)           | (x2, y2)
 *      |                   |
 *      |                   |
 * _____|___________________|_____
 *      ^ plane origin      |
 *      |                   |
 *      |                   |
 *       (x1, y1)            (x2, y1)
 *       starting root       ending root
 *
 *      y2 = calculated based in which row on the plane measurement lies
 */
export interface MeasurementData {
    /** x value of the starting root of the measurement on 2D plane (point where measurement starts) */
    x1: number;
    /** x value of the ending root of the measurement on 2D plane (point where measurement ends) */
    x2: number;
    /** y value of the starting root measurement on 2D plane */
    y1: number;
    /** y value of the ending root measurement on 2D plane */
    y2: number;
    /** Property corresponding to this measurement */
    property: StrengthPropertyId;
    /** Custom value of measurement; overrides property value and is unediable */
    custom: CustomMeasurementValue | false;
}

export interface CustomMeasurementValue {
    value: number;
}

interface TextData {
    text: StrengthUnitText2D;
    metadata?: Record<string, unknown>;
}

const dimensionLinesColor = Color3.FromHexString('#333333');
const initialOffset = 200;
const incrementalOffset = 400;

export class Measurements extends StrengthComponent {

    private measurementTextsData: Partial<Record<StrengthPropertyId, TextData>> = {};
    private measurementMeshes: Array<Mesh>;
    private textCtor: CreateIUnitText2DCreateOptions;

    constructor(ctor: StrengthComponentConstructor) {
        super(ctor);

        this.textCtor = { unitGroup: UnitGroup.Length };
        this.measurementMeshes = [];
        this.createTexts();
        this.beforeCameraRender = this.beforeCameraRender.bind(this);
        this.sceneEvents.addEventListener('onBeforeCameraRenderObservable', this.beforeCameraRender);
    }

    public update(): void {
        this.ensureMesh();
    }

    /**
     * Draws measurements based on MeasurementsData
     */
    private ensureMesh() {
        for (const key in this.measurementTextsData) {
            this.measurementTextsData[key as StrengthPropertyId]?.text.setEnabled(false);

            this.measurementTextsData[key as StrengthPropertyId]?.text.mesh?.setParent(this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId));
        }

        for (const mesh of this.measurementMeshes) {
            mesh.setEnabled(false);
        }
        this.measurementMeshes = [];

        let index = 0;

        // TODO JANJ: if MeasurementsPlaneData from getMeasurementData doesn't change skip update since there is nothing to update
        // you can probably use lodash deep equal to compare MeasurementsPlaneData but you have to write a custom compare (compare by ref) for linesMesh property

        // Iterate through each plane defined in measurement data
        for (const plane of this.getMeasurementData()) {

            // Value of planes origin vector coresponding to planes normal vector dimension
            const planeHeight = GetPlaneHeightfromPlaneOriginVector(plane.planeRoot, plane.plane);

            // Indicator whether the plane extands into positive or negative direction on its Planes Y axis
            const positivePlaneDirection = GetPlaneYFromPlaneOriginVector(plane.planeRoot, plane.plane) > 0;
            const directionModifier = positivePlaneDirection ? 1 : -1;

            // Offset of measurement height on 2D plane it's drawn on
            // Each measurement row on plane is drawn above the previous ones
            let measurementHeight = initialOffset * directionModifier;

            // Iterate throught all measurement rows on measurement plane
            for (const measurementRow of plane.measurementsRows) {
                if (measurementRow.enabled) {

                    // Iterate through all measurements in measurement row
                    for (const measurement of measurementRow.measurements) {
                        const measurementY = GetPlaneYFromPlaneOriginVector(plane.planeRoot, plane.plane) + measurementHeight;
                        // rootStart and rootEnd are end points of measurement, origin points of measurement
                        const rootStart = Project2DPlaneInto3D(measurement.x1, measurement.y1, planeHeight, plane.plane);
                        const rootEnd = Project2DPlaneInto3D(measurement.x2, measurement.y2, planeHeight, plane.plane);
                        // measurementStart and measurementEnd are points of measurement line that holds the text and arrows
                        const measurementStart = Project2DPlaneInto3D(measurement.x1, measurementY, planeHeight, plane.plane);
                        const measurementEnd = Project2DPlaneInto3D(measurement.x2, measurementY, planeHeight, plane.plane);
                        const measurementName = measurement.property + 'Measurement' + index;

                        // metadata added for purposes of calculating alpha index
                        const metadata = MeasurementMetaData(plane.plane, plane.planeRoot);
                        index++;

                        const lines: Vector3[][] = [];
                        // add lines representing measurement and its arrows to lines array
                        lines.push([rootStart, measurementStart, measurementEnd, rootEnd]);
                        lines.push(CreateArrow(plane.plane, measurementStart, ArrowDirection.Start));
                        lines.push(CreateArrow(plane.plane, measurementEnd, ArrowDirection.End));

                        // add text to measurement
                        const data = this.getMeasurementText(measurement.property);
                        if (data == undefined) {
                            console.log('Missing UnitText2D for ' + measurement.property);
                        } else {
                            const value = this.getMeasurementValue(measurement);
                            data.text.setValueOnLine(
                                value,
                                measurementStart,
                                measurementEnd,
                                GetYAxis(plane.plane, directionModifier)
                            );
                            data.text.setEditable(measurement.custom == false);
                            data.metadata = metadata;
                        }

                        // draw and update the measurement mesh
                        plane.linesMesh = this.cache.meshCache.create(measurementName, () => CreateLineSystem(measurementName, { lines: lines, updatable: true }, this.scene));
                        plane.linesMesh.setEnabled(true);
                        plane.linesMesh.color = dimensionLinesColor;
                        plane.linesMesh.metadata = metadata;

                        CreateLineSystem(null!, { lines: lines, instance: plane.linesMesh }, this.scene);
                        plane.linesMesh.refreshBoundingInfo();

                        // rotation
                        plane.linesMesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);

                        this.measurementMeshes.push(plane.linesMesh);
                    }
                    measurementHeight += incrementalOffset  * directionModifier;
                }
            }
        }
    }

    /**
     * Gets the data representing all measurements
     */
    private getMeasurementData(): MeasurementsPlaneData[] {
        return [
            {
                plane: MeasurementPlane.XY,
                measurementsRows: [
                    ZonesXMeasurement(this.model),
                    SlabLengthMeasurement(this.model)
                ],
                planeRoot: new Vector3(-this.model.baseMaterial.length / 2, -this.model.baseMaterial.height / 2, this.model.baseMaterial.width / 2)
            },
            {
                plane: MeasurementPlane.ZX,
                measurementsRows: [
                    SlabHeightMeasurement(this.model)
                ],
                planeRoot: new Vector3(this.model.baseMaterial.length / 2, this.model.baseMaterial.height / 2, this.model.baseMaterial.width / 2)
            },
            {
                plane: MeasurementPlane.YX,
                measurementsRows: [
                    ZonesYMeasurement(this.model),
                    SlabWidthMeasurement(this.model)
                ],
                planeRoot: new Vector3(this.model.baseMaterial.length / 2, this.model.baseMaterial.height / 2, -this.model.baseMaterial.width / 2)
            },
            {
                plane: MeasurementPlane.XY,
                measurementsRows: [
                    AnchorXMeasurements(this.model)
                ],
                planeRoot: new Vector3(-this.model.baseMaterial.length / 2, -this.model.baseMaterial.height / 2, -this.model.baseMaterial.width / 2)
            },
            {
                plane: MeasurementPlane.YX,
                measurementsRows: AnchorYMeasurementsXNegativeSide(this.model),
                planeRoot: new Vector3(-this.model.baseMaterial.length / 2, -this.model.baseMaterial.height / 2, -this.model.baseMaterial.width / 2)
            },
            {
                plane: MeasurementPlane.YX,
                measurementsRows: AnchorYMeasurementsXPositiveSide(this.model),
                planeRoot: new Vector3(this.model.baseMaterial.length / 2, -this.model.baseMaterial.height / 2, -this.model.baseMaterial.width / 2)
            },
        ];
    }

    private createTexts() {
        this.createText('slabLength');
        this.createText('slabHeight');
        this.createText('slabWidth');
        this.createText('zone1Length');
        this.createText('zone2Length');
        this.createText('zone3Length');
        this.createText('zone4Length');
        this.createText('zone5Length');
        this.createText('zone6Length');
        this.createText('zone7Length');
        this.createText('zone8Length');
        this.createText('zone1Width');
        this.createText('zone2Width');
        this.createText('zone3Width');
        this.createText('zone4Width');
        this.createText('zone5Width');
        this.createText('zone6Width');
        this.createText('zone7Width');
        this.createText('zone8Width');
        this.createText('zone1SpacingX');
        this.createText('zone2SpacingX');
        this.createText('zone3SpacingX');
        this.createText('zone4SpacingX');
        this.createText('zone1SpacingY');
        this.createText('zone2SpacingY');
        this.createText('zone3SpacingY');
        this.createText('zone4SpacingY');
        this.createText('zone1MinimumEdgeDistanceX');
        this.createText('zone2MinimumEdgeDistanceX');
        this.createText('zone3MinimumEdgeDistanceX');
        this.createText('zone4MinimumEdgeDistanceX');
        this.createText('zone1MinimumEdgeDistanceY');
        this.createText('zone2MinimumEdgeDistanceY');
        this.createText('zone3MinimumEdgeDistanceY');
        this.createText('zone4MinimumEdgeDistanceY');
    }

    private createText(property: StrengthPropertyId) {
        this.measurementTextsData[property] = {text: this.createUnitText2DForProperty(this.textCtor, property)};
    }

    private getMeasurementValue(measurementData: MeasurementData): number {
        if (measurementData.custom) {
            return measurementData.custom.value;
        } else {
            return this.getPropertyValue(measurementData.property);
        }
    }

    private getMeasurementText(property: StrengthPropertyId | undefined) {
        if (property == undefined) {
            return undefined;
        } else {
            return this.measurementTextsData[property];
        }
    }

    private getPropertyValue(property: StrengthPropertyId): number{
        switch (property) {
            case 'slabLength': return this.model.baseMaterial.length;
            case 'slabHeight': return this.model.baseMaterial.height;
            case 'slabWidth': return this.model.baseMaterial.width;
            case 'zone1Length': return this.model.zones.zone1Length;
            case 'zone2Length': return this.model.zones.zone2Length;
            case 'zone3Length': return this.model.zones.zone3Length;
            case 'zone4Length': return this.model.zones.zone4Length;
            case 'zone5Length': return this.model.zones.zone5Length;
            case 'zone6Length': return this.model.zones.zone6Length;
            case 'zone7Length': return this.model.zones.zone7Length;
            case 'zone8Length': return this.model.zones.zone8Length;
            case 'zone1Width': return this.model.zones.zone1Width;
            case 'zone2Width': return this.model.zones.zone2Width;
            case 'zone3Width': return this.model.zones.zone3Width;
            case 'zone4Width': return this.model.zones.zone4Width;
            case 'zone5Width': return this.model.zones.zone5Width;
            case 'zone6Width': return this.model.zones.zone6Width;
            case 'zone7Width': return this.model.zones.zone7Width;
            case 'zone8Width': return this.model.zones.zone8Width;
            case 'zone1SpacingX': return this.model.postInstalledElement.zone1SpacingX ?? 0;
            case 'zone2SpacingX': return this.model.postInstalledElement.zone2SpacingX ?? 0;
            case 'zone3SpacingX': return this.model.postInstalledElement.zone3SpacingX ?? 0;
            case 'zone4SpacingX': return this.model.postInstalledElement.zone4SpacingX ?? 0;
            case 'zone1SpacingY': return this.model.postInstalledElement.zone1SpacingY ?? 0;
            case 'zone2SpacingY': return this.model.postInstalledElement.zone2SpacingY ?? 0;
            case 'zone3SpacingY': return this.model.postInstalledElement.zone3SpacingY ?? 0;
            case 'zone4SpacingY': return this.model.postInstalledElement.zone4SpacingY ?? 0;
            default: throw new Error('getPropertyValue undefined for ' + property);
        }
    }

    protected beforeCameraRender(): void {
        if (this.measurementMeshes != undefined) {
            for (const mesh of this.measurementMeshes) {
                mesh.alphaIndex = GetMeasurementsAlphaIndex(this.camera.position, mesh.metadata['MeasurementMetaData']);
            }

            for (const key in this.measurementTextsData) {
                const data = this.measurementTextsData[key as StrengthPropertyId];
                if (data?.metadata != undefined) {
                    data.text.setAlphaIndex(GetMeasurementsAlphaIndex(this.camera.position, data.metadata['MeasurementMetaData'] as MeasurementMetaData));
                }
            }
        }
    }

    public override dispose(): void {
        super.dispose();

        for (const key in this.measurementTextsData) {
            this.measurementTextsData[key as StrengthPropertyId]?.text.dispose();
        }
    }
}
