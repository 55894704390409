import { Injectable } from '@angular/core';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    private baseService!: ImportServiceBase;

    public setBaseService(baseService: ImportServiceBase): void {
        this.baseService = baseService;
    }

    public import(importProject: Project, importDesign: Design, projectDesign: File | Blob, name: string, openNewWindow?: boolean): Promise<void> {
        return this.baseService.import(importProject, importDesign, projectDesign, name, openNewWindow);
    }
}
