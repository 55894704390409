import { Vector3 } from '@babylonjs/core/Maths/math.vector';

const arrowSpanX = 21;
const arrowSpanY = 7;

/** 2D Plane in 3D space
 * first letter specifies X dimension of the plane
 * first letter specifies Y dimension of the plane
 */
export enum MeasurementPlane {
    XY,
    XZ,
    YX,
    YZ,
    ZX,
    ZY
}

/** Direction of 2D planes Y axis in 3D */
export enum AxisDirection {
    XPositive,
    XNegative,
    YPositive,
    YNegative,
    ZPositive,
    ZNegative
}

export enum ArrowDirection {
    Start,
    End
}

/**
 * Creates 3D vector from 2D vector defined by x and y on given 2D plane
 * @param x X parameter on 2D plane
 * @param y Y parameter on 2D plane
 * @param planeHeight Position of plane in 3D
 * @param plane Plane definition
 */
export function Project2DPlaneInto3D(x: number, y: number, planeHeight: number, plane: MeasurementPlane): Vector3 {
    switch(plane) {
        case MeasurementPlane.XY:
            return new Vector3(x, planeHeight, y);
        case MeasurementPlane.YX:
            return new Vector3(y, planeHeight, x);
        case MeasurementPlane.XZ:
            return new Vector3(x, y, planeHeight);
        case MeasurementPlane.ZX:
            return new Vector3(y, x, planeHeight);
        case MeasurementPlane.YZ:
            return new Vector3(planeHeight, y, x);
        case MeasurementPlane.ZY:
            return new Vector3(planeHeight, x, y);
        default:
            throw new Error('Invalid direction');
    }
}

/**
 * Gets the normal vector for given plane.
 * @param plane
 */
export function GetYAxis(plane: MeasurementPlane, directionModifier: 1 | -1): Vector3 {
    switch(plane) {
        case MeasurementPlane.YX:
        case MeasurementPlane.ZX:
            return new Vector3(1 * directionModifier, 0, 0);
        case MeasurementPlane.XY:
        case MeasurementPlane.ZY:
            return new Vector3(0, 0, 1 * directionModifier);
        case MeasurementPlane.XZ:
        case MeasurementPlane.YZ:
            return new Vector3(0, 1 * directionModifier, 0);
        default:
            throw new Error('Invalid direction');
    }
}

/**
 * Gets the dimension value of the origin vector corresponding to the normal vector dimension of given 2D plane
 * @param planeOrigin Origin of the plane
 * @param plane Plane definition
 */
export function GetPlaneHeightfromPlaneOriginVector(planeOrigin: Vector3, plane: MeasurementPlane): number {
    switch(plane) {
        case MeasurementPlane.XY:
        case MeasurementPlane.YX:
            return planeOrigin.y;
        case MeasurementPlane.XZ:
        case MeasurementPlane.ZX:
            return planeOrigin.z;
        case MeasurementPlane.YZ:
        case MeasurementPlane.ZY:
            return planeOrigin.x;
        default:
            throw new Error('Invalid direction');
    }
}

/**
 * Gets the dimension value of the origin vector corresponding to the Y dimension of given 2D plane
 * @param planeOrigin Origin of the plane
 * @param plane Plane definition
 */
export function GetPlaneYFromPlaneOriginVector(planeOrigin: Vector3, plane: MeasurementPlane): number {
    switch(plane) {
        case MeasurementPlane.YX:
        case MeasurementPlane.ZX:
            return planeOrigin.x;
        case MeasurementPlane.XY:
        case MeasurementPlane.ZY:
            return planeOrigin.z;
        case MeasurementPlane.XZ:
        case MeasurementPlane.YZ:
            return planeOrigin.y;
        default:
            throw new Error('Invalid direction');
    }
}

/**
 * Gets the dimension value of the origin vector corresponding to the X dimension of given 2D plane
 * @param planeOrigin Origin of the plane
 * @param plane Plane definition
 */
export function GetPlaneXFromPlaneOriginVector(planeOrigin: Vector3, plane: MeasurementPlane): number {
    switch(plane) {
        case MeasurementPlane.XY:
        case MeasurementPlane.XZ:
            return planeOrigin.x;
        case MeasurementPlane.YX:
        case MeasurementPlane.YZ:
            return planeOrigin.z;
        case MeasurementPlane.ZX:
        case MeasurementPlane.ZY:
            return planeOrigin.y;
        default:
            throw new Error('Invalid direction');
    }
}

/**
 * Creates arrow as array of 3D vectors at arrows origin vector location oriented acording to given plane and arrow direction
 * @param plane Plane definition
 * @param arrowOrigin Arrow origin vector
 * @param arrowDirection Arrow direction definition
 */
export function CreateArrow(plane: MeasurementPlane, arrowOrigin: Vector3, arrowDirection: ArrowDirection) {
    const direction = arrowDirection == ArrowDirection.Start ? 1 : -1;
    const x = GetPlaneXFromPlaneOriginVector(arrowOrigin, plane);
    const y = GetPlaneYFromPlaneOriginVector(arrowOrigin, plane);
    const height = GetPlaneHeightfromPlaneOriginVector(arrowOrigin, plane);
    const left = Project2DPlaneInto3D(x + arrowSpanX * direction, y + arrowSpanY, height, plane);
    const right = Project2DPlaneInto3D(x + arrowSpanX * direction, y - arrowSpanY, height, plane);
    return [left, arrowOrigin, right];
}