import { Injectable } from '@angular/core';
import {
    GuidService as GuidServiceBase
} from '@profis-engineering/pe-ui-common/services/guid.common';

@Injectable({
    providedIn: 'root'
})
export class GuidService extends GuidServiceBase {
    // TODO TEAM: fix base service so it's implemented with setBaseService as all the other services
}
