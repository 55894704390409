import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { environment } from '../../environments/environment';
import { CreateMessageFromKeys } from '../modal-helper';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { AppData } from './data.service';
import { ApiDesignCalculationOptions, ApiDesignCreateRequest, ApiDesignReportGenerateOptions, ApiDesignUpdateRequest, ApiDesignUpdateResponse, ApiHtmlReportGenerateOptions, CalculationResult, ConfirmationType, ConvertAndCalculateResult, CreateAndCalculateResult, DesignConvertResult, DesignDetailsData, DesignServiceUpdateDesignOptions, DesignTypeId, designTypes, DesignUpdateRequest, HtmlReportPaperSize, ProjectDesign, PunchApiDesignCreateRequest, PunchApiDesignReportGenerateOptions, PunchApiDesignUpdateResponse, PunchCalculationResult, PunchConvertAndCalculateResult, PunchCreateAndCalculateResult, PunchDesignDetailsData, PunchDesignServiceUpdateDesignOptions, PunchDesignUpdateRequest, PunchProjectDesign, PunchUpdateResult, ReportPaperSizeId, RequiresConfirmation, StrengthApiDesignCreateRequest, StrengthApiDesignReportGenerateOptions, StrengthApiDesignUpdateResponse, StrengthCalculationResult, StrengthConvertAndCalculateResult, StrengthCreateAndCalculateResult, StrengthDesignDetailsData, StrengthDesignServiceUpdateDesignOptions, StrengthDesignUpdateRequest, StrengthProjectDesign, StrengthUpdateResult, UpdateResult, UploadCustomImage, UploadCustomImageResponse } from './design.service';
import { DotnetService } from './dotnet.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';

export const enum SpApiType {
    web,
    webassembly
}

export interface StrengthApi {
    design: {
        convert: (projectDesign: StrengthProjectDesign, apiOptions?: ApiOptions) => Promise<StrengthProjectDesign>;
        create: (designCreateRequest: StrengthApiDesignCreateRequest, apiOptions?: ApiOptions) => Promise<StrengthProjectDesign>;
        details: (projectDesign: StrengthProjectDesign, apiOptions?: ApiOptions) => Promise<StrengthDesignDetailsData>;
        update: (updateDesignOptions: StrengthDesignServiceUpdateDesignOptions, apiOptions?: ApiOptions) => Promise<StrengthApiDesignUpdateResponse>;
    };
    calculation: {
        calculate: (projectDesign: StrengthProjectDesign, apiOptions?: ApiOptions) => Promise<StrengthCalculationResult | undefined>;
    };
    report: {
        generate: (designReportGenerateOptions: StrengthApiDesignReportGenerateOptions, apiOptions?: ApiOptions) => Promise<Blob>;
        generateHtml: (designReportGenerateOptions: StrengthApiDesignReportGenerateOptions, apiOptions?: ApiOptions) => Promise<string>;
    };
    core: {
        createAndCalculate: (designCreateRequest: StrengthApiDesignCreateRequest, apiOptions?: ApiOptions) => Promise<StrengthCreateAndCalculateResult>;
        convertAndCalculate: (projectDesign: StrengthProjectDesign, apiOptions?: ApiOptions) => Promise<StrengthConvertAndCalculateResult>;
        updateAndCalculate: (designUpdateRequest: StrengthDesignUpdateRequest, apiOptions?: ApiOptions) => Promise<StrengthUpdateResult>;
    };
}

export interface PunchApi {
    design: {
        convert: (projectDesign: PunchProjectDesign, apiOptions?: ApiOptions) => Promise<PunchProjectDesign>;
        create: (designCreateRequest: PunchApiDesignCreateRequest, apiOptions?: ApiOptions) => Promise<PunchProjectDesign>;
        details: (projectDesign: PunchProjectDesign, apiOptions?: ApiOptions) => Promise<PunchDesignDetailsData>;
        update: (updateDesignOptions: PunchDesignServiceUpdateDesignOptions, apiOptions?: ApiOptions) => Promise<PunchApiDesignUpdateResponse>;
    };
    calculation: {
        calculate: (projectDesign: PunchProjectDesign, apiOptions?: ApiOptions) => Promise<PunchCalculationResult | undefined>;
    };
    report: {
        generate: (designReportGenerateOptions: PunchApiDesignReportGenerateOptions, apiOptions?: ApiOptions) => Promise<Blob>;
        generateHtml: (designReportGenerateOptions: PunchApiDesignReportGenerateOptions, apiOptions?: ApiOptions) => Promise<string>;
    };
    core: {
        createAndCalculate: (designCreateRequest: PunchApiDesignCreateRequest, apiOptions?: ApiOptions) => Promise<PunchCreateAndCalculateResult>;
        convertAndCalculate: (projectDesign: PunchProjectDesign, apiOptions?: ApiOptions) => Promise<PunchConvertAndCalculateResult>;
        updateAndCalculate: (designUpdateRequest: PunchDesignUpdateRequest, apiOptions?: ApiOptions) => Promise<PunchUpdateResult>;
    };
}

@Injectable({
    providedIn: 'root'
})
export class SpApiService {
    public type = SpApiType.web;

    private urlName = {
        [designTypes.strength.id]: 'strength',
        [designTypes.punch.id]: 'punch',
    };

    constructor(
        private dotnetService: DotnetService,
        private apiService: ApiService,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private browserService: BrowserService,
    ) {
        this.strengthApi = this.api as unknown as StrengthApi;
        this.punchApi = this.api as unknown as PunchApi;
    }

    public get isLongRunning() {
        return this.type == SpApiType.web;
    }

    public strengthApi: StrengthApi;
    public punchApi: PunchApi;

    public api = {
        app: {
            data: async (apiOptions?: ApiOptions): Promise<AppData> => {
                if (this.type == SpApiType.web) {
                    const url = `${environment.designServiceUrl}app/data`;
                    const response = await this.apiService.request<AppData>(new HttpRequest('GET', url), {
                        supressErrorMessage: true,
                        ...apiOptions
                    });

                    return response.body as AppData;
                }

                return await this.dotnetService.api.app.data({
                    supressErrorMessage: true
                });
            }
        },
        design: {
            convert: (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<DesignConvertResult> =>
                this.apiRequest(environment.designServiceUrl, `${this.getUrlName(projectDesign.designTypeId)}-design/convert`, (request) => this.dotnetService.api.design.convert(request, apiOptions), projectDesign, apiOptions),

            create: (designCreateRequest: ApiDesignCreateRequest, apiOptions?: ApiOptions): Promise<ProjectDesign> =>
                this.apiRequest(environment.designServiceUrl, `${this.getUrlName(designCreateRequest.designTypeId)}-design/create`, (request) => this.dotnetService.api.design.create(request, apiOptions), designCreateRequest, apiOptions),

            details: (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<DesignDetailsData> =>
                this.apiRequest(environment.designServiceUrl, `${this.getUrlName(projectDesign.designTypeId)}-design/details`, (request) => this.dotnetService.api.design.details(request, apiOptions), projectDesign, apiOptions),

            update: (updateDesignOptions: DesignServiceUpdateDesignOptions, apiOptions?: ApiOptions): Promise<ApiDesignUpdateResponse> =>
                this.apiRequest(environment.designServiceUrl, `${this.getUrlName(updateDesignOptions.projectDesign.designTypeId)}-design/update`, (request) => this.dotnetService.api.design.update(request, apiOptions), updateDesignOptions, apiOptions)
        },
        calculation: {
            calculate: async (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<CalculationResult | undefined> => {
                const result = await this.apiRequest(environment.calculationServiceUrl, `${this.getUrlName(projectDesign.designTypeId)}-calculation/calculate`, (request) => this.dotnetService.api.calculation.calculate(request, apiOptions), projectDesign, apiOptions);
                console.debug('Calculate duration:', result.calculateDuration);

                return result;
            }
        },
        report: {
            generate: (designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<Blob> =>
                this.reportGenerate(this.getUrlName(designReportGenerateOptions.projectDesign.designTypeId), designReportGenerateOptions, apiOptions),
            generateHtml: (designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<string> =>
                this.reportGenerateHtml(this.getUrlName(designReportGenerateOptions.projectDesign.designTypeId), designReportGenerateOptions, apiOptions)
        },
        core: {
            createAndCalculate: (designCreateRequest: ApiDesignCreateRequest, apiOptions?: ApiOptions): Promise<CreateAndCalculateResult> =>
                this.coreCreateAndCalculate(this.getUrlName(designCreateRequest.designTypeId), designCreateRequest, apiOptions),

            convertAndCalculate: (projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<ConvertAndCalculateResult> =>
                this.coreConvertAndCalculate(this.getUrlName(projectDesign.designTypeId), projectDesign, apiOptions),

            updateAndCalculate: (designUpdateRequest: DesignUpdateRequest, apiOptions?: ApiOptions): Promise<UpdateResult> =>
                this.coreUpdateAndCalculate(this.getUrlName(designUpdateRequest.projectDesign.designTypeId), designUpdateRequest, apiOptions),
        },
        external: {
            uploadCustomImage: async (uploadCustomImage: UploadCustomImage, apiOptions?: ApiOptions): Promise<UploadCustomImageResponse> => {
                const url = `${environment.htmlReportServiceUrl}report/custom-image`;
                return (await this.apiService.request<UploadCustomImageResponse>(new HttpRequest('POST', url, uploadCustomImage), apiOptions)).body!;
            },

            getCustomImage: async (name: string, apiOptions?: ApiOptions): Promise<string> => {
                const url = `${environment.htmlReportContentUrl}custom-image/${name}`;
                const imageBlob = (await this.apiService.request<Blob>(new HttpRequest('GET', url, {
                    responseType: 'blob'
                }), apiOptions)).body!;

                return await this.browserService.blobToDataUrl(imageBlob);
            },
        }
    };

    private getUrlName(designTypeId: DesignTypeId) {
        const urlName = this.urlName[designTypeId];
        if (urlName == null) {
            throw new Error('unknown DesignTypeId');
        }

        return urlName;
    }

    private async reportGenerate(urlPrefix: string, designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<Blob> {
        if (this.type == SpApiType.web) {
            return await this.blobWebApiRequest(environment.reportServiceUrl, `${urlPrefix}-report/generate`, designReportGenerateOptions, apiOptions);
        }

        const htmlReport = await this.dotnetService.api.report.generateHtml(designReportGenerateOptions, apiOptions);
        const htmlReportInput: ApiHtmlReportGenerateOptions = {
            html: htmlReport,
            reportPaperSize: mapReportPaperSize(designReportGenerateOptions.reportPaperSizeId)
        };

        return await this.webApiHtmlReportGenerate(htmlReportInput, apiOptions);

        function mapReportPaperSize(reportPaperSizeId: ReportPaperSizeId): HtmlReportPaperSize {
            switch (reportPaperSizeId) {
                case ReportPaperSizeId.A4:
                    return 'a4';
                case ReportPaperSizeId.Letter:
                    return 'letter';
                default:
                    throw new Error('Unknown ReportPaperSizeId');
            }
        }
    }

    private async reportGenerateHtml(urlPrefix: string, designReportGenerateOptions: ApiDesignReportGenerateOptions, apiOptions?: ApiOptions): Promise<string> {
        if (this.type == SpApiType.web) {
            return await this.textWebApiRequest(environment.reportServiceUrl, `${urlPrefix}-report/generate-html`, designReportGenerateOptions, apiOptions);
        }

        return await this.dotnetService.api.report.generateHtml(designReportGenerateOptions, apiOptions);
    }

    private async coreCreateAndCalculate(urlPrefix: string, designCreateRequest: ApiDesignCreateRequest, apiOptions?: ApiOptions): Promise<CreateAndCalculateResult> {
        if (this.type == SpApiType.web) {
            const projectDesign = await this.webApiRequest<ApiDesignCreateRequest, ProjectDesign>(environment.designServiceUrl, `${urlPrefix}-design/create`, designCreateRequest, apiOptions);

            const designDetailsPromise = this.webApiRequest<ProjectDesign, DesignDetailsData>(environment.designServiceUrl, `${urlPrefix}-design/details`, projectDesign, apiOptions);
            const calculationResultPromise = this.webApiCalculationCalculate(urlPrefix, projectDesign);

            const designDetails = await designDetailsPromise;
            const calculationResult = await calculationResultPromise;

            if (calculationResult != null) {
                console.debug('Calculate duration:', calculationResult.calculateDuration);
            }

            return {
                projectDesign,
                designDetails,
                calculationResult
            };
        }

        const result = await this.dotnetService.api.core.createAndCalculate(designCreateRequest, apiOptions);
        if (result.calculationResult != null) {
            console.debug('Calculate duration:', result.calculationResult.calculateDuration);
        }

        return result;
    }

    private async coreConvertAndCalculate(urlPrefix: string, projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<ConvertAndCalculateResult> {
        if (this.type == SpApiType.web) {
            const designConvertResult = await this.webApiRequest<ProjectDesign, DesignConvertResult>(environment.designServiceUrl, `${urlPrefix}-design/convert`, projectDesign, apiOptions);

            if(designConvertResult.invalidDesignMessageKey != null){
                await this.modalService.openUnsupportedDesignModal(designConvertResult.invalidDesignMessageKey); //invalidDesignMessageKey
                throw new Error(`Invalid design: ${designConvertResult.invalidDesignMessageKey}`);
            }

            projectDesign = designConvertResult.projectDesign as ProjectDesign;
            const designDetailsPromise = this.webApiRequest<ProjectDesign, DesignDetailsData>(environment.designServiceUrl, `${urlPrefix}-design/details`, projectDesign, apiOptions);
            const calculationResultPromise = this.webApiCalculationCalculate(urlPrefix, projectDesign);

            const designDetails = await designDetailsPromise;
            const calculationResult = await calculationResultPromise;

            if (calculationResult != null) {
                console.debug('Calculate duration:', calculationResult.calculateDuration);
            }

            return {
                projectDesign,
                designDetails,
                calculationResult
            };
        }

        const result = await this.dotnetService.api.core.convertAndCalculate(projectDesign, apiOptions);

        if (result.calculationResult != null) {
            console.debug('Calculate duration:', result.calculationResult.calculateDuration);
        }

        return result;
    }

    private async coreUpdateAndCalculate(urlPrefix: string, designUpdateRequest: DesignUpdateRequest, apiOptions?: ApiOptions): Promise<UpdateResult> {
        if (this.type == SpApiType.web) {
            let designUpdateResponse = await this.webApiRequest<ApiDesignUpdateRequest, ApiDesignUpdateResponse>(environment.designServiceUrl, `${urlPrefix}-design/update`, {
                ...designUpdateRequest,
                confirmed: false
            }, apiOptions);

            if (designUpdateResponse.requiresConfirmation) {
                if (!await this.getConfirmation(designUpdateResponse.requiresConfirmation)) {
                    return {
                        resetAction: true
                    };
                }

                designUpdateResponse = await this.webApiRequest<ApiDesignUpdateRequest, ApiDesignUpdateResponse>(environment.designServiceUrl, `${urlPrefix}-design/update`, {
                    ...designUpdateRequest,
                    confirmed: true
                }, apiOptions);
            }

            const designCalculationResult = await this.createCalculationResult(urlPrefix, designUpdateResponse.projectDesign!, apiOptions);
            return {
                designCalculationResult
            };
        }

        // WASM
        let designUpdateResponse = await this.dotnetService.api.core.updateAndCalculate({
            ...designUpdateRequest,
            confirmed: false
        }, apiOptions);

        if (designUpdateResponse.requiresConfirmation) {
            if (!await this.getConfirmation(designUpdateResponse.requiresConfirmation)) {
                return {
                    resetAction: true
                };
            }

            designUpdateResponse = await this.dotnetService.api.core.updateAndCalculate({
                ...designUpdateRequest,
                confirmed: true
            }, apiOptions);
        }

        if (designUpdateResponse.calculationResult != null) {
            console.debug('Calculate duration:', designUpdateResponse.calculationResult.calculateDuration);
        }

        return {
            designCalculationResult: designUpdateResponse
        };
    }

    private async createCalculationResult(urlPrefix: string, projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<CreateAndCalculateResult> {
        const designDetailsPromise = this.webApiRequest<ProjectDesign, DesignDetailsData>(environment.designServiceUrl, `${urlPrefix}-design/details`, projectDesign, apiOptions);
        const calculationResultPromise = this.webApiCalculationCalculate(urlPrefix, projectDesign, apiOptions);

        const designDetails = await designDetailsPromise;
        const calculationResult = await calculationResultPromise;

        if (calculationResult != null) {
            console.debug('Calculate duration:', calculationResult.calculateDuration);
        }

        return {
            projectDesign,
            designDetails,
            calculationResult
        };
    }

    private async apiRequest<TRequest, TResponse>(serviceUrl: string, url: string, dotnetFn: (request: TRequest) => Promise<TResponse>, request: TRequest, apiOptions?: ApiOptions): Promise<TResponse> {
        if (this.type == SpApiType.web) {
            return await this.webApiRequest(serviceUrl, url, request, apiOptions);
        }

        return await dotnetFn(request);
    }

    private async webApiRequest<TRequest, TResponse>(serviceUrl: string, url: string, request: TRequest, apiOptions?: ApiOptions): Promise<TResponse> {
        const absoluteUrl = `${serviceUrl}${url}`;
        return (await this.apiService.request<TResponse>(new HttpRequest('POST', absoluteUrl, request), apiOptions)).body!;
    }

    private async blobWebApiRequest<TRequest>(serviceUrl: string, url: string, request: TRequest, apiOptions?: ApiOptions): Promise<Blob> {
        const absoluteUrl = `${serviceUrl}${url}`;
        return (await this.apiService.request<Blob>(new HttpRequest('POST', absoluteUrl, request, {
            responseType: 'blob'
        }), apiOptions)).body!;
    }

    private async textWebApiRequest<TRequest>(serviceUrl: string, url: string, request: TRequest, apiOptions?: ApiOptions): Promise<string> {
        const absoluteUrl = `${serviceUrl}${url}`;
        return (await this.apiService.request<string>(new HttpRequest('POST', absoluteUrl, request, {
            responseType: 'text'
        }), apiOptions)).body!;
    }

    private async webApiHtmlReportGenerate(htmlReportGenerateOptions: ApiHtmlReportGenerateOptions, apiOptions?: ApiOptions): Promise<Blob> {
        const url = `${environment.htmlReportServiceUrl}report/generate`;

        return (await this.apiService.request<Blob>(new HttpRequest('POST', url, htmlReportGenerateOptions, {
            responseType: 'blob'
        }), apiOptions)).body!;
    }

    private async webApiCalculationCalculate(urlPrefix: string, projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<CalculationResult | undefined> {
        apiOptions = {
            supressErrorMessage: true,
            ...apiOptions
        };

        const designCalculationOptions: ApiDesignCalculationOptions = {
            projectDesign: projectDesign
        };

        try {
            const absoluteUrl = `${environment.calculationServiceUrl}${urlPrefix}-calculation/calculate`;
            return (await this.apiService.request<CalculationResult>(new HttpRequest('POST', absoluteUrl, designCalculationOptions), apiOptions)).body!;
        }
        catch (error) {
            console.log(error);

            // returning undefined will show a calculation error scopecheck
            return undefined;
        }
    }

    private async getConfirmation(requiresConfirmation: RequiresConfirmation) : Promise<boolean> {
        const message = CreateMessageFromKeys(requiresConfirmation.messageKeys, this.localizationService);
        if (requiresConfirmation.confirmationType == ConfirmationType.Information) {
            await this.modalService.showRequiresConfirmationDialog(message);
            return true;
        }
        return await this.modalService.openConfirmDialog('requires-confirmation-dialog', message);
    }
}
