import { Color3 } from '@babylonjs/core/Maths/math.color';
import { toRad } from '../../math';
import { ZoneNumber } from '../../services/data.service';
import { StrengthPropertyId } from '../../services/design.service';
import { StrengthModel } from '../strength-gl-model';

interface ZoneColorsMap {
    1: { 1: Color3 };
    2: { 1: Color3; 2: Color3 };
    3: { 1: Color3; 2: Color3; 3: Color3 };
    4: { 1: Color3; 2: Color3; 3: Color3; 4: Color3 };
    5: { 1: Color3; 2: Color3; 3: Color3; 4: Color3; 5: Color3 };
    6: { 1: Color3; 2: Color3; 3: Color3; 4: Color3; 5: Color3; 6: Color3 };
    7: { 1: Color3; 2: Color3; 3: Color3; 4: Color3; 5: Color3; 6: Color3; 7: Color3 };
    8: { 1: Color3; 2: Color3; 3: Color3; 4: Color3; 5: Color3; 6: Color3; 7: Color3; 8: Color3 };
}

type ZoneColors<K extends keyof ZoneColorsMap = keyof ZoneColorsMap> = {
    [P in K]: ZoneColorsMap[P];
};

const zoneColor025 = Color3.FromHexString('#9CC19C');
const zoneColor041 = Color3.FromHexString('#80BD87');
const zoneColor050 = Color3.FromHexString('#71BB7B');
const zoneColor058 = Color3.FromHexString('#63B970');
const zoneColor075 = Color3.FromHexString('#45B559');

const zoneColor025T = Color3.FromHexString('#8CD79B');
const zoneColor041T = Color3.FromHexString('#67CA7B');
const zoneColor050T = Color3.FromHexString('#53C369');
const zoneColor058T = Color3.FromHexString('#40BD59');
const zoneColor075T = Color3.FromHexString('#19AF37');

const zoneColors: ZoneColors = {
    1: { 1: zoneColor025 },
    2: { 1: zoneColor025, 2: zoneColor075 },
    3: { 1: zoneColor025, 2: zoneColor050, 3: zoneColor075 },
    4: { 1: zoneColor025, 2: zoneColor041, 3: zoneColor058, 4: zoneColor075 },
    5: { 1: zoneColor025, 2: zoneColor075, 3: zoneColor025, 4: zoneColor075, 5: zoneColor025 },
    6: { 1: zoneColor025, 2: zoneColor075, 3: zoneColor025, 4: zoneColor075, 5: zoneColor025, 6: zoneColor075 },
    7: { 1: zoneColor025, 2: zoneColor075, 3: zoneColor025, 4: zoneColor075, 5: zoneColor025, 6: zoneColor075, 7: zoneColor025 },
    8: { 1: zoneColor025, 2: zoneColor075, 3: zoneColor025, 4: zoneColor075, 5: zoneColor025, 6: zoneColor075, 7: zoneColor025, 8: zoneColor075 }
};

const zoneColorsTransparent: ZoneColors = {
    1: { 1: zoneColor025T },
    2: { 1: zoneColor025T, 2: zoneColor075T },
    3: { 1: zoneColor025T, 2: zoneColor050T, 3: zoneColor075T },
    4: { 1: zoneColor025T, 2: zoneColor041T, 3: zoneColor058T, 4: zoneColor075T },
    5: { 1: zoneColor025T, 2: zoneColor075T, 3: zoneColor025T, 4: zoneColor075T, 5: zoneColor025T },
    6: { 1: zoneColor025T, 2: zoneColor075T, 3: zoneColor025T, 4: zoneColor075T, 5: zoneColor025T, 6: zoneColor075T },
    7: { 1: zoneColor025T, 2: zoneColor075T, 3: zoneColor025T, 4: zoneColor075T, 5: zoneColor025T, 6: zoneColor075T, 7: zoneColor025T },
    8: { 1: zoneColor025T, 2: zoneColor075T, 3: zoneColor025T, 4: zoneColor075T, 5: zoneColor025T, 6: zoneColor075T, 7: zoneColor025T, 8: zoneColor075T }
};

export const ZoneNumberHeightOffset = 100;
export const ZoneNumberingAlpha = 0.7;
export interface ZoneDetails {
    name: string;
    index: ZoneNumber;
    load: number;
    color: Color3;
    colorTransparent: Color3;
    zoneDimensions: ZoneDimensions;
}

interface ZoneDimensions {
    x: number;
    y: number;
    length: number;
    width: number;
}

export enum ZoneVerticalPosition {
    Top,
    Bottom
}

enum ZonePlacement {
    TopLeft,
    Top,
    TopRight,
    Right,
    BottomRight,
    Bottom,
    BottomLeft,
    Left
}

export interface ZoneElementsDetails {
    diameter: number;
    depthOfRecess: number;
    zoneElementsLayoutData: ZoneElementsLayoutData[];
}

interface ZoneElementsLayoutData {
    zoneNumber: ZoneNumber;
    defined: boolean;
    spacingX: number;
    spacingY: number;
    minEdgeDistanceX: number;
    minEdgeDistanceY: number;
}

export function GetMinEdgeDistanceX(model: StrengthModel, zoneNumber: ZoneNumber) : number {
    switch(zoneNumber) {
        case 1:
            return model.postInstalledElement.zone1MinimumEdgeDistanceX!;
        case 2:
            return model.postInstalledElement.zone2MinimumEdgeDistanceX!;
        case 3:
            return model.postInstalledElement.zone3MinimumEdgeDistanceX!;
        case 4:
            return model.postInstalledElement.zone4MinimumEdgeDistanceX!;
        case 5:
            return model.postInstalledElement.zone5MinimumEdgeDistanceX!;
        case 6:
            return model.postInstalledElement.zone6MinimumEdgeDistanceX!;
        case 7:
            return model.postInstalledElement.zone7MinimumEdgeDistanceX!;
        case 8:
            return model.postInstalledElement.zone8MinimumEdgeDistanceX!;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetEdgeDistanceX(model: StrengthModel, zoneNumber: ZoneNumber) : number {
    switch(zoneNumber) {
        case 1:
            return model.postInstalledElement.zone1EdgeDistanceX!;
        case 2:
            return model.postInstalledElement.zone2EdgeDistanceX!;
        case 3:
            return model.postInstalledElement.zone3EdgeDistanceX!;
        case 4:
            return model.postInstalledElement.zone4EdgeDistanceX!;
        case 5:
            return model.postInstalledElement.zone5EdgeDistanceX!;
        case 6:
            return model.postInstalledElement.zone6EdgeDistanceX!;
        case 7:
            return model.postInstalledElement.zone7EdgeDistanceX!;
        case 8:
            return model.postInstalledElement.zone8EdgeDistanceX!;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetMinEdgeDistanceY(model: StrengthModel, zoneNumber: ZoneNumber) : number {
    switch(zoneNumber) {
        case 1:
            return model.postInstalledElement.zone1MinimumEdgeDistanceY!;
        case 2:
            return model.postInstalledElement.zone2MinimumEdgeDistanceY!;
        case 3:
            return model.postInstalledElement.zone3MinimumEdgeDistanceY!;
        case 4:
            return model.postInstalledElement.zone4MinimumEdgeDistanceY!;
        case 5:
            return model.postInstalledElement.zone5MinimumEdgeDistanceY!;
        case 6:
            return model.postInstalledElement.zone6MinimumEdgeDistanceY!;
        case 7:
            return model.postInstalledElement.zone7MinimumEdgeDistanceY!;
        case 8:
            return model.postInstalledElement.zone8MinimumEdgeDistanceY!;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetEdgeDistanceY(model: StrengthModel, zoneNumber: ZoneNumber) : number {
    switch(zoneNumber) {
        case 1:
            return model.postInstalledElement.zone1EdgeDistanceY!;
        case 2:
            return model.postInstalledElement.zone2EdgeDistanceY!;
        case 3:
            return model.postInstalledElement.zone3EdgeDistanceY!;
        case 4:
            return model.postInstalledElement.zone4EdgeDistanceY!;
        case 5:
            return model.postInstalledElement.zone5EdgeDistanceY!;
        case 6:
            return model.postInstalledElement.zone6EdgeDistanceY!;
        case 7:
            return model.postInstalledElement.zone7EdgeDistanceY!;
        case 8:
            return model.postInstalledElement.zone8EdgeDistanceY!;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetSpacingX(model: StrengthModel, zoneNumber: ZoneNumber) : number {
    switch(zoneNumber) {
        case 1:
            return model.postInstalledElement.zone1SpacingX!;
        case 2:
            return model.postInstalledElement.zone2SpacingX!;
        case 3:
            return model.postInstalledElement.zone3SpacingX!;
        case 4:
            return model.postInstalledElement.zone4SpacingX!;
        case 5:
            return model.postInstalledElement.zone5SpacingX!;
        case 6:
            return model.postInstalledElement.zone6SpacingX!;
        case 7:
            return model.postInstalledElement.zone7SpacingX!;
        case 8:
            return model.postInstalledElement.zone8SpacingX!;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetSpacingY(model: StrengthModel, zoneNumber: ZoneNumber) : number {
    switch(zoneNumber) {
        case 1:
            return model.postInstalledElement.zone1SpacingY!;
        case 2:
            return model.postInstalledElement.zone2SpacingY!;
        case 3:
            return model.postInstalledElement.zone3SpacingY!;
        case 4:
            return model.postInstalledElement.zone4SpacingY!;
        case 5:
            return model.postInstalledElement.zone5SpacingY!;
        case 6:
            return model.postInstalledElement.zone6SpacingY!;
        case 7:
            return model.postInstalledElement.zone7SpacingY!;
        case 8:
            return model.postInstalledElement.zone8SpacingY!;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetZoneLength(model: StrengthModel, zoneNumber: ZoneNumber) {
    switch(zoneNumber) {
        case 1:
            return model.zones.zone1Length;
        case 2:
            return model.zones.zone2Length;
        case 3:
            return model.zones.zone3Length;
        case 4:
            return model.zones.zone4Length;
        case 5:
            return model.zones.zone5Length;
        case 6:
            return model.zones.zone6Length;
        case 7:
            return model.zones.zone7Length;
        case 8:
            return model.zones.zone8Length;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetZoneWidth(model: StrengthModel, zoneNumber: ZoneNumber) {
    switch(zoneNumber) {
        case 1:
            return model.zones.zone1Width;
        case 2:
            return model.zones.zone2Width;
        case 3:
            return model.zones.zone3Width;
        case 4:
            return model.zones.zone4Width;
        case 5:
            return model.zones.zone5Width;
        case 6:
            return model.zones.zone6Width;
        case 7:
            return model.zones.zone7Width;
        case 8:
            return model.zones.zone8Width;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetStrengtheningElementDefinition(model: StrengthModel, zoneNumber: ZoneNumber) : boolean | null {
    switch(zoneNumber) {
        case 1:
            return model.postInstalledElement.zone1StrengtheningElementDefinition;
        case 2:
            return model.postInstalledElement.zone2StrengtheningElementDefinition ?? false;
        case 3:
            return model.postInstalledElement.zone3StrengtheningElementDefinition ?? false;
        case 4:
            return model.postInstalledElement.zone4StrengtheningElementDefinition ?? false;
        case 5:
            return model.postInstalledElement.zone5StrengtheningElementDefinition ?? false;
        case 6:
            return model.postInstalledElement.zone6StrengtheningElementDefinition ?? false;
        case 7:
            return model.postInstalledElement.zone7StrengtheningElementDefinition ?? false;
        case 8:
            return model.postInstalledElement.zone8StrengtheningElementDefinition ?? false;
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetZoneLengthProperty(zoneNumber: ZoneNumber) : StrengthPropertyId {
    switch(zoneNumber) {
        case 1:
            return 'zone1Length';
        case 2:
            return 'zone2Length';
        case 3:
            return 'zone3Length';
        case 4:
            return 'zone4Length';
        case 5:
            return 'zone5Length';
        case 6:
            return 'zone6Length';
        case 7:
            return 'zone7Length';
        case 8:
            return 'zone8Length';
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetZoneWidthProperty(zoneNumber: ZoneNumber) : StrengthPropertyId {
    switch(zoneNumber) {
        case 1:
            return 'zone1Width';
        case 2:
            return 'zone2Width';
        case 3:
            return 'zone3Width';
        case 4:
            return 'zone4Width';
        case 5:
            return 'zone5Width';
        case 6:
            return 'zone6Width';
        case 7:
            return 'zone7Width';
        case 8:
            return 'zone8Width';
        default:
            throw new Error('Invalid zone number.');
    }
}
export function GetSpacingXProperty(zoneNumber: ZoneNumber) : StrengthPropertyId {
    switch(zoneNumber) {
        case 1:
            return 'zone1SpacingX';
        case 2:
            return 'zone2SpacingX';
        case 3:
            return 'zone3SpacingX';
        case 4:
            return 'zone4SpacingX';
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetSpacingYProperty(zoneNumber: ZoneNumber) : StrengthPropertyId {
    switch(zoneNumber) {
        case 1:
            return 'zone1SpacingY';
        case 2:
            return 'zone2SpacingY';
        case 3:
            return 'zone3SpacingY';
        case 4:
            return 'zone4SpacingY';
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetMinEdgeDistanceXProperty(zoneNumber: ZoneNumber) : StrengthPropertyId {
    switch(zoneNumber) {
        case 1:
            return 'zone1MinimumEdgeDistanceX';
        case 2:
            return 'zone2MinimumEdgeDistanceX';
        case 3:
            return 'zone3MinimumEdgeDistanceX';
        case 4:
            return 'zone4MinimumEdgeDistanceX';
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetMinEdgeDistanceYProperty(zoneNumber: ZoneNumber) : StrengthPropertyId {
    switch(zoneNumber) {
        case 1:
            return 'zone1MinimumEdgeDistanceY';
        case 2:
            return 'zone2MinimumEdgeDistanceY';
        case 3:
            return 'zone3MinimumEdgeDistanceY';
        case 4:
            return 'zone4MinimumEdgeDistanceY';
        default:
            throw new Error('Invalid zone number.');
    }
}

export function GetZoneDetailsList(model: StrengthModel): ZoneDetails[] {
    const numberOfZones = model.zones.numberOfZones;
    const zoneDimensions = new Array<ZoneDetails>(numberOfZones);
    let xBaseOffset = -model.baseMaterial.length / 2;

    for (let i = 0; i < numberOfZones; i++) {
        const zoneNumber = (i + 1) as ZoneNumber;
        const zoneLength = GetZoneLength(model, zoneNumber);

        zoneDimensions[i] = {
            name: 'Zone' + zoneNumber,
            index: zoneNumber,
            zoneDimensions: GetZoneDimensions(model, i, xBaseOffset, zoneLength),
            color: zoneColors[numberOfZones][zoneNumber as 1],
            colorTransparent: zoneColorsTransparent[numberOfZones][zoneNumber as 1],
            load: model.zones.zoneLoads.find(x => x.zoneNumber === zoneNumber)?.load ?? 0,
        };

        xBaseOffset += zoneLength;
    }

    return zoneDimensions;
}

export function GetZoneElementsDetails(model: StrengthModel): ZoneElementsDetails {
    const zoneElementsLayoutData = new Array<ZoneElementsLayoutData>(model.zones.numberOfZones);
    const numberOfZones = model.zones.numberOfZones;

    for (let i = 0; i < numberOfZones; i++) {
        const zoneNumber = (i + 1) as ZoneNumber;
        zoneElementsLayoutData[i] = {
            zoneNumber: zoneNumber,
            defined: GetStrengtheningElementDefinition(model, zoneNumber) ?? false,
            spacingX: GetSpacingX(model, zoneNumber),
            spacingY: GetSpacingY(model, zoneNumber),
            minEdgeDistanceX: GetMinEdgeDistanceX(model, zoneNumber),
            minEdgeDistanceY: GetMinEdgeDistanceY(model, zoneNumber)
        };
    }

    return {
        diameter: model.postInstalledElement.anchorDiameter,
        depthOfRecess: model.postInstalledElement.depthOfRecess,
        zoneElementsLayoutData: zoneElementsLayoutData
    };
}

export function GetZoneDimensions(model: StrengthModel, i: number, xBaseOffset: number, zoneLength: number): ZoneDimensions {
    if (!model.opening.defineOpening) {
        return {
            x: xBaseOffset + zoneLength / 2,
            y: 0,
            width: model.baseMaterial.width,
            length: zoneLength
        };
    }

    const baseMaterialY = model.baseMaterial.length;
    const baseMaterialX = model.baseMaterial.width;
    const baseMaterialXEdge = baseMaterialY / 2;
    const baseMaterialYEdge = baseMaterialX / 2;
    const openingWidth = model.opening.openingLength;
    const openingLength = model.opening.openingWidth;
    const openingOriginX = model.opening.openingOriginX;
    const openingOriginY = model.opening.openingOriginY;
    let len = 150;
    let width = 150;
    let x = 5000;
    let y = 5000;
    switch (i) {
        case ZonePlacement.TopLeft: {
            len = baseMaterialXEdge + (openingOriginX - openingWidth / 2);
            width = baseMaterialYEdge - (openingOriginY + openingLength / 2);
            x = -(baseMaterialXEdge - len / 2);
            y = baseMaterialX / 2 - width / 2;
            break;
        }
        case ZonePlacement.Top: {
            len = openingWidth;
            width = baseMaterialYEdge - (openingOriginY + openingLength / 2);
            x = openingOriginX;
            y = baseMaterialX / 2 - width / 2;
            break;
        }
        case ZonePlacement.TopRight: {
            len = baseMaterialXEdge - (openingOriginX + openingWidth / 2);
            width = baseMaterialYEdge - (openingOriginY + openingLength / 2);
            x = baseMaterialXEdge - len / 2;
            y = baseMaterialX / 2 - width / 2;
            break;
        }
        case ZonePlacement.Right: {
            len = baseMaterialXEdge - (openingOriginX + openingWidth / 2);
            width = openingLength;
            x = baseMaterialXEdge - len / 2;
            y = openingOriginY;
            break;
        }
        case ZonePlacement.BottomRight: {
            len = baseMaterialXEdge - (openingOriginX + openingWidth / 2);
            width = baseMaterialYEdge + (openingOriginY - openingLength / 2);
            x = baseMaterialXEdge - len / 2;
            y = -(baseMaterialX / 2 - width / 2);
            break;
        }
        case ZonePlacement.Bottom: {
            len = openingWidth;
            width = baseMaterialYEdge + (openingOriginY - openingLength / 2);
            x = openingOriginX;
            y = -(baseMaterialX / 2 - width / 2);
            break;
        }
        case ZonePlacement.BottomLeft: {
            len = baseMaterialXEdge + (openingOriginX - openingWidth / 2);
            width = baseMaterialYEdge + (openingOriginY - openingLength / 2);
            x = -(baseMaterialXEdge - len / 2);
            y = -(baseMaterialX / 2 - width / 2);
            break;
        }
        case ZonePlacement.Left: {
            len = baseMaterialXEdge + (openingOriginX - openingWidth / 2);
            width = openingLength;
            x = -(baseMaterialXEdge - len / 2);
            y = openingOriginY;
            break;
        }
    }

    return {
        width: width,
        length: len,
        x: x,
        y: y,
    };
}

export function GetZoneRotation(zonePosition: ZoneVerticalPosition) {
    switch (zonePosition) {
        case ZoneVerticalPosition.Top:
            return toRad(0);
        case ZoneVerticalPosition.Bottom:
            return toRad(180);
        default:
            throw new Error('Invalid zone position.');
    }
}

export function GetZoneZOffset(model: StrengthModel, zonePosition: ZoneVerticalPosition) {
    switch (zonePosition) {
        case ZoneVerticalPosition.Top:
            return model.baseMaterial.height / 2;
        case ZoneVerticalPosition.Bottom:
            return -model.baseMaterial.height / 2;
        default:
            throw new Error('Invalid zone position.');
    }
}



