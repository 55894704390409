<div id="zone-utilizations" class="utilizations box-section box-section-white" *ngIf="this.zoneUtilizationsAvailable">

    <div *ngFor="let zone of zones; trackBy: trackByIndex" [id]="getComponentId(zone, 'utilization')">

        <!-- Tabs -->

        <!-- Primary utilization tab -->
        <div class="box-section-header box-section-header-with-img" [class]="typeClass(TabType.Primary)">

            <div class="box-section-title drag-handle-static utilizaion-title-wrapper" [ngbTooltip]="getTooltipText()">

                <!-- Rebar image -->
                <div class="image" [id]="getComponentId(zone, 'utilization-image')">
                    <div class="pe-ui-sp-sprite pe-ui-sp-sprite-tab-supplementary-reinforcement"></div>
                </div>

                <!-- Zone bar title -->
                <span class="bar-title" [id]="getComponentId(zone, 'utilization-header')">
                    {{ getHeaderTitle(zone) }}
                    <span class="new-label" *ngIf="showNewLabel" l10n="Common.Tags.New"></span>
                </span>

                <span class="bar-subtitle" *ngIf="!getZoneCollapseState(zone)"></span>
            </div>

            <pe-utilization-bar [id]="getComponentId(zone, 'utilization-bar')" *ngIf="true && getZoneCollapseState(zone)"
                [collapsed]="getZoneCollapseState(zone)" [utilizationInvalid]="utilizationsCalculated[zone]?.maxUtilizationInvalid" [utilizationValue]="utilizationsCalculated[zone]?.maxUtilizationZone">
            </pe-utilization-bar>

            <!-- Collapse part -->
            <pe-collapse
                [id]="getZoneCollapsingControl(zone)"
                [collapsed]="getZoneCollapseState(zone)"
                (collapsedChange)="onCollapsedChanged($any($event).detail, zone, utilizationsCalculated[zone]!)">
            </pe-collapse>

        </div>

         <!-- Secondary utilization tabs -> Existing Member && Strengthened Member -->
         <ng-container *ngIf="!zonesCollapsing[zone]!.header">
            <ng-container *ngFor="let section of utilizationsCalculated[zone]!.sections; trackBy: sectionsTrackById; index as si">

                <div [id]="getComponentId(zone, 'secondary-type', si)" class="box-section-header box-section-header-with-img" [class]="typeClass(TabType.Secondary)" *ngIf="checkDefineStrengtheningElement(zone, section.section)">

                    <div class="box-section-title drag-handle-static utilizaion-title-wrapper" [ngbTooltip]="null">

                        <!-- No image -> it's secondary tab -->
                        <div class="no-image"></div>

                        <!-- Zone bar title -->
                        <span class="bar-title" [id]="getComponentId(zone, 'utilization-details-header', si)">
                            {{ translate(section.header) }}
                            <span class="new-label" *ngIf="showNewLabel" l10n="Common.Tags.New"></span>
                        </span>

                        <span class="bar-subtitle" *ngIf="!getSectionCollapseState(zone, si, section.section)"></span>
                    </div>

                    <pe-utilization-bar *ngIf="section.hasPercentage && getSectionCollapseState(zone, si, section.section)"
                        [collapsed]="getSectionCollapseState(zone, si, section.section)"
                        [utilizationInvalid]="section.maxUtilizationInvalid"
                        [utilizationValue]="section.maxUtilization"
                        [id]="getComponentId(zone, 'utilization-bar', si)">
                    </pe-utilization-bar>

                    <!-- Collapse part -->
                    <pe-collapse
                        [id]="getCollapseComponentId(zone, 'utilization-detail-worst', si)"
                        [collapsed]="getSectionCollapseState(zone, si, section.section)"
                        (collapsedChange)="onSectionCollapseChanged($any($event).detail, zone, si, section.section, section)">
                    </pe-collapse>

                </div>

                <!-- Section items part (properties) -->
                <ng-container *ngIf="!zonesCollapsing[zone]!.header && !zonesCollapsing[zone]!.sections[si].header">
                    <div class="items box-section-content" *ngIf="section.properties != null && section.properties.length > 0">

                        <ng-container *ngFor="let detailProperty of section.properties; let dgi = index">

                            <div [id]="getComponentId(zone, 'utilization-group-details-property', si, dgi)" *ngIf="detailProperty.isVisible">

                                <app-zone-utilization-item
                                    [title]="getPropertyTitle(detailProperty.text, detailProperty.utilizationVariable)"
                                    [utilizationVariable]="getUtilizationVariable(detailProperty.utilizationVariable)"
                                    [value]="detailProperty.value"
                                    [controlId]="getComponentId(zone, 'utilization-detail-worst', si, dgi)"
                                    [showPercentBar]="detailProperty.isPercentage!"
                                    [percentValue]="detailProperty.percentValue!"
                                    [percentageBarStyle]="detailProperty.percentBarStyle!"
                                    [numberContainerStyle]="detailProperty.numberContainerStyle!">
                                </app-zone-utilization-item>

                            </div>

                        </ng-container>

                    </div>
                </ng-container>

            </ng-container>
         </ng-container>

    </div>

</div>
