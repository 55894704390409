<pe-modal-header [modalInstance]="modalInstance" [title]="getTitle()"></pe-modal-header>

<pe-modal-body>
    <div>

        <span l10n="SP.PostInstalled.InstallationDirection.InfoPopUp.PartOne"></span>
        <div class="info-dialog-image">
            <img class="image" [src]="installationDirectionAImage" alt="installation-direction-a-image">
        </div>
        <span l10n="SP.PostInstalled.InstallationDirection.InfoPopUp.PartTwo"></span>
        <div class="info-dialog-image">
            <img class="image" [src]="installationDirectionBImage" alt="installation-direction-b-image">
        </div>
        <span l10n="SP.PostInstalled.InstallationDirection.InfoPopUp.PartThree"></span>
        <br /> <br />
        <span l10n="SP.PostInstalled.InstallationDirection.InfoPopUp.PartBottom"></span>

    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="info-dialog-ok-button" [look]="'Primary'" (buttonClick)="close()" [text]="translate('SP.General.OK')">
    </pe-button>
</pe-modal-footer>
