import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { isElementInDocument } from '@profis-engineering/pe-ui-common/helpers/html';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[closeOutsideNgbDropdown]'
})
export class CloseOutsideNgbDropdownDirective {

    constructor(
        private element: ElementRef,
        private dropdown: NgbDropdown,
    ) { }

    @HostListener('document:click', ['$event.composedPath()[0]'])
    public clickedOut(target: HTMLElement) {
        if (this.dropdown.isOpen() && !isElementInDocument(target, this.element.nativeElement)) {
            this.dropdown.close();
        }
    }

}
