import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { getSpriteAsIconStyle } from '../../sprites';
import { StrengthRegion, StrengthTab } from './static-menu.strength.definitions';

export function createSPMenu_Application(): StrengthTab {
    return {
        DisplayKey: 'SP.Navigation.TabApplication',
        Id: 6,
        Name: 'application-tab',
        Image: 'tab-application',
        IconImage: getSpriteAsIconStyle('sprite-tab-application'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_Application_TabApplication()
        ]
    };
}

function createSPMenu_Application_TabApplication(): StrengthRegion {
    return {
        DisplayKey: 'SP.Navigation.TabApplication.RegionApplication',
        Id: 60,
        Name: 'application-section',
        Controls: [
            {
                ControlType: 'ImageNameSelectionGroup',
                Name: 'application-concretemember-image-name-selection',
                TitleDisplayKey: 'SP.Navigation.TabApplication.RegionApplication.ConcreteMember',
                CodelistName: 'concreteMembers',
                UIPropertyId: 'concreteMemberId'
            }
        ]
    };
}
