import { Component, ViewEncapsulation } from '@angular/core';
import installationDirectionAImage from '../../../../images/install-dir-a.png';
import installationDirectionBImage from '../../../../images/install-dir-b.png';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';

@Component({
    templateUrl: './info-dialog-installation-direction.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogInstallationDirectionComponent extends InfoDialogBaseComponent {
    public installationDirectionAImage = installationDirectionAImage;
    public installationDirectionBImage = installationDirectionBImage;

    public getTitle(): string {
        return this.translate('SP.PostInstalled.InstallationDirection.InfoPopUp.Title');
    }
}
