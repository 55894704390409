import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class QueryService {
    public featureFlagsQueryEnabled = environment.featureFlagsQueryEnabled;
    private initialQuery: string;
    private httpParams: HttpParams;
    private featureHttpParams: HttpParams;
    private queryParams: Record<string, string[]>;

    constructor() {
        this.httpParams = new HttpParams();
        this.featureHttpParams = new HttpParams();
        this.initialQuery = '';
        this.queryParams = {};
    }

    public get windowLocationSearch(): string {
        return window.location.search;
    }

    public getAllHttpParams(): HttpParams {
        return this.httpParams;
    }

    public getFeatureHttpParams(): HttpParams {
        return this.featureHttpParams;
    }

    public getQueryValuesForParam(param: string): string[] {
        return this.featureFlagsQueryEnabled ? this.queryParams[param] : [];
    }

    public featureQueryParamContains(value: string): boolean {
        return this.featureFlagsQueryEnabled
            ? this.queryParams['features']?.includes(value.toLowerCase()) ?? false
            : false;
    }

    public featureQueryParamContainsAny(values: string[]): boolean {
        return this.featureFlagsQueryEnabled
            ? values.some((value) => this.queryParams['features']?.includes(value.toLowerCase()) ?? false)
            : false;
    }

    public initQuery() {
        if (!this.featureFlagsQueryEnabled || !this.windowLocationSearch)
            return;

        this.initialQuery = this.windowLocationSearch;
        const query = new URLSearchParams(this.initialQuery);

        query.forEach((value: string, key: string) => {
            // always store queries/values in lower case to avoid case sensitivity
            let paramKey = key.toLowerCase();
            let paramValue = value.toLowerCase();

            // keep old usage of queries such as "?spdemo=true", but store them under "features" param key
            if (value === 'true') {
                paramKey = 'features';
                paramValue = key;
            }

            if (paramKey === 'features') {
                this.featureHttpParams = this.httpParams.append(paramKey, paramValue);
            }

            this.httpParams = this.httpParams.append(paramKey, paramValue);
            this.appendValueToQueryParams(paramKey, paramValue);
        });
    }

    private appendValueToQueryParams(key: string, value: string) {
        this.queryParams[key] = this.queryParams[key] || [];
        const values = value.split(',');
        Array.prototype.push.apply(this.queryParams[key], values);
    }
}
