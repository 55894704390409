import { LoadCombination } from '../../../services/design.service';
import { StrengthBaseUpdate, StrengthModel } from '../../../web-gl/strength-gl-model';
import { strengthPropertyValueChanged, UpdateServices } from '../update';

export class BaseMaterialUpdate extends StrengthBaseUpdate {

    @strengthPropertyValueChanged('slabWidth')
    protected updateSlabWidth(slabWidth: number, model: StrengthModel) {
        model.baseMaterial.width = slabWidth;
    }

    @strengthPropertyValueChanged('slabHeight')
    protected updateSlabHeight(slabHeight: number, model: StrengthModel) {
        model.baseMaterial.height = slabHeight;
    }

    @strengthPropertyValueChanged('slabLength')
    protected updateSlabLength(slabLength: number, model: StrengthModel) {
        model.baseMaterial.length = slabLength;
    }

    @strengthPropertyValueChanged('concreteMemberId')
    protected updateConcreteMmberId(concreteMemberId: number, model: StrengthModel) {
        model.baseMaterial.concreteMemberId = concreteMemberId;
    }

    @strengthPropertyValueChanged('zonesNumberId')
    protected updateZonesNumberId(zonesNumberId: number, model: StrengthModel, services: UpdateServices) {
        model.zones.numberOfZones = services.dataService.zonesNumbersById[zonesNumberId].number;
    }

    @strengthPropertyValueChanged('slabWidth')
    protected updateZonesWidth(slabWidth: number, model: StrengthModel) {
        model.zones.width = slabWidth;
    }

    @strengthPropertyValueChanged('zone1Length')
    protected updateZone1Length(zone1Length: number, model: StrengthModel) {
        model.zones.zone1Length = zone1Length;
    }

    @strengthPropertyValueChanged('zone2Length')
    protected updateZone2Length(zone2Length: number, model: StrengthModel) {
        model.zones.zone2Length = zone2Length;
    }

    @strengthPropertyValueChanged('zone3Length')
    protected updateZone3Length(zone3Length: number, model: StrengthModel) {
        model.zones.zone3Length = zone3Length;
    }

    @strengthPropertyValueChanged('zone4Length')
    protected updateZone4Length(zone4Length: number, model: StrengthModel) {
        model.zones.zone4Length = zone4Length;
    }

    @strengthPropertyValueChanged('zone5Length')
    protected updateZone5Length(zone5Length: number, model: StrengthModel) {
        model.zones.zone5Length = zone5Length;
    }

    @strengthPropertyValueChanged('zone6Length')
    protected updateZone6Length(zone6Length: number, model: StrengthModel) {
        model.zones.zone6Length = zone6Length;
    }

    @strengthPropertyValueChanged('zone7Length')
    protected updateZone7Length(zone7Length: number, model: StrengthModel) {
        model.zones.zone7Length = zone7Length;
    }

    @strengthPropertyValueChanged('zone8Length')
    protected updateZone8Length(zone8Length: number, model: StrengthModel) {
        model.zones.zone8Length = zone8Length;
    }

    @strengthPropertyValueChanged('zone1Width')
    protected updateZone1Width(zone1Width: number, model: StrengthModel) {
        model.zones.zone1Width = zone1Width;
    }

    @strengthPropertyValueChanged('zone2Width')
    protected updateZone2Width(zone2Width: number, model: StrengthModel) {
        model.zones.zone2Width = zone2Width;
    }

    @strengthPropertyValueChanged('zone3Width')
    protected updateZone3Width(zone3Width: number, model: StrengthModel) {
        model.zones.zone3Width = zone3Width;
    }

    @strengthPropertyValueChanged('zone4Width')
    protected updateZone4Width(zone4Width: number, model: StrengthModel) {
        model.zones.zone4Width = zone4Width;
    }

    @strengthPropertyValueChanged('zone5Width')
    protected updateZone5Width(zone5Width: number, model: StrengthModel) {
        model.zones.zone5Width = zone5Width;
    }

    @strengthPropertyValueChanged('zone6Width')
    protected updateZone6Width(zone6Width: number, model: StrengthModel) {
        model.zones.zone6Width = zone6Width;
    }

    @strengthPropertyValueChanged('zone7Width')
    protected updateZone7Width(zone7Width: number, model: StrengthModel) {
        model.zones.zone7Width = zone7Width;
    }

    @strengthPropertyValueChanged('zone8Width')
    protected updateZone8Width(zone8Width: number, model: StrengthModel) {
        model.zones.zone8Width = zone8Width;
    }

    @strengthPropertyValueChanged('loadCombinations')
    protected updateLoadCombinations(loadCombinations: Array<LoadCombination>, model: StrengthModel) {
        if (loadCombinations?.[0] !== null) {
            model.zones.zoneLoads = loadCombinations[0].zoneLoads;
        }
    }
}
