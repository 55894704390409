import { Component, ViewEncapsulation } from '@angular/core';
import depthOfRecessImage from '../../../../images/recess.png';
import { InfoDialogBaseComponent } from '../../info-dialog-base.component';

@Component({
    templateUrl: './info-dialog-depth-of-recess.component.html',
    styleUrls: ['../../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogDepthOfRecessComponent extends InfoDialogBaseComponent {
    public depthOfRecessImage = depthOfRecessImage;

    public getTitle(): string {
        return this.translate('SP.InstallationConditions.DepthOfRecess.InfoPopUp.Title');
    }
}
